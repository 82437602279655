/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KorrespondenzSprache} from '../../../../core/sprache.enum';

export enum TemplateLanguage {
    DE_FR_IT = 'DE_FR_IT',
    DE_FR_EN = 'DE_FR_EN',
}

export function fromSprache(sprache: KorrespondenzSprache): TemplateLanguage {
    switch (sprache) {
        case KorrespondenzSprache.DE:
            return TemplateLanguage.DE_FR_IT;
        case KorrespondenzSprache.FR:
            return TemplateLanguage.DE_FR_IT;
        case KorrespondenzSprache.IT:
            return TemplateLanguage.DE_FR_IT;
        default:
            return TemplateLanguage.DE_FR_EN;
    }
}
