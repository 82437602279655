/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {classToPlain} from 'class-transformer';
import {forkJoin, from, Observable, of} from 'rxjs';
import {combineAll, map, mapTo, mergeMap, switchMap} from 'rxjs/operators';
import {ArbeitGeber} from '../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {LogFactory} from '../core/logging/log-factory';
import {toInvalidEntities} from '../core/validation/IsValid';
import {ValidationService} from '../core/validation/validation.service';
import {Validity} from '../core/validation/validity.model';
import {Lohnausweis} from '../lohnausweis/shared/lohnausweis.model';
import {ImportMode} from '../onboarding/import/import-mode.enum';
import {ImportData} from '../shared/import/ImportData.interface';
import {Database} from './Database';
import {DexieErrorComponent} from './dexie-error/dexie-error.component';
import {DexieCrudService} from './DexieCrudService';

const LOG = LogFactory.createLog('DataStoreService');

@Injectable({providedIn: 'root'})
export class DataStoreService {

    public db!: Database;
    public arbeitGeber!: DexieCrudService<ArbeitGeber>;
    public arbeitNehmer!: DexieCrudService<ArbeitNehmer>;
    public lohnausweis!: DexieCrudService<Lohnausweis>;
    public validity!: DexieCrudService<Validity>;

    private dialogRef?: MatDialogRef<DexieErrorComponent, void>;

    constructor(
        private readonly validationService: ValidationService,
        private readonly dialog: MatDialog,
    ) {
        this.init();
    }

    private init(): void {
        LOG.debug('creating database');
        this.db = new Database();
        this.arbeitGeber = new DexieCrudService(this.db.arbeitGeber);
        this.arbeitNehmer = new DexieCrudService(this.db.arbeitNehmer);
        this.lohnausweis = new DexieCrudService(this.db.lohnausweis);
        this.validity = new DexieCrudService(this.db.validity);

        window.addEventListener('unhandledrejection', (event: any) => {
            // Prevents default handler (would log to console).
            event.preventDefault();
            const reason = event.reason;
            if (!this.dialogRef) {
                this.dialogRef = this.dialog.open(DexieErrorComponent, {data: {dexieError: reason}});
            }
            LOG.warn('Unhandled promise rejection:', (reason && (reason.stack || reason)));
        });
    }

    public resetDatabase$(): Observable<void> {
        return from(this.db.delete().then(() => this.init()));
    }

    public clearAll$(): Observable<void> {
        return this.db.clearAll$()
            .pipe(
                // we do not want the caller to have access to the db instance
                map(() => undefined),
            );
    }

    public replaceWith$(params: ImportData, mode: ImportMode): Observable<void> {

        return from(this.db.transaction('rw',
            this.db.arbeitGeber,
            this.db.arbeitNehmer,
            this.db.lohnausweis,
            this.db.validity,
            /* eslint-disable @typescript-eslint/promise-function-async */
            () => {
                return this.db.clearAll$()
                    .pipe(
                        switchMap(() => {
                            const lohnausweise$ = mode === ImportMode.FULL
                                ? from(this.db.lohnausweis.bulkAdd(classToPlain(params.lohnausweise) as Lohnausweis[]))
                                : of(undefined);

                            const validity$ = this.createValidity$(params)
                                .pipe(mergeMap(validity => this.db.validity.add(classToPlain(validity) as Validity)));

                            return forkJoin([
                                from(this.db.arbeitGeber.bulkAdd(classToPlain(params.arbeitGeber) as ArbeitGeber[])),
                                from(this.db.arbeitNehmer.bulkAdd(classToPlain(params.arbeitNehmer) as ArbeitNehmer[])),
                                from(lohnausweise$),
                                validity$
                            ]);
                        }),
                        map(() => undefined),
                    )
                    .toPromise();
            }));
        /* eslint-enable @typescript-eslint/promise-function-async */
    }

    private createValidity$(params: ImportData): Observable<Validity> {
        const validity = new Validity();

        return forkJoin([
            from(params.arbeitNehmer).pipe(
                map(arbeitNehmer => this.validationService.validate$(arbeitNehmer)),
                combineAll(),
                map(toInvalidEntities),
                map(invalid => invalid.forEach(arbeitNehmer => validity.invalidArbeitnehmer.add(arbeitNehmer.id))),
            ),
            from(params.lohnausweise).pipe(
                map(lohnausweis => this.validationService.validate$(lohnausweis, lohnausweis.validationOptions())),
                combineAll(),
                map(toInvalidEntities),
                map(invalid => invalid.forEach(lohnausweis => {
                    const lohnausweisSet = validity.invalidLohnausweisViaArbeitnehmer.has(lohnausweis.arbeitNehmerId)
                        ? validity.invalidLohnausweisViaArbeitnehmer.get(lohnausweis.arbeitNehmerId)!
                        : new Set<string>();
                    lohnausweisSet.add(lohnausweis.id);
                    validity.invalidLohnausweisViaArbeitnehmer.set(lohnausweis.arbeitNehmerId, lohnausweisSet);
                })),
            ),
        ])
            .pipe(mapTo(validity));
    }

}
