/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, mapTo, mergeMap} from 'rxjs/operators';
import {ArbeitNehmer} from '../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {Lohnausweis} from '../../lohnausweis/shared/lohnausweis.model';
import {DataService} from '../../data-store/data-initializer.service';
import {DataStoreService} from '../../data-store/data-store.service';
import {LogFactory} from '../logging/log-factory';
import {Validity} from './validity.model';

const LOG = LogFactory.createLog('ValidityCheckService');

@Injectable({
    providedIn: 'root'
})
export class ValidityCheckService implements DataService {

    private readonly invalidLohnausweisViaArbeitnehmer = new Map<string, Set<string>>();
    private readonly invalidArbeitnehmer = new Set<string>();

    private readonly validitySubject$ = new BehaviorSubject(new Validity());
    private readonly validity$ = this.validitySubject$.asObservable();

    constructor(private readonly dataStoreService: DataStoreService) {
        this.loadInitialData$().subscribe(
            () => LOG.debug('initialized'),
            (err: unknown) => LOG.error(err)
        );
    }

    public lohnausweisInvalid$(lohnausweis: Lohnausweis): Observable<boolean> {
        return this.validity$.pipe(
            map(validity => validity.isLohnausweisInvalid(lohnausweis)),
            distinctUntilChanged()
        );
    }

    public arbeitNehmerInvalid$(arbeitNehmer: ArbeitNehmer): Observable<boolean> {
        return this.validity$.pipe(
            map(validity => validity.isArbeitNehmerInvalid(arbeitNehmer)),
            distinctUntilChanged()
        );
    }

    public hasInvalidLohnausweis$(arbeitNehmer: ArbeitNehmer): Observable<boolean> {
        return this.validity$.pipe(
            map(validity => validity.hasInvalidLohnausweis(arbeitNehmer)),
            distinctUntilChanged()
        );
    }

    private emit$(): Observable<void> {
        const id = this.validitySubject$.getValue().id;
        const next: Validity =
            new Validity(this.invalidLohnausweisViaArbeitnehmer, this.invalidArbeitnehmer, id);

        return this.dataStoreService.validity.update$(next)
            .pipe(map(() => this.validitySubject$.next(next)));
    }

    public setValidityForLohnausweis$(lohnausweis: Lohnausweis, valid: boolean): Observable<void> {
        const lohnausweisSet = this.invalidLohnausweisViaArbeitnehmer.has(lohnausweis.arbeitNehmerId) ?
            this.invalidLohnausweisViaArbeitnehmer.get(lohnausweis.arbeitNehmerId)! :
            new Set<string>();

        if (valid) {
            lohnausweisSet.delete(lohnausweis.id);
            if (lohnausweisSet.size === 0) {
                this.invalidLohnausweisViaArbeitnehmer.delete(lohnausweis.arbeitNehmerId);
            }
        } else {
            lohnausweisSet.add(lohnausweis.id);
            this.invalidLohnausweisViaArbeitnehmer.set(lohnausweis.arbeitNehmerId, lohnausweisSet);
        }

        return this.emit$();
    }

    public clearValidityForAllLohnausweise$(): Observable<void> {
        this.invalidLohnausweisViaArbeitnehmer.clear();

        return this.emit$();
    }

    public clearValidityForArbeitnehmer$(arbeitnehmerID: string): Observable<void> {
        this.invalidArbeitnehmer.delete(arbeitnehmerID);
        this.invalidLohnausweisViaArbeitnehmer.delete(arbeitnehmerID);

        return this.emit$();
    }

    public setValidityForArbeitNehmer$(arbeitNehmer: ArbeitNehmer, valid: boolean): Observable<void> {

        if (valid) {
            this.invalidArbeitnehmer.delete(arbeitNehmer.id);
        } else {
            this.invalidArbeitnehmer.add(arbeitNehmer.id);
        }

        return this.emit$();
    }

    public loadInitialData$(): Observable<void> {
        return this.dataStoreService.validity.getAll$()
            .pipe(
                mergeMap(validity => {
                    if (validity.length === 0) {
                        const v = new Validity();

                        return this.dataStoreService.validity.add$(v).pipe(mapTo(v));
                    }

                    this.importDatabaseData(validity[0]);

                    return of(validity[0]);
                }),
                map(validity => {
                    this.validitySubject$.next(validity);

                    return;
                }));
    }

    private importDatabaseData(validity: Validity): void {
        validity.invalidArbeitnehmer
            .forEach(val => this.invalidArbeitnehmer.add(val));
        validity.invalidLohnausweisViaArbeitnehmer
            .forEach((val, key) => this.invalidLohnausweisViaArbeitnehmer.set(key, val));
    }
}
