/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {registerDecorator, ValidationArguments, ValidationOptions} from 'class-validator';
import {ValidationCode} from './ValidationCode';

export function IsValidXMLValue(validationOptions?: ValidationOptions)
    : (object: object, propertyName: string) => void {
    return (object: object, propertyName: string) => {
        registerDecorator({
            name: 'validXmlValue',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
                validate(value: any, ignored: ValidationArguments): boolean {
                    if (typeof value === 'string') {
                        const VALID_XML_CHARACTERS = /* eslint-disable-next-line  no-control-regex */
                            /[^\x09\x0A\x0D\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm;

                        return !VALID_XML_CHARACTERS.test(value);
                    }

                    return true;

                },

                defaultMessage(): string {
                    return ValidationCode[ValidationCode.INVALID_XML_VALUE];
                },
            },
        });
    };
}
