import bwipjs from 'bwip-js';
import {LogFactory} from '../../core/logging/log-factory';
import {uint8ArrayToBinaryString} from '../../shared/functions';

const LOG = LogFactory.createLog('barcode-renderer');

export class Barcode {
    constructor(
        public readonly payload: Uint8Array,
        public readonly dataUrl: string,
        public readonly widthPx: number,
        public readonly heightPx: number,
    ) {
    }
}

export class BarcodeRendererService {
    async renderOne(content: Uint8Array): Promise<Barcode> {
        LOG.debug('renderOne: ', content.byteLength, content.length);
        const tmpcanvas = this.createCanvas();

        // https://github.com/bwipp/postscriptbarcode/wiki/PDF417
        // remember: the generated barcode gets resized when putting the image
        // into the PDF so settings width/height here does not work as expected.
        const options = {
            bcid: 'pdf417',
            // text: arrayBufferToString(content.slice(0, 400), {charset: 'windows-1252'}),
            text: uint8ArrayToBinaryString(content),
            includetext: false,
            // !!! !!! !!! !!! !!! !!! !!! !!! !!! !!! !!! !!!
            // using params from the specification fails for huge xml content.
            // (i.e.: when all standard remarks are set + some plaintext remarks)
            // The result would be a great-looking but non-PDF417-compliant/unparseable barcode :(
            // !!! !!! !!! !!! !!! !!! !!! !!! !!! !!! !!! !!!

            // => let bwip decide

            // wie im Beispiel Ich-AG
            // columns: 17,
            // wie im Beispiel Ich-AG
            // rows: 63,
            // zu rowmult: der Faktor ist so spezifiziert: "1.2 (evtl. dynamische Anpassung)")
            // ... also lassen wir die Lib entscheiden :) => "rowmult" nicht uebergeben
            // rowmult: (a.k.a.: Aspect Ratio)
            // eclevel: der Level 2 ist spezifiziert
            // eclevel: 2,
        };

        LOG.debug('renderOne', typeof content, content.byteLength, typeof options.text, options.text.length);

        return new Promise((resolve, reject) => {
            try {
                bwipjs.toCanvas(tmpcanvas, options);
                LOG.debug('barcode created:', tmpcanvas);

                const dataUrl = tmpcanvas.toDataURL('image/png');
                resolve(new Barcode(content, dataUrl, tmpcanvas.width, tmpcanvas.height));
            } catch (err) {
                LOG.error('barcode creation failed', err);
                reject(err);
            } finally {
                this.disposeCanvas(tmpcanvas);
            }
        });
    }

    private createCanvas(): HTMLCanvasElement {
        // Accessing 'document' directly should be migrated to Renderer2 (or something similar)
        const result: HTMLCanvasElement = document.createElement('canvas');

        return result;
    }

    private disposeCanvas(canvas: HTMLCanvasElement): void {
        canvas.remove();
    }
}
