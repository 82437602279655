/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, Output} from '@angular/core';
import {saveAs} from 'file-saver';
import {combineLatest, Observable} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {ArbeitGeber} from '../../arbeit-geber/shared/arbeit-geber.model';
import {DataInitializerService} from '../../data-store/data-initializer.service';
import {DataStoreService} from '../../data-store/data-store.service';
import {LogFactory} from '../../core/logging/log-factory';
import {XmlExportService} from '../../xml-import-export/xml-export.service';
import moment from 'moment';

const LOG = LogFactory.createLog('XmlExportComponent');

@Component({
    selector: 'elohn-xml-export',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './xml-export.component.html',
    styleUrls: ['./xml-export.component.scss'],
})

export class XmlExportComponent {

    @Input() public isMainBar = true;
    @Output() public readonly exported = new EventEmitter<Blob>();

    public show$: Observable<boolean>;

    constructor(
        private readonly service: XmlExportService,
        private readonly dataStoreService: DataStoreService,
        private readonly dataInitializerService: DataInitializerService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        this.show$ = this.dataInitializerService.hasBrowserData$;
    }

    public exportAll(): void {
        const ag$ = this.dataStoreService.arbeitGeber.getAll$().pipe(take(1));
        const an$ = this.dataStoreService.arbeitNehmer.getAll$().pipe(take(1));
        const la$ = this.dataStoreService.lohnausweis.getAll$().pipe(take(1));

        combineLatest([ag$, an$, la$])
            .pipe(
                mergeMap(([agList, anList, laList]) => {
                    const ag = agList[0];
                    const fileName = this.generateFileName(ag);

                    return this.service.buildXmlBlob$({
                        jahr: ag.bearbeitungsJahr || moment().toDate().getUTCFullYear(),
                        arbeitGeber: ag,
                        arbeitNehmer: anList,
                        lohnausweise: laList,
                    }).pipe(map(blob => ({blob, fileName})));
                }),
            )
            .subscribe(
                result => {
                    saveAs(result.blob, result.fileName);
                    this.exported.emit(result.blob);
                },
                (err: unknown) => LOG.error(err),
            );
    }

    private generateFileName(ag: ArbeitGeber): string {
        const la = this.locale === 'de-CH' ? 'LA' : 'CS';

        return `${ag.bearbeitungsJahr}_${la}_${ag.displayName}.xml`;
    }
}
