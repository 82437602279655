/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArbeitGeber} from '../arbeit-geber/shared/arbeit-geber.model';
import {DataService} from '../data-store/data-initializer.service';
import {DataStoreService} from '../data-store/data-store.service';
import {LogFactory} from './logging/log-factory';

const LOG = LogFactory.createLog('ArbeitGeberService');

@Injectable({
    providedIn: 'root'
})
export class ArbeitGeberService implements DataService {

    // Why using a ReplaySubject (instead of a BehaviorSubject):
    // We don't have to initialise. All subscriptions to this.get$() will have to wait until this.loadInitialData()
    // emits the first value.
    // However, since a ReplaySubject by default caches and streams all values to a new subscribe, we reduce the
    // bufferSize to 1, such that only tha last value is returned.
    private readonly arbeitGeberSubject$: ReplaySubject<ArbeitGeber | null> = new ReplaySubject(1);
    private readonly arbeitGeber$ = this.arbeitGeberSubject$.asObservable();

    constructor(private readonly dataStoreService: DataStoreService) {
        this.loadInitialData$().subscribe(
            () => LOG.debug('initialized'),
            (err: unknown) => LOG.error(err)
        );
    }

    public get$(): Observable<ArbeitGeber | null> {
        return this.arbeitGeber$;
    }

    public loadInitialData$(): Observable<void> {
        return this.dataStoreService.arbeitGeber.getAll$()
            .pipe(
                map<ArbeitGeber[], ArbeitGeber | null>(arbeitGeber => {
                    if (arbeitGeber.length === 0) {
                        return null;
                    }

                    return arbeitGeber[0];
                }),
                map(arbeitGeber => {
                    this.arbeitGeberSubject$.next(arbeitGeber);

                    return;
                }));
    }

    /**
     * returns the ID of the updated ArbeitGeber
     */
    public update$(arbeitGeber: ArbeitGeber): Observable<string> {
        const subj$ = new Subject<string>();
        const obs$ = subj$.asObservable();

        this.dataStoreService.arbeitGeber.update$(arbeitGeber)
            .subscribe(subj$);

        obs$.subscribe(
            () => this.arbeitGeberSubject$.next(arbeitGeber),
            (err: unknown) => LOG.error(err)
        );

        return obs$;
    }
}
