/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Lohnausweis} from './lohnausweis.model';

export class WizardStep5Model {
    constructor(
        public effektiveSpesen: boolean = false,
        public spesenRandziffer52: boolean = false,
        public zusaetzlicheEffektiveSpesen: boolean = false,
        public pauschalSpesen: boolean = false,
    ) {
    }

    public initFromLohnausweis(la: Lohnausweis): void {
        const spesen = la.spesenVerguetungen;

        this.effektiveSpesen = spesen.effektivReiseCheck
            || !!spesen.effektivReise
            || spesen.effektivUebrige.hasNonZeroValue();

        this.spesenRandziffer52 = la.spesenVerguetungen.effektivReiseCheck;

        this.zusaetzlicheEffektiveSpesen = la.spesenVerguetungen.effektivUebrige.hasNonZeroValue();

        this.pauschalSpesen = !!spesen.pauschalRepraesentation
            || !!spesen.pauschalAuto
            || spesen.pauschalUebrige.hasNonZeroValue()
            || !!spesen.weiterbildung;
    }
}
