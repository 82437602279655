/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Dexie, {DexieError} from 'dexie';

@Component({
    selector: 'elohn-dexie-error',
    templateUrl: './dexie-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DexieErrorComponent {

    public hasQuotaExceeded = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { dexieError: DexieError }) {
        const error = data.dexieError;

        this.hasQuotaExceeded = this.hasError(error, Dexie.errnames.QuotaExceeded);
    }

    private hasError(error: DexieError, name: string): boolean {
        if (error.name === name) {
            return true;
        }

        if (error.inner) {
            return this.hasError(error.inner, name);
        }

        return false;
    }
}
