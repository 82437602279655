<h1 mat-dialog-title
    i18n="@@dexie-error.dialogtitel">
    Datenbank Fehler
</h1>
<mat-dialog-content>
    <p i18n="@@dexie-error.quota" *ngIf="hasQuotaExceeded; else genericError">
        Es ist nicht genügend Speicherplatz für die Datenbank vorhanden. Die Applikation ist so nicht funktionsfähig.
    </p>

    <ng-template #genericError>
        <p i18n="@@dexie-error.generic">Es gibt ein Problem mit der Datenbank.</p>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions class="elohn-dialog-actions">
    <button mat-button
            mat-dialog-close
            i18n="@@global.close">Schliessen
    </button>
</mat-dialog-actions>
