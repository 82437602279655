/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import packageJson from 'package.json';
import {LogLevel} from '../app/core/logging/log-level';
import {Environment} from './IEnvironment';

export const environment: Environment = {
    production: true,
    // in a production build, the other values of package.json get tree-shaken
    // eslint-disable-next-line
    version: packageJson.version,
    hmr: false,
    logLevel: LogLevel.INFO,
};
