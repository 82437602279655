/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {List} from 'immutable';
import {BehaviorSubject, from, Observable, of, Subject, throwError} from 'rxjs';
import {distinctUntilChanged, map, mapTo, mergeMap} from 'rxjs/operators';
import {firstBy} from 'thenby';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {DataService} from '../data-store/data-initializer.service';
import {DataStoreService} from '../data-store/data-store.service';
import {SortOrder} from '../lohnausweis/shared/sort-order.enum';
import {LogFactory} from './logging/log-factory';
import {LohnausweisService} from './lohnausweis.service';
import {ValidationService} from './validation/validation.service';
import {ValidityCheckService} from './validation/validityCheck.service';

const LOG = LogFactory.createLog('ArbeitNehmerService');

@Injectable({
    providedIn: 'root'
})
export class ArbeitNehmerService implements DataService {

    private readonly arbeitNehmer$: BehaviorSubject<List<ArbeitNehmer>> = new BehaviorSubject(List<ArbeitNehmer>([]));

    private readonly searchTermSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public readonly searchTerm$ = this.searchTermSubject$.asObservable().pipe(distinctUntilChanged());

    public _sortOrder = SortOrder.ASCENDING;

    constructor(private readonly dataStoreService: DataStoreService,
                private readonly lohnausweisService: LohnausweisService,
                private readonly validationService: ValidationService,
                private readonly validityCheckService: ValidityCheckService) {
        this.loadInitialData$().subscribe(
            () => LOG.debug('initialized'),
            (err: unknown) => LOG.error(err)
        );
    }

    public get sortOrder(): SortOrder {
        return this._sortOrder;
    }

    public set sortOrder(value: SortOrder) {
        if (this._sortOrder === value) {
            return;
        }

        this._sortOrder = value;
        this.emitArbeitNehmer(this.arbeitNehmer$.getValue());
    }

    public setSearchTerm(term: string): void {
        this.searchTermSubject$.next(term);
    }

    public getAll$(): Observable<List<ArbeitNehmer>> {
        return this.arbeitNehmer$.asObservable();
    }

    public get$(arbeitNehmerId: string): Observable<Readonly<ArbeitNehmer>> {
        const arbeitNehmer = this.arbeitNehmer$.getValue().find(a => !!a && a.id === arbeitNehmerId);

        if (!arbeitNehmer) {
            return throwError(new Error(`ArbeitNehmer with ID ${arbeitNehmerId} not found`));
        }

        return of(arbeitNehmer);
    }

    /**
     * creates an ArbeitNehmer and an empty Lohnausweis and returns the ID of the ArbeitNehmer
     */
    public createWithLohnausweis$(jahr: number, arbeitNehmer: ArbeitNehmer): Observable<string> {
        const db = this.dataStoreService.db;

        const subj$ = new Subject<string>();
        const obs$ = subj$.asObservable();

        this.validationService.validate$(arbeitNehmer)
            .pipe(
                map(validation => validation.isValid()),
                mergeMap(isValid => this.validityCheckService.setValidityForArbeitNehmer$(arbeitNehmer, isValid)),
                mergeMap(() => from(db.transaction('rw', db.arbeitNehmer, db.lohnausweis, db.validity, () => {
                    return this.dataStoreService.arbeitNehmer.add$(arbeitNehmer)
                        .pipe(mergeMap(id => {
                            return this.lohnausweisService.createEmpty$(jahr, arbeitNehmer).pipe(mapTo(id));
                        }))
                        .toPromise();
                })))
            )
            .subscribe(subj$);

        obs$.subscribe(
            () => this.emitArbeitNehmer(this.arbeitNehmer$.getValue().push(arbeitNehmer)),
            (err: unknown) => LOG.error(err)
        );

        return obs$;
    }

    /**
     * returns the number of deleted Lohnausweise
     */
    public deleteWithLohnausweise$(arbeitNehmerId: string, arbeitGeberId: string): Observable<number> {
        const db = this.dataStoreService.db;

        const subj$ = new Subject<number>();
        const obs$ = subj$.asObservable();

        //No Validation required since the clearing is done in the lohnausweisService anyways
        from(db.transaction('rw', db.arbeitNehmer, db.lohnausweis, db.validity, () => {
            return db.arbeitNehmer.delete(arbeitNehmerId)
                .then(() => this.lohnausweisService.deleteForArbeitGeber$(arbeitNehmerId, arbeitGeberId).toPromise());
        }))
            .subscribe(subj$);

        obs$.subscribe(() => {
            const list = this.arbeitNehmer$.getValue();
            const index = list.findIndex(a => !!a && a.id === arbeitNehmerId);
            this.emitArbeitNehmer(list.delete(index));
        }, (err: unknown) => LOG.error(err));

        return obs$;
    }

    /**
     * returns the ID of the updated ArbeitNehmer
     */
    public update$(arbeitNehmer: ArbeitNehmer): Observable<string> {
        const subj$ = new Subject<string>();
        const obs$ = subj$.asObservable();

        this.validationService.validate$(arbeitNehmer)
            .pipe(
                map(validation => validation.isValid()),
                mergeMap(isValid => this.validityCheckService.setValidityForArbeitNehmer$(arbeitNehmer, isValid)),
                mergeMap(() => this.dataStoreService.arbeitNehmer.update$(arbeitNehmer))
            )
            .subscribe(subj$);

        obs$.subscribe(
            () => {
                const list = this.arbeitNehmer$.getValue();
                const index = list.findIndex(a => !!a && a.id === arbeitNehmer.id);

                this.emitArbeitNehmer(list.set(index, arbeitNehmer));
            },
            (err: unknown) => LOG.error(err)
        );

        return obs$;
    }

    public loadInitialData$(): Observable<void> {
        return this.dataStoreService.arbeitNehmer.getAll$()
            .pipe(
                map(arbeitNehmer => {
                    this.emitArbeitNehmer(List(arbeitNehmer));

                    return;
                }),
            );
    }

    private emitArbeitNehmer(arbeitNehmer: List<ArbeitNehmer>): void {
        const sorted = arbeitNehmer.sort(firstBy(an => an.displayName)) as List<ArbeitNehmer>;
        if (this._sortOrder === SortOrder.DESCENDING) {
            this.arbeitNehmer$.next(sorted.reverse() as List<ArbeitNehmer>);
        } else {
            this.arbeitNehmer$.next(sorted);
        }
    }
}
