/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {OverflowingPlaceholderType} from './OverflowingPlaceholders';

const additionalPageDataIt: ReadonlyMap<OverflowingPlaceholderType, ReadonlyArray<string>> = new Map([
    ['2_3_ANDERE_ART' as OverflowingPlaceholderType, [
        '2. Gehaltsnebenleistungen - Prestations salariales accessoires - Prestazioni accessorie al salario',
        '2.3 Andere - Autres - Altre',
        'Art - Genre - Genere'
    ]],
    ['3_UNREGELMAESSIGE_LEISTUNGEN_ART' as OverflowingPlaceholderType, [
        '3. Unregelmässige Leistungen – Prestations non périodiques – Prestazioni aperiodiche',
        'Art - Genre - Genere',
    ]],
    ['4_KAPITALLEISUNGEN_ART' as OverflowingPlaceholderType, [
        '4. Kapitalleistungen – Prestations en capital – Prestazioni in capitale',
        'Art - Genre - Genere',
    ]],
    ['7_ANDERE_LEISTUNGEN_ART' as OverflowingPlaceholderType, [
        '7. Andere Leistungen – Autres prestations – Altre prestazioni',
        'Art - Genre - Genere',
    ]],
    ['13_1_2_UEBRIGE_ART' as OverflowingPlaceholderType, [
        '13. Spesenvergütungen - Allocations pour frais - Indennità per spese',
        '13.1 Effekive Spesen - Frais effectifs - Spese effettive',
        '13.1.2 Übrige - Autres - Altre',
        'Art - Genre - Genere',
    ]],
    ['13_2_3_UEBRIGE_ART' as OverflowingPlaceholderType, [
        '13. Spesenvergütungen - Allocations pour frais - Indennità per spese',
        '13.2 Pauschalspesen - Frais forfaitaires - Spese forfettarie',
        '13.2.3 Übrige - Autres - Altre',
        'Art - Genre - Genere',
    ]],
    ['14_1_WEITERE_GEHALTSNEBENLEISTUNGEN' as OverflowingPlaceholderType, [
        '14. Weitere Gehaltsnebenleistungen - Autres prestations salariales accessoires - Altre prestazioni accessorie al salario',
        'Art - Genre - Genere',
    ]],
    ['15_1_BEMERKUNGEN' as OverflowingPlaceholderType, [
        '15. Bemerkungen - Observations - Osservazioni'
    ]]
]);

const additionalPageDataEn: ReadonlyMap<OverflowingPlaceholderType, ReadonlyArray<string>> = new Map([
    ['2_3_ANDERE_ART' as OverflowingPlaceholderType, [
        '2. Gehaltsnebenleistungen - Prestations salariales accessoires - Fringe benefts',
        '2.3 Andere - Autres - Others',
        'Art - Genre - Kind'
    ]],
    ['3_UNREGELMAESSIGE_LEISTUNGEN_ART' as OverflowingPlaceholderType, [
        '3. Unregelmässige Leistungen – Prestations non périodiques – Irregular benefits',
        'Art - Genre - Kind',
    ]],
    ['4_KAPITALLEISUNGEN_ART' as OverflowingPlaceholderType, [
        '4. Kapitalleistungen – Prestations en capital – Capital benefits',
        'Art - Genre - Kind',
    ]],
    ['7_ANDERE_LEISTUNGEN_ART' as OverflowingPlaceholderType, [
        '7. Andere Leistungen – Autres prestations – Other benefits',
        'Art - Genre - Kind',
    ]],
    ['13_1_2_UEBRIGE_ART' as OverflowingPlaceholderType, [
        '13. Spesenvergütungen - Allocations pour frais - Expenses reimbursement',
        '13.1 Effekive Spesen - Frais effectifs - Actual expenses',
        '13.1.2 Übrige - Autres - Others',
        'Art - Genre - Kind',
    ]],
    ['13_2_3_UEBRIGE_ART' as OverflowingPlaceholderType, [
        '13. Spesenvergütungen - Allocations pour frais - Expenses reimbursement',
        '13.2 Pauschalspesen - Frais forfaitaires - Overall expenses',
        '13.2.3 Übrige - Autres - Others',
        'Art - Genre - Kind',
    ]],
    ['14_1_WEITERE_GEHALTSNEBENLEISTUNGEN' as OverflowingPlaceholderType, [
        '14. Weitere Gehaltsnebenleistungen - Autres prestations salariales accessoires - Further fringe benefits',
        'Art - Genre - Kind',
    ]],
    ['15_1_BEMERKUNGEN' as OverflowingPlaceholderType, [
        '15. Bemerkungen - Observations - Comments'
    ]]
]);

export class AdditionalPageData {
    static IT = additionalPageDataIt;
    static EN = additionalPageDataEn;
}

export class AdditionalHeaderText {
    static IT = 'Ergänzung - Complément - Complemento';
    static EN = 'Ergänzung - Complément - Addendum';
}
