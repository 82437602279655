/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Translation} from './translations';

export enum CantonType {
    AG = 'AG',
    AI = 'AI',
    AR = 'AR',
    BE = 'BE',
    BL = 'BL',
    BS = 'BS',
    FR = 'FR',
    GE = 'GE',
    GL = 'GL',
    GR = 'GR',
    JU = 'JU',
    LU = 'LU',
    NE = 'NE',
    NW = 'NW',
    OW = 'OW',
    SG = 'SG',
    SH = 'SH',
    SO = 'SO',
    SZ = 'SZ',
    TG = 'TG',
    TI = 'TI',
    UR = 'UR',
    VD = 'VD',
    VS = 'VS',
    ZG = 'ZG',
    ZH = 'ZH',
    EX = 'EX'
}

export const CANTONTYPE_TRANSLATIONS: {[k in CantonType]: Translation} = {
    AG: {
        'de-CH': 'Kanton Aargau',
        'fr-CH': 'Argovie',
        'it-CH': 'Argovia',
        'en': 'Kanton Aargau'},
    AI: {
        'de-CH': 'Kanton Appenzell Innerrhoden',
        'fr-CH': 'Appenzell Rhodes-Intérieures',
        'it-CH': 'Appenzello Interno',
        'en': 'Kanton Appenzell Innerrhoden'},
    AR: {
        'de-CH': 'Kanton Appenzell Ausserrhoden',
        'fr-CH': 'Appenzell Rhodes-Extérieures ',
        'it-CH': 'Appenzello Esterno',
        'en': 'Kanton Appenzell Ausserrhoden'},
    BE: {
        'de-CH': 'Kanton Bern',
        'fr-CH': 'Berne',
        'it-CH': 'Berna',
        'en': 'Kanton Bern'},
    BL: {
        'de-CH': 'Kanton Basel-Landschaft',
        'fr-CH': 'Bâle-Campagne',
        'it-CH': 'Basilea Campagna',
        'en': 'Kanton Basel-Landschaft'},
    BS: {
        'de-CH': 'Kanton Basel-Stadt',
        'fr-CH': 'Bâle-Ville',
        'it-CH': 'Basilea Città',
        'en': 'Kanton Basel-Stadt'},
    FR: {
        'de-CH': 'Kanton Freiburg',
        'fr-CH': 'Fribourg',
        'it-CH': 'Friburgo',
        'en': 'Kanton Freiburg'},
    GE: {
        'de-CH': 'Kanton Genf',
        'fr-CH': 'Genève',
        'it-CH': 'Ginevra',
        'en': 'Kanton Genf'},
    GL: {
        'de-CH': 'Kanton Glarus',
        'fr-CH': 'Glaris',
        'it-CH': 'Glarona',
        'en': 'Kanton Glarus'},
    GR: {
        'de-CH': 'Kanton Graubünden',
        'fr-CH': 'Grisons',
        'it-CH': 'Cantone dei Grigioni',
        'en': 'Kanton Graubünden'},
    JU: {
        'de-CH': 'Kanton Jura',
        'fr-CH': 'Jura',
        'it-CH': 'Giura',
        'en': 'Kanton Jura'},
    LU: {
        'de-CH': 'Kanton Luzern',
        'fr-CH': 'Lucerne',
        'it-CH': 'Lucerna',
        'en': 'Kanton Luzern'},
    NE: {
        'de-CH': 'Kanton Neuenburg',
        'fr-CH': 'Neuchâtel',
        'it-CH': 'Neuchâtel',
        'en': 'Kanton Neuenburg'},
    NW: {
        'de-CH': 'Kanton Nidwalden',
        'fr-CH': 'Nidwald',
        'it-CH': 'Nidvaldo',
        'en': 'Kanton Nidwalden'},
    OW: {
        'de-CH': 'Kanton Obwalden',
        'fr-CH': 'Obwald',
        'it-CH': 'Obvaldo',
        'en': 'Kanton Obwalden'},
    SG: {
        'de-CH': 'Kanton St. Gallen',
        'fr-CH': 'Saint-Gall',
        'it-CH': 'San Gallo',
        'en': 'Kanton St. Gallen'},
    SH: {
        'de-CH': 'Kanton Schaffhausen',
        'fr-CH': 'Schaffhouse',
        'it-CH': 'Sciaffusa',
        'en': 'Kanton Schaffhausen'},
    SO: {
        'de-CH': 'Kanton Solothurn',
        'fr-CH': 'Soleure',
        'it-CH': 'Soletta',
        'en': 'Kanton Solothurn'},
    SZ: {
        'de-CH': 'Kanton Schwyz',
        'fr-CH': 'Schwytz',
        'it-CH': 'Svitto',
        'en': 'Kanton Schwyz'},
    TG: {
        'de-CH': 'Kanton Thurgau',
        'fr-CH': 'Thurgovie',
        'it-CH': 'Turgovia',
        'en': 'Kanton Thurgau'},
    TI: {
        'de-CH': 'Kanton Tessin',
        'fr-CH': 'Tessin',
        'it-CH': 'Ticino',
        'en': 'Kanton Tessin'},
    UR: {
        'de-CH': 'Kanton Uri',
        'fr-CH': 'Uri',
        'it-CH': 'Uri',
        'en': 'Kanton Uri'},
    VD: {
        'de-CH': 'Kanton Waadt',
        'fr-CH': 'Vaud',
        'it-CH': 'Vaud',
        'en': 'Kanton Waadt'},
    VS: {
        'de-CH': 'Kanton Wallis',
        'fr-CH': 'Valais',
        'it-CH': 'Vallese',
        'en': 'Kanton Wallis'},
    ZG: {
        'de-CH': 'Kanton Zug',
        'fr-CH': 'Zoug',
        'it-CH': 'Zugo',
        'en': 'Kanton Zug'},
    ZH: {
        'de-CH': 'Kanton Zürich',
        'fr-CH': 'Zurich',
        'it-CH': 'Zurigo',
        'en': 'Kanton Zürich'},
    EX: {
        'de-CH': 'Ausland',
        'fr-CH': 'l\'étranger',
        'it-CH': 'l\'estero',
        'en': 'Abroad'}
};
