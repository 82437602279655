/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KorrespondenzSprache} from './sprache.enum';

/**
 * Default locale of the app.
 */
export const DEFAULT_KORRESPONDENZ_SPRACHE = KorrespondenzSprache.DE;

export const UUID_VERSION = '4';

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const BARCODE_RIGHT: Readonly<{ x: number; y: number; options: Readonly<PDFKit.Mixins.ImageOption> }> = {
    x: 320,
    y: 120,
    options: {
        width: 223.75,
    },
};

export const BARCODE_LEFT: Readonly<{ x: number; y: number; options: Readonly<PDFKit.Mixins.ImageOption> }> = {
    x: 60,
    y: 120,
    options: {
        width: 223.75,
    },
};

// see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
export enum KEYCODES {
    enter = 'Enter',
    space = ' ',
    // for legacy browsers (Firefox <= 37, IE 9, 10, 11)
    spaceBar = 'Spacebar',
}

/**
 * Default visibility duration for SnackBars
 */
export const DEFAULT_SNACK_DURATION = 3000;

/**
 * Error visibility duration for SnackBars
 */
export const ERROR_SNACK_DURATION = 10000;
