/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export enum DialogMode {
    ALL = 'ALL',
    SELECTED = 'SELECTED'
}

export enum DownloadMode {
    SINGLE_PDF = 'SINGLE_PDF',
    ZIP = 'ZIP'
}

@Component({
    selector: 'elohn-pdf-print-all-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './pdf-print-all-dialog.component.html',
})
export class PdfPrintAllDialogComponent {
    public dialogMode: DialogMode;
    public readonly DialogMode = DialogMode;
    public readonly DownloadMode = DownloadMode;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { mode: DialogMode }) {
        this.dialogMode = data.mode;
    }

    public static open(
        dialog: MatDialog,
        dialogMode: DialogMode
    ): MatDialogRef<PdfPrintAllDialogComponent, DownloadMode> {
        return dialog.open(PdfPrintAllDialogComponent, {data: {mode: dialogMode}});
    }
}
