/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

export const noStorageManagerAPI = 'api not present';

// noinspection FunctionWithMoreThanThreeNegationsJS
/**
 * Tries to persist storage without ever prompting user.
 * @returns "never" In case persisting is not ever possible. Caller don't bother asking user for permission. "prompt"
 *         In case persisting would be possible if prompting user first. "persisted" In case this call successfully
 *         silently persisted the storage, or if it was already persisted.
 */
export async function tryPersistWithoutPromtingUser(): Promise<string> {
    if (!navigator.storage || !navigator.storage.persisted) {
        return 'never';
    }
    if (await navigator.storage.persisted()) {
        return 'persisted';
    }
    if (!navigator.permissions || !navigator.permissions.query) {
        return 'prompt'; // It MAY be successful to prompt. Don't know.
    }

    if (navigator.storage.persist && await navigator.storage.persist()) {
        return 'persisted';
    }

    return 'never';
}
