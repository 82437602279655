/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {MarkerService} from '../../core/marker.service';
import {PdfGenerationProgress} from '../../printing/pdf/pdf-generator/models/PdfGenerationProgress';
import {PdfGeneratorService} from '../../printing/pdf/pdf-generator/pdf-generator.service';

@Component({
    selector: 'elohn-toolbar-root',
    templateUrl: './toolbar-root.component.html',
    styleUrls: ['./toolbar-root.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarRootComponent {

    public readonly hasMarks$: Observable<boolean>;
    public readonly pdfGenerationProgress$: Observable<PdfGenerationProgress | null>;

    constructor(
        markerService: MarkerService,
        pdfGeneratorService: PdfGeneratorService,
    ) {
        this.hasMarks$ = markerService.hasMarks$();
        this.pdfGenerationProgress$ = pdfGeneratorService.progress$;
    }
}
