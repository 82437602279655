/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Type} from 'class-transformer';
import {IsDefined, ValidateNested} from 'class-validator';
import {OPTS_BOTH} from '../../core/validation/validation.const';
import {Lohnausweis} from './lohnausweis.model';
import {WizardStep2Model} from './wizard.step2.model';
import {WizardStep3Model} from './wizard.step3.model';
import {WizardStep5Model} from './wizard.step5.model';

export class Wizard {

    @Type(() => WizardStep2Model)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    step2: WizardStep2Model = new WizardStep2Model();

    @Type(() => WizardStep3Model)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    step3: WizardStep3Model = new WizardStep3Model();

    @Type(() => WizardStep5Model)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    step5: WizardStep5Model = new WizardStep5Model();

    public initFromLohnausweis(la: Lohnausweis): void {
        this.step2.initFromLohnausweis(la);
        this.step3.initFromLohnausweis(la);
        this.step5.initFromLohnausweis(la);
    }
}
