import {LogFactory} from "../logging/log-factory";
import {Injectable} from "@angular/core";
import {StatisticEntry} from "./statistic-entry";
import {StatisticType} from "./statistic-type";
import {ArbeitGeber} from "../../arbeit-geber/shared/arbeit-geber.model";
import {Lohnausweis} from "../../lohnausweis/shared/lohnausweis.model";
import {StatisticsApiService} from "./api/statistics-api.service";

const LOG = LogFactory.createLog('StatisticsService');

@Injectable({
    providedIn: 'root'
})
export class StatisticsService {
    constructor(private readonly statisticsRestService: StatisticsApiService) {
    }

    public logLohnausweisErstellt(arbeitgeber: ArbeitGeber) {
        // TODO Check, can there be injection of any kind here?
        this.sendStatistic({
            type: StatisticType.LOHNAUSWEIS_ERSTELLT,
            arbeitgeberId: arbeitgeber.id
        });
    }

    public logLohnausweisAbgeschlossen(lohnausweis: Lohnausweis) {
        // TODO Check, can there be injection of any kind here?
        this.sendStatistic({
            type: StatisticType.LOHNAUSWEIS_ABGESCHLOSSEN,
            arbeitgeberId: lohnausweis.arbeitGeberId,
            lohnausweisId: lohnausweis.id
        });
    }


    private sendStatistic(entry: StatisticEntry): void {
        const payload = Object.values(entry).join(',');
        this.statisticsRestService.statisticsResourcePostStatistics(payload)
            .subscribe({
                next: _ => LOG.debug("Statistic sent"),
                error: (err: unknown) => LOG.error(err)
            });
    }
}
