/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {registerDecorator, ValidationArguments, ValidationOptions} from 'class-validator';
import moment from 'moment';
import {ValidationCode} from './ValidationCode';

export function IsSameOrAfter(property: string, validationOptions?: ValidationOptions)
    : (object: object, propertyName: string) => void {
    return (object: object, propertyName: string) => {
        registerDecorator({
            name: 'isSameOrAfter',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [property],
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments): boolean {
                    const [relatedPropertyName] = args.constraints;
                    const relatedValue = (args.object as any)[relatedPropertyName];

                    // waere noch cool, wenn die validierung auf falschen Propertynamen schon vorher geschene
                    // koennte und nicht erst beim Aufruf der Validierung
                    if (!Object.getOwnPropertyDescriptor(args.object, relatedPropertyName)) {
                        throw new Error(`Object ${args.object.constructor.name} does not have property: ${relatedPropertyName}`);
                    }

                    const val = moment.isMoment(value) ? moment(value).toDate() : value;
                    const relatedVal = moment.isMoment(relatedValue) ? moment(relatedValue).toDate() : relatedValue;

                    return val instanceof Date && relatedVal instanceof Date && val.getTime() >= relatedVal.getTime();
                },

                defaultMessage(): string {
                    return ValidationCode[ValidationCode.DATE_IS_NOT_AFTER];
                }
            }
        });
    };
}
