<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->

<elohn-toolbar role="toolbar" class="print-progress-toolbar">
    <div class="elohn-toolbar-right">
        <button mat-button
                (click)="progress.abort()"
                aria-label="Druckvorgang abbrechen"
                i18n-aria-label="@@print-progress-toolbar.abortLabel">
            <ng-container i18n="@@print-progress-toolbar.abort">Druck abbrechen</ng-container>
            <mat-icon class="spacer-left">cancel</mat-icon>
        </button>
    </div>

    <div class="elohn-toolbar-center">
        <span class="progress" i18n="@@print-progress-toolbar.progress">generiere PDF
            {{ progress.completed$ | async }} von {{ progress.queueSize }}</span>
    </div>
</elohn-toolbar>
