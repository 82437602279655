/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';

export function formatISODate(date: moment.Moment | Date | null): string | null {
    if (!date) {
        return null;
    }

    return date.toISOString().substr(0, 10);
}

export function formatDate(date: moment.Moment | Date | null, locale: string): string | null {
    if (!date) {
        return null;
    }

    if (date instanceof Date) {
        return moment(date).locale(locale).format('L');
    }

    return date.locale(locale).format('L');
}
