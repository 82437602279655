/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {registerLocaleData} from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import localeEn from '@angular/common/locales/en';
import localeDeChExtra from '@angular/common/locales/extra/de-CH';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrChExtra from '@angular/common/locales/extra/fr-CH';
import localeItChExtra from '@angular/common/locales/extra/it-CH';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeItCh from '@angular/common/locales/it-CH';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {KorrespondenzSprache} from './app/core/sprache.enum';
import {environment} from './environments/environment';
import {hmrBootstrap} from './hmr';

registerLocaleData(localeDeCh, KorrespondenzSprache.DE, localeDeChExtra);
registerLocaleData(localeFrCh, KorrespondenzSprache.FR, localeFrChExtra);
registerLocaleData(localeItCh, KorrespondenzSprache.IT, localeItChExtra);
registerLocaleData(localeEn, KorrespondenzSprache.EN, localeEnExtra);

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

/* eslint-disable no-console */
if (environment.hmr) {
    if (module.hot) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap()
        .catch((err: unknown) => console.log('App bootstrap error: ', err));
}
/* eslint-enable no-console */
if (document.title.indexOf(environment.version) === -1) {
    document.title += ` - Version ${(environment.version)}`;
}
