/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {from, Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, switchMap, take} from 'rxjs/operators';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {DataStoreService} from '../data-store/data-store.service';
import {LogFactory} from './logging/log-factory';

const LOG = LogFactory.createLog('LohnausweiseCountService');

@Injectable({
    providedIn: 'root',
})
export class LohnausweiseCountService {
    private readonly lohnausweiseByArbeitNehmerId: Map<string, ReplaySubject<number>> = new Map<string, ReplaySubject<number>>();

    constructor(private readonly dataStoreService: DataStoreService) {
    }

    public countLohnausweiseViaArbeitNehmer$(arbeitNehmerIds: Set<string>, arbeitGeberId: string): Observable<number> {
        return from(this.dataStoreService.db.lohnausweis
            .where('arbeitGeberId').equals(arbeitGeberId)
            .filter(lohnausweis => arbeitNehmerIds.has(lohnausweis.arbeitNehmerId))
            .count());
    }

    public clearCache(): void {
        Array.from(this.lohnausweiseByArbeitNehmerId.keys())
            .forEach(id => this.removeAndCompleteObsolete(id));
    }

    /**
     * returns a Lohnausweise count stream for the ArbeitNehmer (emits whenever the count changes)
     */
    public get$(arbeitNehmer: ArbeitNehmer): Observable<number> {
        return this.getFromCache$(arbeitNehmer)
            .pipe(distinctUntilChanged());
    }

    /**
     * triggers a refresh for arbeitNehmerId & arbeitGeber and returns the current count (completing).
     */
    public update$(arbeitNehmerId: string, arbeitGeberId: string): Observable<number> {
        return this.updateStream$(arbeitNehmerId, arbeitGeberId)
            .pipe(take(1));
    }

    private getFromCache$(arbeitNehmer: ArbeitNehmer): Observable<number> {
        const subject$ = this.lohnausweiseByArbeitNehmerId.get(arbeitNehmer.id);
        if (subject$) {
            return subject$.asObservable();
        }

        return this.updateStream$(arbeitNehmer.id, arbeitNehmer.arbeitGeberId);
    }

    private updateStream$(arbeitNehmerId: string, arbeitGeberId: string): Observable<number> {
        return this.countLohnausweiseOfArbeitNehmer$(arbeitNehmerId, arbeitGeberId)
            .pipe(switchMap(lohnausweiseCount => this.emitCount$(arbeitNehmerId, lohnausweiseCount)));
    }

    private countLohnausweiseOfArbeitNehmer$(arbeitNehmerId: string, arbeitGeberId: string): Observable<number> {
        return from(this.dataStoreService.db.lohnausweis
            .where({arbeitNehmerId, arbeitGeberId})
            .count());
    }

    private emitCount$(arbeitNehmerId: string, lohnausweiseCount: number): ReplaySubject<number> {
        const subject$ = this.getOrCreateSubject$(arbeitNehmerId);
        subject$.next(lohnausweiseCount);
        LOG.debug(`updating count to ${lohnausweiseCount} for ArbeitNehmerId ${arbeitNehmerId}`);

        return subject$;
    }

    private getOrCreateSubject$(arbeitNehmerId: string): ReplaySubject<number> {
        const existing$ = this.lohnausweiseByArbeitNehmerId.get(arbeitNehmerId);
        if (existing$) {
            return existing$;
        }

        const subject$ = new ReplaySubject<number>(1);
        this.lohnausweiseByArbeitNehmerId.set(arbeitNehmerId, subject$);

        return subject$;
    }

    private removeAndCompleteObsolete(arbeitNehmerId: string): void {
        this.lohnausweiseByArbeitNehmerId.get(arbeitNehmerId)?.complete();
        this.lohnausweiseByArbeitNehmerId.delete(arbeitNehmerId);
    }
}
