// noinspection MagicNumberJS

/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import moment from 'moment';
import {ArbeitGeber} from '../../../arbeit-geber/shared/arbeit-geber.model';
import {format as formatAhvNummer} from '../../../arbeit-nehmer/shared/ahvnummer';
import {ArbeitNehmer} from '../../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {DEFAULT_KORRESPONDENZ_SPRACHE} from '../../../core/constants';
import {KorrespondenzSprache} from '../../../core/sprache.enum';
import {BeruflicheVorsorge} from '../../../lohnausweis/shared/berufliche-vorsorge.model';
import {GehaltsNebenLeistungen} from '../../../lohnausweis/shared/gehalts-neben-leistungen.model';
import {Lohnausweis} from '../../../lohnausweis/shared/lohnausweis.model';
import {LohnausweisPDF} from '../../../lohnausweis/shared/LohnausweisPDF.model';
import {SpesenVerguetungen} from '../../../lohnausweis/shared/spesen-verguetungen.model';
import {ValueWithDescription} from '../../../lohnausweis/shared/value-with-description.model';
import {Adresse} from '../../../shared/adresse.model';
import {formatDate} from '../../../shared/functions/date-formats';
import {formatDecimal} from '../../../shared/functions/decimal-format';
import {splitText} from '../../../shared/functions/split-text';
import {OverflowingPlaceholder} from './models/OverflowingPlaceholder';
import {SVGMappingDocument} from './models/SVGMappingDocument';
import {SVGPlaceholder} from './models/SVGPlaceholder';

@Injectable({
    providedIn: 'root',
})
export class SvgMappingService {

    /* eslint-disable no-magic-numbers */
    // region header
    private readonly IS_LOHNAUSWEIS: SVGPlaceholder<boolean> =
        new SVGPlaceholder('A_DISPLAY_MODE', SvgMappingService.fromBoolean);
    private readonly IS_RENTENBESCHEINIGUNG: SVGPlaceholder<boolean> =
        new SVGPlaceholder('B_DISPLAY_MODE', SvgMappingService.fromBoolean);

    private readonly AHV_NR: SVGPlaceholder<string> =
        new SVGPlaceholder('C_AHV_NR', formatAhvNummer);
    private readonly GEBURTSDATUM: SVGPlaceholder<Date | null>;

    private readonly JAHR: SVGPlaceholder<number | null> =
        new SVGPlaceholder('D_JAHR', String);
    private readonly VON: SVGPlaceholder<Date | null>;
    private readonly BIS: SVGPlaceholder<Date | null>;

    private readonly UNENTGELTLICHE_BEFOERDERUNG: SVGPlaceholder<boolean> =
        new SVGPlaceholder('F_DISPLAY_MODE', SvgMappingService.fromBoolean);
    private readonly KANTINENVERPFLEGUNG: SVGPlaceholder<boolean> =
        new SVGPlaceholder('G_DISPLAY_MODE', SvgMappingService.fromBoolean);
    // endregion

    // region address
    private readonly ARBEITNEHMER_ANREDE: SVGPlaceholder<string> =
        new SVGPlaceholder('H_1_ANREDE', SvgMappingService.fromString);
    private readonly ARBEITNEHMER_NAME: SVGPlaceholder<string> =
        new SVGPlaceholder('H_2_NAME', SvgMappingService.fromString);
    private readonly ARBEITNEHMER_ADRESSE: SVGPlaceholder<string> =
        new SVGPlaceholder('H_3_ADRESSE', SvgMappingService.fromString);
    private readonly ARBEITNEHMER_POSTFACH: SVGPlaceholder<string> =
        new SVGPlaceholder('H_4_POSTFACH', SvgMappingService.fromString);
    private readonly ARBEITNEHMER_WOHNORT: SVGPlaceholder<string> =
        new SVGPlaceholder('H_5_WOHNORT', SvgMappingService.fromString);
    // endregion

    // region lohnausweis
    private readonly LOHN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('1_LOHN', SvgMappingService.fromNumber);

    private readonly GEHALTSNEBENLEISTUNG_VERPFLEGUNG_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('2_1_VERPFLEGUNG', SvgMappingService.fromNumber);
    private readonly GEHALTSNEBENLEISTUNG_PRIVATANTEIL_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('2_2_PRIVATANTEIL', SvgMappingService.fromNumber);
    private readonly GEHALTSNEBENLEISTUNG_ANDERE_TITEL: OverflowingPlaceholder =
        new OverflowingPlaceholder('2_3_ANDERE_ART', 240);
    private readonly GEHALTSNEBENLEISTUNG_ANDERE_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('2_3_ANDERE', SvgMappingService.fromNumber);

    private readonly UNREGELMAESSIGE_LEISTUNGEN_TITEL: OverflowingPlaceholder =
        new OverflowingPlaceholder('3_UNREGELMAESSIGE_LEISTUNGEN_ART', 470);
    private readonly UNREGELMAESSIGE_LEISTUNGEN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('3_UNREGELMAESSIGE_LEISTUNGEN', SvgMappingService.fromNumber);

    private readonly KAPITALLEISUNGEN_TITEL: OverflowingPlaceholder =
        new OverflowingPlaceholder('4_KAPITALLEISUNGEN_ART', 210);
    private readonly KAPITALLEISUNGEN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('4_KAPITALLEISUNGEN', SvgMappingService.fromNumber);

    private readonly BETEILIGUNGSRECHTE_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('5_BETEILIGUNGSRECHTE', SvgMappingService.fromNumber);

    private readonly VERWALTUNGSENTSCHAEDIGUNGEN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('6_VERWALTUNGSENTSCHAEDIGUNGEN', SvgMappingService.fromNumber);

    private readonly ANDERE_LEISTUNGEN_TITEL: OverflowingPlaceholder =
        new OverflowingPlaceholder('7_ANDERE_LEISTUNGEN_ART', 240);
    private readonly ANDERE_LEISTUNGEN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('7_ANDERE_LEISTUNGEN', SvgMappingService.fromNumber);

    private readonly BRUTTOLOHN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('8_BRUTTOLOHN', SvgMappingService.fromNumber);

    private readonly BEITRAEGE_AHV_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('9_BEITRAEGE_AHV', SvgMappingService.fromNumber);

    private readonly BVG_ORDENTLICHE_BEITRAEGE_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('10_1_ORDENTLICHE_BEITRAEGE', SvgMappingService.fromNumber);
    private readonly BVG_BEITRAEGE_FUR_EINKAUF_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('10_2_BEITRAEGE_FUR_EINKAUF', SvgMappingService.fromNumber);

    private readonly NETTOLOHN_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('11_NETTOLOHN', SvgMappingService.fromNumber);

    private readonly QUELLENSTEUERABZUG_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('12_QUELLENSTEUERABZUG', SvgMappingService.fromNumber);

    private readonly IS_EFFEKTIVE_SPESEN_REISE: SVGPlaceholder<boolean> =
        new SVGPlaceholder('13_1_1_REISE_VERPFL_UEB_DISPLAY_MODE', SvgMappingService.fromBoolean);
    private readonly EFFEKTIVE_SPESEN_REISE_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('13_1_2_REISE_VERPFL_UEB', SvgMappingService.fromNumber);
    private readonly EFFEKTIVE_SPESEN_UEBRIGE_TITEL: OverflowingPlaceholder =
        new OverflowingPlaceholder('13_1_2_UEBRIGE_ART', 240);
    private readonly EFFEKTIVE_SPESEN_UEBRIGE_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('13_1_2_UEBRIGE', SvgMappingService.fromNumber);

    private readonly PAUSCHALSPESEN_REPRAESENTATION_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('13_2_1_REPRAESENTATION', SvgMappingService.fromNumber);
    private readonly PAUSCHALSPESEN_AUTO_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('13_2_2_AUTO', SvgMappingService.fromNumber);
    private readonly PAUSCHALSPESEN_UEBRIGE_TITEL: OverflowingPlaceholder =
        new OverflowingPlaceholder('13_2_3_UEBRIGE_ART', 240);
    private readonly PAUSCHALSPESEN_UEBRIGE_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('13_2_3_UEBRIGE', SvgMappingService.fromNumber);

    private readonly BEITRAEGE_WEITERBILDUNG_CHF: SVGPlaceholder<number | null> =
        new SVGPlaceholder('13_3_BEITRAEGE_WEITERBILDUNG', SvgMappingService.fromNumber);

    private readonly WEITERE_GEHALTSNEBENLEISTUNGEN_1: OverflowingPlaceholder =
        new OverflowingPlaceholder(
            '14_1_WEITERE_GEHALTSNEBENLEISTUNGEN',
            410,
            new SVGPlaceholder('14_2_WEITERE_GEHALTSNEBENLEISTUNGEN', SvgMappingService.fromString),
        );

    private readonly BEMERKUNGEN_1: OverflowingPlaceholder =
        new OverflowingPlaceholder(
            '15_1_BEMERKUNGEN',
            540,
            new SVGPlaceholder('15_2_BEMERKUNGEN', SvgMappingService.fromString),
        );
    // endregion
    /* eslint-enable no-magic-numbers */

    private readonly ORT_DATUM: SVGPlaceholder<string> =
        new SVGPlaceholder('I_ORT_DATUM', SvgMappingService.fromString);
    private readonly UNTERSCHRIFT_1: SVGPlaceholder<string> =
        new SVGPlaceholder('UNTERSCHRIFT_1', SvgMappingService.fromString);
    private readonly UNTERSCHRIFT_2: SVGPlaceholder<string> =
        new SVGPlaceholder('UNTERSCHRIFT_2', SvgMappingService.fromString);
    private readonly UNTERSCHRIFT_3: SVGPlaceholder<string> =
        new SVGPlaceholder('UNTERSCHRIFT_3', SvgMappingService.fromString);
    private readonly UNTERSCHRIFT_4: SVGPlaceholder<string> =
        new SVGPlaceholder('UNTERSCHRIFT_4', SvgMappingService.fromString);
    private readonly UNTERSCHRIFT_5: SVGPlaceholder<string> =
        new SVGPlaceholder('UNTERSCHRIFT_5', SvgMappingService.fromString);
    private readonly UNTERSCHRIFT_6: SVGPlaceholder<string> =
        new SVGPlaceholder('UNTERSCHRIFT_6', SvgMappingService.fromString);

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
    ) {
        this.GEBURTSDATUM = new SVGPlaceholder('C_GEBURTSDATUM',
            value => SvgMappingService.fromShortDate(value, DEFAULT_KORRESPONDENZ_SPRACHE));
        this.VON = new SVGPlaceholder('E_VON',
            value => SvgMappingService.fromShortDate(value, DEFAULT_KORRESPONDENZ_SPRACHE));
        this.BIS = new SVGPlaceholder('E_BIS',
            value => SvgMappingService.fromShortDate(value, DEFAULT_KORRESPONDENZ_SPRACHE));
    }

    public static fromBoolean(
        value: string | boolean,
        /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
        ignored: KorrespondenzSprache,
    ): string {
        return (value === 'yes' || value === true) ? 'block' : 'none';
    }

    public static fromNumber(value: number | null, locale: KorrespondenzSprache): string {
        return formatDecimal(value, locale);
    }

    public static fromString(
        value: string,
        /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
        ignored: KorrespondenzSprache,
    ): string {
        return value;
    }

    public static fromShortDate(value: Date | null, locale: KorrespondenzSprache): string {
        return formatDate(value, locale) || '';
    }

    private static postfachPrefix(sprache: KorrespondenzSprache): string {
        switch (sprache) {
            case KorrespondenzSprache.DE:
                return 'Postfach';
            case KorrespondenzSprache.FR:
                return 'Case postale';
            case KorrespondenzSprache.IT:
                return 'Casella postale';
            case KorrespondenzSprache.EN:
                return 'P.O. Box';
            default:
                throw new Error(`No postfach prefix defined for ${sprache}`);
        }
    }

    private static getPostfacheStrasseLine(adresse: Adresse, sprache: KorrespondenzSprache): string {
        if (adresse.postfach && adresse.strasse) {
            return `${adresse.strasse}, ${SvgMappingService.postfachPrefix(sprache)} ${adresse.postfach}`;
        }

        if (adresse.strasse) {
            return adresse.strasse;
        }

        if (adresse.postfach) {
            return `${SvgMappingService.postfachPrefix(sprache)} ${adresse.postfach}`;
        }

        return '';
    }

    public replacePlaceholders(
        svgDocument: string,
        lohnausweisPDF: LohnausweisPDF,
        widthCalculator: (text: string) => number,
    ): SVGMappingDocument {
        const document: SVGMappingDocument = {result: svgDocument, widthCalculator};

        const lohnausweis = lohnausweisPDF.lohnausweis;
        const arbeitNehmer = lohnausweisPDF.arbeitNehmer;
        const arbeitGeber = lohnausweisPDF.arbeitGeber;

        const sprache = this.locale as KorrespondenzSprache;
        this.replaceHeaderFields(document, lohnausweis, arbeitNehmer, sprache);
        this.replaceAddressFields(document, arbeitNehmer, sprache);
        this.replaceLohnausweis(document, lohnausweis, sprache);
        this.replaceFooterFields(document, arbeitGeber, sprache);

        return document;
    }

    public replaceHeaderAndAddressFields(svgDocument: string, lohnausweisPDF: LohnausweisPDF): string {
        const document: SVGMappingDocument = {result: svgDocument, widthCalculator: () => 0};

        const lohnausweis = lohnausweisPDF.lohnausweis;
        const arbeitNehmer = lohnausweisPDF.arbeitNehmer;

        this.replaceHeaderFields(document, lohnausweis, arbeitNehmer, arbeitNehmer.korrespondezSprache);
        this.replaceAddressFields(document, arbeitNehmer, arbeitNehmer.korrespondezSprache);

        return document.result;
    }

    private replaceHeaderFields(
        document: SVGMappingDocument,
        lohnausweis: Lohnausweis,
        arbeitNehmer: ArbeitNehmer,
        sprache: KorrespondenzSprache,
    ): void {

        this.IS_LOHNAUSWEIS.write(document, lohnausweis.isLohn, sprache);
        this.IS_RENTENBESCHEINIGUNG.write(document, lohnausweis.isRente, sprache);
        this.AHV_NR.write(document, arbeitNehmer.ahvNummerNeu, sprache);
        this.GEBURTSDATUM.write(document, arbeitNehmer.geburtsdatum, sprache);
        this.JAHR.write(document, lohnausweis.jahr, sprache);
        this.VON.write(document, lohnausweis.von, sprache);
        this.BIS.write(document, lohnausweis.bis, sprache);
        this.UNENTGELTLICHE_BEFOERDERUNG.write(document, lohnausweis.unentgeltlicheBefoerderung, sprache);
        this.KANTINENVERPFLEGUNG.write(document, lohnausweis.kantinenverpflegung, sprache);
    }

    private replaceAddressFields(
        document: SVGMappingDocument,
        arbeitNehmer: ArbeitNehmer,
        sprache: KorrespondenzSprache,
    ): void {
        const adresse = arbeitNehmer.adresse;

        const lines = [
            arbeitNehmer.printName,
            adresse.strasse,
            adresse.postfach ? `${SvgMappingService.postfachPrefix(sprache)} ${adresse.postfach}` : '',
            `${adresse.plz} ${adresse.ort}`,
            adresse.land,
        ].filter(v => v.trim().length > 0);

        for (let index = lines.length - 1; index < 5; index++) {
            lines.push('');
        }

        this.ARBEITNEHMER_ANREDE.write(document, lines[0], sprache);
        this.ARBEITNEHMER_NAME.write(document, lines[1], sprache);
        this.ARBEITNEHMER_ADRESSE.write(document, lines[2], sprache);
        this.ARBEITNEHMER_POSTFACH.write(document, lines[3], sprache);
        this.ARBEITNEHMER_WOHNORT.write(document, lines[4], sprache);
    }

    private replaceLohnausweis(
        document: SVGMappingDocument,
        lohnausweis: Lohnausweis,
        sprache: KorrespondenzSprache,
    ): void {
        this.LOHN_CHF.write(document, lohnausweis.lohnRente, sprache);
        this.replaceGehaltsNebenLeistungen(document, lohnausweis.gehaltsNebenLeistungen, sprache);
        this.replaceUnregelmaessigeLeistungen(document, lohnausweis.unregelmaessigeLeistungen, sprache);
        this.replaceKapitalleistungen(document, lohnausweis.kapitalLeistungen, sprache);
        this.BETEILIGUNGSRECHTE_CHF.write(document, lohnausweis.beteiligungsrechte, sprache);
        this.VERWALTUNGSENTSCHAEDIGUNGEN_CHF.write(document, lohnausweis.verwaltungsratEntschaedigungen, sprache);
        this.replaceAndereLeistungen(document, lohnausweis.andereLeistungen, sprache);
        this.BRUTTOLOHN_CHF.write(document, lohnausweis.bruttoLohnRenteTotal, sprache);
        this.BEITRAEGE_AHV_CHF.write(document, lohnausweis.beitraegeAhvIvEoAlvNbuv, sprache);
        this.replaceBeruflicheVorsorge(document, lohnausweis.beruflicheVorsorge, sprache);
        this.NETTOLOHN_CHF.write(document, lohnausweis.nettoLohnRente, sprache);
        this.QUELLENSTEUERABZUG_CHF.write(document, lohnausweis.quellensteuerAbzug, sprache);
        this.replaceSpesenVerguetung(document, lohnausweis.spesenVerguetungen, sprache);
        this.WEITERE_GEHALTSNEBENLEISTUNGEN_1.write(document, lohnausweis.weitereGehaltsNebenLeistungen, sprache);
        this.BEMERKUNGEN_1.write(document, lohnausweis.buildAllBemerkungenText(sprache), sprache);
    }

    private replaceAndereLeistungen(document: SVGMappingDocument, andereLeistungen: ValueWithDescription,
                                    sprache: KorrespondenzSprache,
    ): void {
        this.ANDERE_LEISTUNGEN_TITEL.write(document, andereLeistungen.beschreibung, sprache);
        this.ANDERE_LEISTUNGEN_CHF.write(document, andereLeistungen.value, sprache);
    }

    private replaceKapitalleistungen(document: SVGMappingDocument, kapitalLeistungen: ValueWithDescription,
                                     sprache: KorrespondenzSprache,
    ): void {
        this.KAPITALLEISUNGEN_TITEL.write(document, kapitalLeistungen.beschreibung, sprache);
        this.KAPITALLEISUNGEN_CHF.write(document, kapitalLeistungen.value, sprache);
    }

    private replaceUnregelmaessigeLeistungen(
        document: SVGMappingDocument,
        unregelmaessigeLeistungen: ValueWithDescription,
        sprache: KorrespondenzSprache,
    ): void {
        this.UNREGELMAESSIGE_LEISTUNGEN_TITEL.write(document, unregelmaessigeLeistungen.beschreibung, sprache);
        this.UNREGELMAESSIGE_LEISTUNGEN_CHF.write(document, unregelmaessigeLeistungen.value, sprache);
    }

    private replaceGehaltsNebenLeistungen(
        document: SVGMappingDocument,
        gehaltsNebenLeistungen: GehaltsNebenLeistungen,
        sprache: KorrespondenzSprache,
    ): void {
        this.GEHALTSNEBENLEISTUNG_VERPFLEGUNG_CHF.write(document, gehaltsNebenLeistungen.verpflegung, sprache);
        this.GEHALTSNEBENLEISTUNG_PRIVATANTEIL_CHF.write(document,
            gehaltsNebenLeistungen.privatanteilGeschaeftswagen,
            sprache);
        this.GEHALTSNEBENLEISTUNG_ANDERE_TITEL.write(document, gehaltsNebenLeistungen.andere.beschreibung, sprache);
        this.GEHALTSNEBENLEISTUNG_ANDERE_CHF.write(document, gehaltsNebenLeistungen.andere.value, sprache);
    }

    private replaceBeruflicheVorsorge(document: SVGMappingDocument, bvg: BeruflicheVorsorge,
                                      sprache: KorrespondenzSprache,
    ): void {
        this.BVG_ORDENTLICHE_BEITRAEGE_CHF.write(document, bvg.ordentlicheBeitraege, sprache);
        this.BVG_BEITRAEGE_FUR_EINKAUF_CHF.write(document, bvg.beitraegeFuerDenEinkauf, sprache);
    }

    private replaceSpesenVerguetung(document: SVGMappingDocument, spesenVerguetungen: SpesenVerguetungen,
                                    sprache: KorrespondenzSprache,
    ): void {
        this.IS_EFFEKTIVE_SPESEN_REISE.write(document, spesenVerguetungen.effektivReiseCheck, sprache);
        this.EFFEKTIVE_SPESEN_REISE_CHF.write(document, spesenVerguetungen.effektivReise, sprache);
        this.EFFEKTIVE_SPESEN_UEBRIGE_TITEL.write(document, spesenVerguetungen.effektivUebrige.beschreibung, sprache);
        this.EFFEKTIVE_SPESEN_UEBRIGE_CHF.write(document, spesenVerguetungen.effektivUebrige.value, sprache);
        this.PAUSCHALSPESEN_REPRAESENTATION_CHF.write(document, spesenVerguetungen.pauschalRepraesentation, sprache);
        this.PAUSCHALSPESEN_AUTO_CHF.write(document, spesenVerguetungen.pauschalAuto, sprache);
        this.PAUSCHALSPESEN_UEBRIGE_TITEL.write(document, spesenVerguetungen.pauschalUebrige.beschreibung, sprache);
        this.PAUSCHALSPESEN_UEBRIGE_CHF.write(document, spesenVerguetungen.pauschalUebrige.value, sprache);
        this.BEITRAEGE_WEITERBILDUNG_CHF.write(document, spesenVerguetungen.weiterbildung, sprache);
    }

    private splitLongText(text: string): string[] {
        const fontSizePx = 325;
        const containerWidthPx = 5500;

        return splitText(text, 'Arial, sans-serif', fontSizePx, containerWidthPx);
    }

    private replaceFooterFields(document: SVGMappingDocument, arbeitGeber: ArbeitGeber,
                                sprache: KorrespondenzSprache,
    ): void {
        const adresse = arbeitGeber.adresse;
        const ort = adresse.ort;
        const kontakt = arbeitGeber.kontakt;

        this.ORT_DATUM.write(document, `${ort}, ${formatDate(moment(), sprache)}`, sprache);

        const lines = [
            ...this.splitLongText(arbeitGeber.firma),
            kontakt.name,
            SvgMappingService.getPostfacheStrasseLine(adresse, sprache),
            adresse.land ? `${adresse.plz} ${adresse.ort}, ${adresse.land}` : `${adresse.plz} ${adresse.ort}`,
            `Tel. ${kontakt.telefon}`,
        ].filter(v => v.trim().length > 0);

        for (let index = lines.length - 1; index < 5; index++) {
            lines.push('');
        }

        this.UNTERSCHRIFT_1.write(document, lines[0], sprache);
        this.UNTERSCHRIFT_2.write(document, lines[1], sprache);
        this.UNTERSCHRIFT_3.write(document, lines[2], sprache);
        this.UNTERSCHRIFT_4.write(document, lines[3], sprache);
        // FIXME übersetzung von Tel.
        this.UNTERSCHRIFT_5.write(document, lines[4], sprache);
        this.UNTERSCHRIFT_6.write(document, lines[5], sprache);
    }
}
