// noinspection MagicNumberJS

/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBlobStream} from 'blob-stream';
import {NgProgress, NgProgressRef} from 'ngx-progressbar';
import * as PDFKit from 'pdfkit';
import {BehaviorSubject, from, fromEvent, Observable, of, Subject, throwError} from 'rxjs';
// eslint-disable-next-line
import {combineAll, first, last, map, mergeAll, mergeMap, takeUntil, tap} from 'rxjs/operators';
import * as SvgToPdfkit from 'svg-to-pdfkit';
import {BARCODE_LEFT, BARCODE_RIGHT} from '../../../core/constants';
import {LogFactory} from '../../../core/logging/log-factory';
import {isNodeStyleEventEmitter} from '../../../core/type-guards';
import {LohnausweisTyp} from '../../../lohnausweis/shared/lohnausweis-typ.enum';
import {LohnausweisPDF} from '../../../lohnausweis/shared/LohnausweisPDF.model';
import {Barcode} from '../../barcode/barcode-renderer.service';
import {BarcodeService} from '../../barcode/barcode.service';
import {AddressPosition} from './models/AddressPosition';
import {FontFace} from './models/FontFace';
import {
    AdditionalSvgPdfOptions,
    LohnausweisDocument,
    lohnausweisDocumentFromPDFModel,
    PDF_DOCUMENT_OPTIONS,
    PDF_FONTS,
} from './models/LohnausweisDocument';
import {OverflowingPlaceholderType} from './models/OverflowingPlaceholders';
import {PdfGenerationProgress} from './models/PdfGenerationProgress';
import {SVGMappingDocument} from './models/SVGMappingDocument';
import {SvgMappingService} from './svg-mapping.service';

declare function blobStream(): IBlobStream;

const LOG = LogFactory.createLog('PdfGeneratorService');

export const PDF_GENERATION_ABORTED = 'aborted pdf generation';

interface FontOptions {
    fauxItalic: boolean;
    fauxBold: false;
}

interface Cache {
    [index: string]: ArrayBuffer;
}

class FontConfig {
    constructor(
        public readonly fonts: FontFace[],
        public readonly fontWidthCalculator: (text: string) => number,
    ) {
        //
    }
}

class PDFData {
    constructor(
        public readonly lohnausweisPDF: LohnausweisPDF,
        public readonly barcodes: Barcode[] | undefined,
    ) {
    }
}

const halfLine = 0.5;
const pdfProgressId = 'pdfgeneration';

@Injectable({providedIn: 'root'})
export class PdfGeneratorService {
    private _imageCache: Cache = {};
    public readonly progress: NgProgressRef;

    private readonly progressSubject$ = new BehaviorSubject<PdfGenerationProgress | null>(null);
    public progress$ = this.progressSubject$.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly barcodeService: BarcodeService,
        private readonly svgMappingService: SvgMappingService,
        readonly progressService: NgProgress,
    ) {
        this.progress = progressService.ref(pdfProgressId);
    }

    private static fontCallback(
        family: string,
        bold: boolean,
        italic: boolean,
        ignore: FontOptions,
        availableFonts: FontFace[],
    ): string {

        let face = family
            .replace(/["']/g, '')
            .replace(/-/g, ' ')
            .replace(/, sans serif/, '');

        if (bold && italic) {
            face = `${face} Bold Italic`;
        }
        if (bold) {
            face = `${face} Bold`;
        }
        if (italic) {
            face = `${face} Italic`;
        }

        const match = availableFonts.find(fontObj => {
            const re = new RegExp(face);

            return re.test(fontObj.font.name);
        });

        if (!match) {
            if (face !== 'sans serif') {
                LOG.warn('no font found for font face ' + face);
            }

            return 'Helvetica';
        }

        // LOG.debug(`${face} -> ${match.font.url}`);

        return face;
    }

    private static warningCallback(message: string, error ?: Error): void {
        LOG.warn('SVG Warning:', message, error);
    }

    private static imageCallback(instance: PdfGeneratorService, path: string): ArrayBuffer {
        return instance._imageCache[path];
    }

    public loadSVG$(path: string): Observable<string> {
        return this.http.get(path, {responseType: 'text'});
    }

    public generatePreviewPDF$(lohnausweisPDF: LohnausweisPDF): Observable<Blob> {
        return this.generatePDFAll$([lohnausweisPDF], false, true);
    }

    public generatePDF$(lohnausweisPDF: LohnausweisPDF): Observable<Blob> {
        return this.generatePDFAll$([lohnausweisPDF]);
    }

    private registerFont(pdf: PDFKit.PDFDocument, fonts: FontFace[]): FontConfig {
        LOG.debug('fonts loaded', fonts);

        fonts.forEach(fontFace => pdf.registerFont(fontFace.font.name, fontFace.data));

        return new FontConfig(fonts, this.fontWidthCalculator(fonts, pdf));
    }

    private makePDFPage$(
        pdf: PDFKit.PDFDocument,
        lohnausweisPDF: LohnausweisPDF,
        fontConfig: FontConfig,
        preview: boolean,
        personIdent: number,
    ): Observable<void> {
        LOG.debug('lohnausweis:', lohnausweisPDF.toString(), fontConfig);

        if (preview || lohnausweisPDF.lohnausweis.ausweisTyp === LohnausweisTyp.RENTENBESCHEINIGUNG) {
            return this.convertToPDF$(pdf, fontConfig, new PDFData(lohnausweisPDF, undefined));
        }

        return from(this.barcodeService.buildBarcodeImages(lohnausweisPDF, personIdent))
            .pipe(mergeMap(
                barcodeImages => this.convertToPDF$(pdf, fontConfig, new PDFData(lohnausweisPDF, barcodeImages))),
            );
    }

    public generatePDFAll$(
        input: LohnausweisPDF[],
        showProgress = true,
        preview = false,
        forceProgress?: { current: number, totalLength: number, abort$: Subject<boolean> },
    ): Observable<Blob> {
        // LOG.debug('generatePDFAll$', input.length, input.map(i => i.toString()));
        /* eslint-disable-next-line  @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        const pdf = new PDFDocument(PDF_DOCUMENT_OPTIONS);
        const stream = pdf.pipe(blobStream());

        const totalLength = forceProgress ? forceProgress.totalLength : input.length;

        const completed$ = new BehaviorSubject<number>(forceProgress && forceProgress.current || 0);
        const pdfGenerationProgress = new PdfGenerationProgress(completed$.asObservable(), totalLength);

        if (forceProgress) {
            pdfGenerationProgress.abort$.subscribe(
                () => forceProgress.abort$.next(true),
                (err: unknown) => LOG.error(err),
            );
            forceProgress.abort$.subscribe(
                () => {
                    this.progressSubject$.next(null);
                    this.progress.complete();
                },
                (err: unknown) => LOG.error(err),
            );
        }

        // noinspection MagicNumberJS
        const hundred = 100;
        const progressIncrement = forceProgress
            ? (forceProgress.current / totalLength * hundred)
            : (1 / totalLength * hundred);
        LOG.debug('progressIncrement ', progressIncrement);
        if (showProgress) {
            this.progressSubject$.next(pdfGenerationProgress);

            this.progress.set(progressIncrement);
            this.progress.start();
        }

        const startTime = performance.now();

        const pages$: Observable<Blob> = this.loadFonts$()
            .pipe(
                map((fonts: FontFace[]) => {
                    this.setupSVGtoPDFKit(pdf, fonts);

                    return this.registerFont(pdf, fonts);
                }),
                mergeMap(fontConfig => {
                    return from(input)
                        .pipe(
                            map((lohnausweisPDF, index) => {
                                return this.makePDFPage$(pdf, lohnausweisPDF, fontConfig, preview, index).pipe(
                                    tap(() => {
                                        const current = 1 + (forceProgress ? forceProgress.current : index);

                                        // use set instead of increment: since the progress tickles forward
                                        // -> we might reach 100% too early when we increment
                                        if (showProgress) {
                                            completed$.next(current);
                                            this.progress.set(current / totalLength * hundred);
                                        }
                                        LOG.debug(`completed ${current}/${totalLength}`);
                                    }));
                            }),
                            // only process 1 PDF at a time, to allow the browser to garbage collect
                            mergeAll(1),
                            takeUntil(pdfGenerationProgress.abort$),
                        );
                }),
                // wait until the last page is processed and emit one last time
                // when there is no last item because of pdfGenerationProgress.abort$ then emit the defaultValue
                last(() => true, true),
                mergeMap(aborted => {
                    LOG.debug('pdf.end');
                    pdf.end();
                    if (forceProgress === undefined ||
                        ((forceProgress.current + 1) === forceProgress.totalLength)) {
                        this.progressSubject$.next(null);
                        this.progress.complete();
                    }

                    const duration = performance.now() - startTime;
                    LOG.debug(`processing duration ${duration} ms`);

                    if (aborted) {
                        return throwError(PDF_GENERATION_ABORTED);
                    }

                    return this.emitPdf$(stream);
                }),
            );

        return pages$;
    }

    public setImageChache(imageCache: Cache): void {
        this._imageCache = imageCache;
    }

    private loadSvgMappingDocument$(
        document: LohnausweisDocument,
        lohnausweisPDF: LohnausweisPDF,
        widthCalculator: (text: string) => number,
        forceMultipage: boolean,
    ): Observable<SVGMappingDocument> {
        LOG.debug('loadSvgMappingDocument$', document);

        return this.loadSVG$(document.mainPage.svgUrl)
            .pipe(
                mergeMap(svg => {
                    LOG.debug('loaded mapping document', document.mainPage.svgUrl);
                    const mappingDoc = this.svgMappingService.replacePlaceholders(svg, lohnausweisPDF, widthCalculator);

                    if (!mappingDoc.multiPage && !forceMultipage) {
                        return of(mappingDoc);
                    }

                    return this.loadSVG$(document.additionalPages.svgUrl)
                        .pipe(
                            tap(() => LOG.debug('loaded additional mapping document', document.additionalPages.svgUrl)),
                            map(header => this.svgMappingService.replaceHeaderAndAddressFields(header, lohnausweisPDF)),
                            map(headerSvg => {
                                const newMappingDoc: SVGMappingDocument = {
                                    ...mappingDoc,
                                    multiPage: {
                                        overflows: mappingDoc?.multiPage?.overflows ?? {},
                                        overflowHeader: headerSvg,
                                    },
                                }

                                return newMappingDoc;
                            }));
                }));
    }

    private loadFonts$(): Observable<FontFace[]> {
        return from(PDF_FONTS)
            .pipe(
                map(font => this.http.get(font.url, {responseType: 'arraybuffer'})
                    .pipe(map(data => new FontFace(font, data))),
                ),
            )
            .pipe(combineAll());
    }

    private convertToPDF$(pdf: PDFKit.PDFDocument, fontConfig: FontConfig, pdfData: PDFData): Observable<void> {
        LOG.debug('init PDF generation', pdfData.barcodes?.length);
        const document = lohnausweisDocumentFromPDFModel(pdfData.lohnausweisPDF);
        const forceOverflow = this.hasMultipleBarcodes(pdfData);

        return this.loadSvgMappingDocument$(document,
            pdfData.lohnausweisPDF,
            fontConfig.fontWidthCalculator,
            forceOverflow,
        )
            .pipe(
                map((svgMappingDoc: SVGMappingDocument) => {
                    LOG.debug('next svgMappingDoc');
                    let pageNumber = 0;

                    const barcodePosition =
                        (pdfData.lohnausweisPDF.arbeitGeber.addressPosition === AddressPosition.LEFT)
                            ? BARCODE_RIGHT
                            : BARCODE_LEFT;

                    const pageAddedListener = () => {
                        const barcode = pdfData?.barcodes?.[pageNumber];
                        if (barcode) {
                            pdf.image(barcode.dataUrl, barcodePosition.x, barcodePosition.y,
                                barcodePosition.options);
                        }

                        if (pageNumber !== 0 && svgMappingDoc.multiPage?.overflowHeader) {
                            pdf.addSVG(svgMappingDoc.multiPage.overflowHeader);
                        }

                        pageNumber++;
                    };
                    pdf.on('pageAdded', pageAddedListener);
                    pdf.addPage();

                    pdf.addSVG(svgMappingDoc.result);

                    this.addAdditionalInfo(document, svgMappingDoc, pdf);
                    LOG.debug('next svgMappingDoc finished');

                    pdf.removeListener('pageAdded', pageAddedListener);
                }));
    }

    private hasMultipleBarcodes(pdfData: PDFData) {
        const result = (pdfData?.barcodes?.length ?? 0) > 1;
        return result;
    }

    private fontWidthCalculator(fonts: FontFace[], pdf: PDFKit.PDFDocument): (text: string) => number {
        const inputFont = fonts.find(fontFace => fontFace.font.isInputFont);
        if (!inputFont) {
            throw new Error(`No input font defind in ${fonts}`);
        }

        return (text: string) => {
            pdf.font(inputFont.font.name);
            // So funktioniert es, aber es ist unschön, dass hier eine grössere Schrift verwendet wird als auf den
            // Zusatzseiten (dort is es 11 für Titel, sonst 10)
            pdf.fontSize(12);

            return pdf.widthOfString(text);
        };
    }

    private addAdditionalInfo(
        document: LohnausweisDocument,
        svgMappingDoc: SVGMappingDocument,
        pdf: PDFKit.PDFDocument,
    ): void {
        if (!svgMappingDoc.multiPage) {
            return;
        }

        const additionalPages = document.additionalPages;

        pdf.addPage();

        if (!additionalPages.overflowTitles) {
            throw new Error('no titles for overflow placeholders defined');
        }

        pdf.fontSize(additionalPages.heading.size)
            .font(additionalPages.heading.font)
            .text(additionalPages.heading.text || '')
            .moveDown(halfLine);

        pdf.fontSize(additionalPages.fontSize);

        const inputFieldWidth = pdf.page.width - pdf.page.margins.right - pdf.page.margins.left;
        additionalPages.overflowTitles.forEach((titles, placeholder) => {
            this.processOverflow(titles, placeholder, document.additionalPages, inputFieldWidth, svgMappingDoc, pdf);
        });
    }

    private processOverflow(
        titles: ReadonlyArray<string>,
        placeholder: OverflowingPlaceholderType,
        pageOptions: AdditionalSvgPdfOptions,
        inputFieldWidth: number,
        svgMappingDoc: SVGMappingDocument,
        pdf: PDFKit.PDFDocument,
    ): void {

        if (!svgMappingDoc.multiPage) {
            return;
        }

        const overflow = svgMappingDoc.multiPage.overflows[placeholder];
        if (overflow) {
            pdf.font(pageOptions.titleFont);
            titles.forEach(title => pdf.text(title));
            pdf.moveDown(halfLine);

            pdf.font(pageOptions.inputFont);
            const text = overflow.fullText;

            pdf
                .rect(pdf.x - 1, pdf.y - 1, inputFieldWidth, pdf.heightOfString(text) + 2)
                .fill(pageOptions.inputBackgroudColor)
                .fillColor('black')
                .text(text);

            pdf.moveDown();
        }
    }

    private emitPdf$(stream: IBlobStream): Observable<Blob> {
        if (!isNodeStyleEventEmitter(stream)) {
            throw new Error('Cannot create an observable from stream');
        }

        return fromEvent(stream, 'finish')
            .pipe(
                first(), // RxJS event streams are endless!
                map(() => stream.toBlob('application/pdf')),
            );
    }

    private setupSVGtoPDFKit(pdf: PDFKit.PDFDocument, fonts: FontFace[]): void {
        LOG.debug('setup SVGtoPDFKit');

        // noinspection JSUnusedGlobalSymbols
        const options = {
            fontCallback: (family: string, bold: boolean, italic: boolean, fontOptions: FontOptions) =>
                PdfGeneratorService.fontCallback(family, bold, italic, fontOptions, fonts),
            imageCallback: (imagePath: string) =>
                PdfGeneratorService.imageCallback(this, imagePath),
            warningCallback: (message: string, error: Error) =>
                PdfGeneratorService.warningCallback(message, error),
            width: 595,
            height: 842,
            precision: 9,
            assumePt: false,
        };

        pdf.addSVG = function (svg: string): void {
            // Using SvgToPdfkit converts the given svg into a pdf. The pdf must be given as parameter
            return SvgToPdfkit(this, svg, 0, 0, options);
        };
    }
}
