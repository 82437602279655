/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsDefined, ValidateNested} from 'class-validator';
import {ArbeitGeber} from '../../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {Lohnausweis} from './lohnausweis.model';

export class LohnausweisPDF {

    @IsDefined({always: true})
    @ValidateNested({always: true})
    arbeitGeber: ArbeitGeber;

    @IsDefined({always: true})
    @ValidateNested({always: true})
    arbeitNehmer: ArbeitNehmer;

    @IsDefined({always: true})
    @ValidateNested({always: true})
    lohnausweis: Lohnausweis;

    constructor(args: {
        arbeitGeber: ArbeitGeber,
        arbeitNehmer: ArbeitNehmer,
        lohnausweis: Lohnausweis
    }) {
        this.arbeitGeber = args.arbeitGeber;
        this.arbeitNehmer = args.arbeitNehmer;
        this.lohnausweis = args.lohnausweis;
    }

    public toString(): string {
        return `{${this.lohnausweis.id}/${this.lohnausweis.lohnRente}, ${this.arbeitNehmer.displayName}, ${this.arbeitGeber.displayName}}`;
    }
}
