/*
 * Copyright 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';

const routes: Routes = [
    {
        path: 'printing-development',
        loadChildren: () => import('./printing-development/printing-development.module').then(m => m.PrintingDevelopmentModule),
    },
    {
        path: 'arbeitnehmer',
        loadChildren: () => import('./arbeit-nehmer/arbeit-nehmer.module').then(m => m.ArbeitNehmerModule),
    },
    {path: '', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)},
    {path: '**', component: NotFoundComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes,
            {
                enableTracing: false,
            },
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
