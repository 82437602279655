/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsDate, IsEnum} from 'class-validator';
import {CantonType} from '../../core/canton-type.enum';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';

export class Genehmigung {
    @IsDate({always: true})
    @IsValidXMLValue({always: true})
    public datum: Date | null = null;

    @IsEnum(CantonType, {always: true})
    public kanton: CantonType | null = null;

}
