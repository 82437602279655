/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TransformationType} from 'class-transformer';
import {TransformFnParams} from 'class-transformer/types/interfaces';
import {CivilStatusType} from '../../xml-import-export/xml-import.types';
import {fromISODateString, fromISOString, toISODateString, toISOString} from '../functions/dateUtil';
import {valueGiven} from '../functions/valueGiven';

const SALARY_AMOUNT_NUMBER_FORMAT = Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

/**
 * ClassToPlain: value === null -> null, value === undefined -> undefined, else -> number
 *
 * Elements with minOccurs should probably not be exported when they don't have a value.
 * Opposed to transformSalaryAmountType, this implementation does not convert undefined to null.
 */
export function transformOptionalSalaryAmountType(params: TransformFnParams): any {
    switch (params.type) {
        case TransformationType.CLASS_TO_CLASS:
            return params.value;
        case TransformationType.CLASS_TO_PLAIN:

            return valueGiven(params.value) ? parseFloat(SALARY_AMOUNT_NUMBER_FORMAT.format(params.value)) : params.value;
        case TransformationType.PLAIN_TO_CLASS:
            return params.value ? parseFloat(params.value) : params.value;
        default:
            throw new Error(`transformation type not supported: ${params.type}" with value ${params.value} on object ${params.obj}`);
    }
}

export function transformSalaryAmountType(params: TransformFnParams): any {
    switch (params.type) {
        case TransformationType.CLASS_TO_CLASS:
            return params.value;
        case TransformationType.CLASS_TO_PLAIN:
            return params.value ? parseFloat(SALARY_AMOUNT_NUMBER_FORMAT.format(params.value)) : null;
        case TransformationType.PLAIN_TO_CLASS:
            return params.value ? parseFloat(params.value) : null;
        default:
            throw new Error(`transformation type not supported: ${params.type}" with value ${params.value} on object ${params.obj}`);
    }
}

export function transformDate(params: TransformFnParams): any {
    if (!params.value) {
        return null;
    }

    switch (params.type) {
        case TransformationType.CLASS_TO_CLASS:
            return params.value;
        case TransformationType.CLASS_TO_PLAIN:
            return toISODateString(params.value);
        case TransformationType.PLAIN_TO_CLASS:
            return fromISODateString(params.value);
        default:
            throw new Error(`transformation type not supported: ${params.type}" with value ${params.value} on object ${params.obj}`);
    }
}

export function transformDateTime(params: TransformFnParams): any {
    if (!params.value) {
        return null;
    }

    switch (params.type) {
        case TransformationType.CLASS_TO_CLASS:
            return params.value;
        case TransformationType.CLASS_TO_PLAIN:
            return toISOString(params.value);
        case TransformationType.PLAIN_TO_CLASS:
            return fromISOString(params.value);
        default:
            throw new Error(`transformation type not supported: ${params.type}" with value ${params.value} on object ${params.obj}`);
    }
}

export function transformNumberFixed(fractionDigits: number): (params: TransformFnParams) => any {
    return (params: TransformFnParams) => {
        switch (params.type) {
            case TransformationType.CLASS_TO_CLASS:
                return params.value;
            case TransformationType.CLASS_TO_PLAIN:
                return valueGiven(params.value) ? params.value.toFixed(fractionDigits) : params.value;
            case TransformationType.PLAIN_TO_CLASS:
                return Number.parseFloat(params.value);
            default:
                throw new Error(`transformation type not supported: ${params.type}" with value ${params.value} on object ${params.obj}`);
        }
    };
}

export function transformToEnum<E>(params: TransformFnParams, enumClass: any): E | null {
    if (!params.value) {
        return null;
    }

    if (params.value in enumClass) {
        // simple enums: key == value
        const enumInst: E = enumClass[params.value];

        return enumInst;
    }

    for (const key of Object.keys(enumClass)) {
        if (enumClass[key] === params.value) {
            return enumClass[key];
        }

    }

    throw new Error(`could not find enum entry: ${JSON.stringify(params.value)} in ${JSON.stringify(enumClass)}`);
}

export function transformToMap(params: TransformFnParams): any {
    switch (params.type) {
        case TransformationType.CLASS_TO_CLASS:
            return params.value;
        case TransformationType.CLASS_TO_PLAIN:
            return params.value;
        case TransformationType.PLAIN_TO_CLASS: {
            const tMap: Map<string, Set<string>> = new Map<string, Set<string>>();
            for (const key in params.value) {
                // eslint-disable-next-line no-prototype-builtins
                if (params.value.hasOwnProperty(key)) {
                    const tSet: Set<string> = new Set<string>(params.value[key]);
                    tMap.set(key, tSet);
                }
            }

            return tMap;
        }
        default:
            throw new Error(`transformation type not supported: ${params.type}" with value ${params.value} on object ${params.obj}`);
    }
}

export function transformToCivilStatusEnum(value: any, enumClass: any): CivilStatusType | null {
    let actual = value;
    if (value && value.Status) {
        // ancient Swissdec-Schema: http://www.swissdec.ch/schema/sd/20120504/SalaryDeclaration
        actual = value.Status;
    }

    return transformToEnum(actual, enumClass);
}

export function transformEmptyType(params: TransformFnParams): boolean {
    switch (typeof params.value) {
        case 'undefined':
           return false;
        case 'object':
           return true;
        case 'boolean':
            // At xml-import.service.ts, parse$, fromXml seems that this can be boolean
            return params.value;
        case 'string':
            return !!params.value && (params.value.toString().trim().toLowerCase() === 'true');
        default:
            return false;

    }
}
