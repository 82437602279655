/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {Lohnausweis} from '../lohnausweis/shared/lohnausweis.model';
import {ignoreNullAndUndefined} from '../shared/functions/isNotNullOrUndefined';
import {ArbeitGeberService} from './arbeit-geber.service';
import {LogFactory} from './logging/log-factory';
import {LohnausweiseCountService} from './lohnausweise-count.service';

export class Marks {
    /**
     * @param la Map of lohnausweisId => arbeitNehmerId
     * @param an Set of arbeitNehmerId
     * @param count Count of marks
     */
    constructor(
        public readonly la: Map<string, string>,
        public readonly an: Set<string>,
        public readonly count: number
    ) {
    }

    public isLohnausweisMarked(la: Lohnausweis): boolean {
        return this.la.has(la.id) || this.an.has(la.arbeitNehmerId);
    }

    public isArbeitNehmerMarked(an: ArbeitNehmer): boolean {
        return this.an.has(an.id);
    }

    public hasMarks(): boolean {
        return this.count > 0;
    }
}

const LOG = LogFactory.createLog('MarkerService');

@Injectable({
    providedIn: 'root'
})
export class MarkerService {

    private readonly laMarkings = new Map<string, string>();
    private readonly anMarkings = new Set<string>();

    private readonly marksSubject$ = new BehaviorSubject(new Marks(this.laMarkings, this.anMarkings, 0));
    private readonly marks$ = this.marksSubject$.asObservable();

    constructor(private readonly lohnausweiseCountService: LohnausweiseCountService,
                private readonly arbeitGeberService: ArbeitGeberService) {
        //
    }

    public lohnausweisMarked$(lohnausweis: Lohnausweis): Observable<boolean> {
        return this.marks$.pipe(
            map(marks => marks.isLohnausweisMarked(lohnausweis)),
            distinctUntilChanged()
        );
    }

    public arbeitNehmerMarked$(arbeitNehmer: ArbeitNehmer): Observable<boolean> {
        return this.marks$.pipe(
            map(marks => marks.isArbeitNehmerMarked(arbeitNehmer)),
            distinctUntilChanged()
        );
    }

    public marksCount$(): Observable<number> {
        return this.marks$.pipe(
            map(marks => marks.count)
        );
    }

    public hasMarks$(): Observable<boolean> {
        return this.marks$.pipe(
            map(marks => marks.hasMarks()),
            distinctUntilChanged()
        );
    }

    public lastMarks$(): Observable<Marks> {
        return this.marks$.pipe(take(1));
    }

    private emit(): void {
        this.arbeitGeberService.get$()
            .pipe(
                ignoreNullAndUndefined(),
                switchMap(arbeitgeber =>
                    this.lohnausweiseCountService.countLohnausweiseViaArbeitNehmer$(this.anMarkings, arbeitgeber.id)),
                map(arbeitnehmerCount => arbeitnehmerCount + this.laMarkings.size),
                take(1)
            )
            .subscribe(
                count => this.marksSubject$.next(new Marks(this.laMarkings, this.anMarkings, count)),
                (error: unknown) => LOG.error(error));

    }

    public reset(): void {
        this.laMarkings.clear();
        this.anMarkings.clear();
        this.emit();
    }

    public markLohnausweis(lohnausweis: Lohnausweis, marked: boolean): void {
        if (marked) {
            this.laMarkings.set(lohnausweis.id, lohnausweis.arbeitNehmerId);
        } else {
            this.laMarkings.delete(lohnausweis.id);
        }

        this.emit();
    }

    public markArbeitNehmer(arbeitNehmer: ArbeitNehmer, marked: boolean): void {
        if (marked) {
            this.anMarkings.add(arbeitNehmer.id);
        } else {
            this.anMarkings.delete(arbeitNehmer.id);
        }

        // Sowohl bei marked als auch un-marked:
        // marked: der Arbeitnehmer ist markiert, der Lohnausweis braucht also nicht markiert zu werden.
        // un-marked: kein Lohnausweis vom Arbeitnehmer darf mehr markiert sein.
        this.removeDependentLohnausweise(arbeitNehmer.id);

        this.emit();
    }

    private removeDependentLohnausweise(arbeitNehmerId: string): void {
        Array.from(this.laMarkings.entries()) // [[key],[value]]
            .filter(entry => entry[1] === arbeitNehmerId)
            .forEach(entry => this.laMarkings.delete(entry[0]));
    }

}
