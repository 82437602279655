<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->

<div>
    <button mat-raised-button color="primary" (click)="reset()">Datanbank Reset</button>

    <pre>
        ArbeitGeber: {{ ((allArbeitGeber$ | async) || []).length }}
        ArbeitNehmer: {{ ((allArbeitNehmer$ | async) || []).length }}
        Lohnausweise: {{ ((allLohnausweise$ | async) || []).length }}
    </pre>

    <h1>Arbeitgeber</h1>
    <button mat-raised-button color="primary" (click)="addArbeitGeber()">Arbeitgeber hinzufügen</button>

    <mat-card appearance="outlined" *ngFor="let arbeitGeber of allArbeitGeber$ | async">
        <mat-card-content><pre>{{ arbeitGeber | json }}</pre></mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="deleteArbeitGeber(arbeitGeber)">Arbeitgeber löschen
            </button>
        </mat-card-actions>
    </mat-card>


    <h1>Arbeitnehmer</h1>
    <button mat-raised-button color="primary" (click)="addArbeitNehmer()">Arbeitnehmer hinzufügen</button>

    <mat-card appearance="outlined" *ngFor="let arbeitNehmer of allArbeitNehmer$ | async">
        <mat-card-content><pre>{{ arbeitNehmer | json }}</pre></mat-card-content>
        <mat-card-actions>
            <button mat-raised-button
                    color="primary"
                    (click)="deleteArbeitNehmer(arbeitNehmer)">
                Arbeitnehmer löschen
            </button>
        </mat-card-actions>
    </mat-card>

    <h1>Lohnausweis</h1>
    <button mat-raised-button color="primary" (click)="addLohnausweis()">Lohnausweis hinzufügen</button>

    <mat-card appearance="outlined" *ngFor="let lohnausweis of allLohnausweise$ | async">
        <mat-card-content><pre>{{ lohnausweis | json }}</pre></mat-card-content>
        <mat-card-actions>
            <button mat-raised-button
                    color="primary"
                    (click)="deleteLohnausweis(lohnausweis)">
                Lohnausweis löschen
            </button>
        </mat-card-actions>
    </mat-card>
</div>
