/*
 * Copyright 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {DataStoreComponent} from './component/data-store.component';

import {DataStoreRoutingModule} from './data-store-routing.module';
import {DexieErrorComponent} from './dexie-error/dexie-error.component';

@NgModule({
    imports: [
        SharedModule,
        DataStoreRoutingModule,
    ],
    declarations: [
        DataStoreComponent,
        DexieErrorComponent,
    ],
})
export class DataStoreModule {
}
