/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Inject, LOCALE_ID} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {from, Observable, of, zip} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ArbeitNehmerService} from '../../core/arbeit-nehmer.service';
import {LogFactory} from '../../core/logging/log-factory';
import {Lohnausweis} from '../../lohnausweis/shared/lohnausweis.model';
import {ErrorType} from '../models/ErrorType';
import {PrintValidationError} from '../models/PrintValidationError';

const LOG = LogFactory.createLog('PrintErrorsDialogComponent');

export class DialogParams {
    constructor(
        public readonly error: PrintValidationError<any>,
    ) {
        //
    }
}

class ErrorDisplay {
    constructor(
        public readonly text: string,
        public readonly route: any[] | undefined,
    ) {
        //
    }
}

@Component({
    selector: 'elohn-print-errors-dialog',
    templateUrl: './print-errors-dialog.component.html',
    styleUrls: ['./print-errors-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintErrorsDialogComponent {
    public readonly ErrorType = ErrorType;
    public readonly form: UntypedFormGroup;
    public errors$?: Observable<ErrorDisplay[]>;

    constructor(
        @Inject(LOCALE_ID) public readonly locale: string,
        private readonly dialogRef: MatDialogRef<PrintErrorsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly params: DialogParams,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly arbeitNehmerService: ArbeitNehmerService,
        private readonly router: Router,
    ) {
        this.form = this.formBuilder.group({
            auswahl: [undefined, Validators.required],
        });
        this.errors$ = this.initErrors$();
    }

    private initErrors$(): Observable<ErrorDisplay[]> {
        switch (this.params.error.type) {
            case ErrorType.LOHNAUSWEIS:
                return this.mapLohnausweisErrors$();
            case ErrorType.ARBEIT_NEHMER:
                return this.mapArbeitNehmerErrors$();
            case ErrorType.ARBEIT_GEBER:
                return of([]);

            default:
                return this.mapOtherErrors$();
        }
    }

    private mapLohnausweisErrors$(): Observable<ErrorDisplay[]> {
        const arbeitNehmer$ = this.arbeitNehmerService.getAll$()
            .pipe(take(1));

        return arbeitNehmer$.pipe(
            map(allArbeitNehmer => {
                LOG.debug('errors', this.params.error.errors);
                const lohnausweise = this.params.error.errors.map(invalid => invalid.errorEntity as Lohnausweis);

                return lohnausweise.map(la => {
                    LOG.debug('show error', la, la.arbeitNehmerId);
                    const arbeitNehmer = allArbeitNehmer.find(an => !!an && an.id === la.arbeitNehmerId);
                    if (!arbeitNehmer) {
                        throw new Error(`Lohnausweis ohne Arbeitnehmer??? ${JSON.stringify(la)}`);
                    }
                    const text = `${arbeitNehmer.getDisplayName(this.locale)}: ${la.getDisplayName(this.locale)}`;
                    const route = ['arbeitnehmer', la.arbeitNehmerId, 'lohnausweis', la.id];
                    const result = new ErrorDisplay(text, route);
                    LOG.debug('show error result', result);

                    return result;
                });
            }),
        );
    }

    private mapArbeitNehmerErrors$(): Observable<ErrorDisplay[]> {
        return zip(from(this.params.error.errors)
            .pipe(
                map(invalid => {
                    const route = ['/arbeitnehmer', invalid.errorEntity.id];

                    return new ErrorDisplay(invalid.errorEntity.getDisplayName(), route);
                }),
            ),
        );
    }

    private mapOtherErrors$(): Observable<ErrorDisplay[]> {
        return zip(from(this.params.error.errors)
            .pipe(
                map(invalid => new ErrorDisplay(invalid.errorEntity.getDisplayName(), undefined)),
            ),
        );
    }

    public closeAndNavigate(routerLink: any[]): void {
        this.dialogRef.close();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(routerLink);
    }
}
