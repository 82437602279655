<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->

<ng-container *ngIf="(pdfGenerationProgress$ | async) as progress; else fallbackToolbar">
    <elohn-print-progress-toolbar [progress]="progress"></elohn-print-progress-toolbar>
</ng-container>

<ng-template #fallbackToolbar>
    <elohn-marker-toolbar *ngIf="(hasMarks$ | async) === true; else defaultToolbar"></elohn-marker-toolbar>
</ng-template>

<ng-template #defaultToolbar>
    <elohn-default-toolbar></elohn-default-toolbar>
</ng-template>
