/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Exclude, Expose} from 'class-transformer';
import {IsInt, Min, MinLength, ValidateIf} from 'class-validator';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';
import {chopDecimals} from '../../shared/functions/chopDecimals';

/**
 * Wenn der Wert angegeben ist, brauchts auch eine Beschreibung.
 */
export class ValueWithDescription {
    @ValidateIf((obj: ValueWithDescription) => obj.hasNonZeroValue(), {always: true})
    @MinLength(1, {always: true})
    @IsValidXMLValue({always: true})
    public beschreibung = '';

    @Exclude()
    @ValidateIf((obj: ValueWithDescription) => obj.hasNonZeroValue(), {always: true})
    @IsInt({always: true})
    @Min(0, {always: true})
    private _value: number | null = null;

    @Expose()
    public get value(): number | null {
        return this._value;
    }

    public set value(value: number | null) {
        this._value = chopDecimals(value);
    }

    public hasNonZeroValue(): boolean {
        return !!this.beschreibung || !!this._value;
    }

}
