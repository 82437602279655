/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsBoolean} from 'class-validator';
import {LogFactory} from '../../../core/logging/log-factory';
import {StandardBemerkungenKeys} from './standard-bemerkungen.translations';

export enum StaffShareWithoutTaxableIncomeReasonEnum {
    anwartschaftlicheRechte = 'staffShareWithoutTaxableIncome_anwartschaftlicheRechte',
    veraeusserungsSperre = 'staffShareWithoutTaxableIncome_veraeusserungsSperre',
    nichtBewertbareOptionen = 'staffShareWithoutTaxableIncome_nichtBewertbareOptionen',
    gesperrteMitarbeiterOptionen = 'staffShareWithoutTaxableIncome_gesperrteMitarbeiterOptionen',
}

const LOG = LogFactory.createLog('StaffShareWithoutTaxableIncomeReasons');

export class StaffShareWithoutTaxableIncomeReasons {
    @IsBoolean({always: true})
    anwartschaftlicheRechte = false;

    @IsBoolean({always: true})
    veraeusserungsSperre = false;

    @IsBoolean({always: true})
    nichtBewertbareOptionen = false;

    @IsBoolean({always: true})
    gesperrteMitarbeiterOptionen = false;

    public buildTranslationKeys(): StandardBemerkungenKeys[] {
        const reasonKeys: string[] = [];
        if (this.anwartschaftlicheRechte) {
            reasonKeys.push(StaffShareWithoutTaxableIncomeReasonEnum.anwartschaftlicheRechte);
        }
        if (this.veraeusserungsSperre) {
            reasonKeys.push(StaffShareWithoutTaxableIncomeReasonEnum.veraeusserungsSperre);
        }
        if (this.nichtBewertbareOptionen) {
            reasonKeys.push(StaffShareWithoutTaxableIncomeReasonEnum.nichtBewertbareOptionen);
        }
        if (this.gesperrteMitarbeiterOptionen) {
            reasonKeys.push(StaffShareWithoutTaxableIncomeReasonEnum.gesperrteMitarbeiterOptionen);
        }

        return reasonKeys;
    }

    public enableViaKey(key: string): void {
        switch (key) {
            case StaffShareWithoutTaxableIncomeReasonEnum.anwartschaftlicheRechte:
                this.anwartschaftlicheRechte = true;
                break;
            case StaffShareWithoutTaxableIncomeReasonEnum.veraeusserungsSperre:
                this.veraeusserungsSperre = true;
                break;
            case StaffShareWithoutTaxableIncomeReasonEnum.nichtBewertbareOptionen:
                this.nichtBewertbareOptionen = true;
                break;
            case StaffShareWithoutTaxableIncomeReasonEnum.gesperrteMitarbeiterOptionen:
                this.gesperrteMitarbeiterOptionen = true;
                break;
            default:
                LOG.error(`failed to recognize Reasonkey ${key}`);
                break;
        }
    }

    public atleastOneTrue(): boolean {
        return this.anwartschaftlicheRechte ||
            this.veraeusserungsSperre ||
            this.nichtBewertbareOptionen ||
            this.gesperrteMitarbeiterOptionen;
    }
}
