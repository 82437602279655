/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * das sind die boolean elemente, die leer sind wenn true und fehlen wenn false
 */
export const EMPTYTYPE_ELEM_NAMES: string[] = [
    'unknown',
    'AgriculturalEmployee',
    'Apprentice',
    'FreeTransport',
    'CanteenLunchCheck',
    'Guidance',
    'ChildAllowancePerAHV-AVS',
    'KurzArbeit',
    'MinimalEmployeeCarPartPercentage',
    'ActivityRate',
    'taxAtSourcePeriodForObjection',
    'RectificateEnabled',
    'ExpatriateRulingEnabled',
    'ContinuedProvisionOfSalaryEnabled',
    'CompanyCarClarify',
    'Unknown',
    'Missing',
    'NoContributionsAvailable',
    'TestCase'
];

export const LONG_TAG_ELEM_NAMES: string[] = [
    'FreeTransport',
    'CanteenLunchCheck',
    'ChildAllowancePerAHV-AVS',
];
