<h2 *ngIf="dialogMode === DialogMode.ALL" mat-dialog-title i18n="@@pdf-print-all-dialog.title">Alle Lohnausweise
    drucken</h2>
<h2 *ngIf="dialogMode === DialogMode.SELECTED" mat-dialog-title i18n="@@pdf-print-selected-dialog.title">
    Ausgewählte Lohnausweise drucken</h2>
<div mat-dialog-content>
    <p i18n="@@pdf-print-all-dialog.content">
        In welcher Form möchten sie die Lohnausweise herunterladen?
    </p>
</div>
<div mat-dialog-actions class="">
    <button mat-button
            color="primary"
            cdkFocusInitial
            mat-dialog-close
            i18n="@@global.abbrechen">Abbrechen</button>
    <button mat-raised-button
        i18n-matTooltip="@@pdf-print-all-dialog.single-pdf.tooltip"
        matTooltip="Alle Lohnausweise in einem PDF herunterladen"
        color="primary"
        mat-button [mat-dialog-close]="DownloadMode.SINGLE_PDF"
        cdkFocusInitial i18n="@@pdf-print-all-dialog.single-pdf">Alle in einem PDF</button>
    <button mat-raised-button
        i18n-matTooltip="@@pdf-print-all-dialog.multiple-pdfs-zipped.tooltip"
        matTooltip="Alle Lohnausweise als getrennte PDFs in einer ZIP Datei"
        color="primary"
        mat-button [mat-dialog-close]="DownloadMode.ZIP"
        i18n="@@pdf-print-all-dialog.multiple-pdfs-zipped">Mehrere PDF in einer ZIP Datei</button>
</div>
