/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import moment from 'moment';

/**
 * For parameters, see: {@link Date#UTC}.
 */
export function utcDate(year: number, month: number, date: number): Date {
    return moment(Date.UTC(year, month, date)).toDate();
}

export function toISODateString(date: Date): string {
    return date.toISOString().substr(0, 10);
}

export function fromISODateString(value: string): Date {
    return moment(Date.parse(value.substr(0, 10))).toDate();
}

export function toISOString(date: Date): string {
    return date.toISOString();
}

export function fromISOString(value: string): Date {
    return moment(Date.parse(value)).toDate();
}
