/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsNotEmpty, MaxLength, MinLength} from 'class-validator';
import {IsValidXMLValue} from '../core/validation/IsValidXMLValue';

const MIN_PLZ_LENGTH = 1;
const MAX_PLZ_LENGTH = 15;

export class Adresse {
    @IsValidXMLValue({always: true})
    public strasse = '';

    @IsValidXMLValue({always: true})
    public postfach = '';

    @IsNotEmpty({always: true})
    @MinLength(MIN_PLZ_LENGTH, {always: true})
    @MaxLength(MAX_PLZ_LENGTH, {always: true})
    @IsValidXMLValue({always: true})
    public plz = '';

    @IsNotEmpty({always: true})
    @MinLength(1, {always: true})
    @IsValidXMLValue({always: true})
    public ort = '';

    @IsValidXMLValue({always: true})
    public land = '';
}
