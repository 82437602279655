/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsInt, IsOptional, Min} from 'class-validator';
import {sum} from '../../shared/functions/sum';
import {OPTS_LOHNAUSWEIS} from '../../core/validation/validation.const';

export class BeruflicheVorsorge {

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public ordentlicheBeitraege: number | null = null;

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public beitraegeFuerDenEinkauf: number | null = null;

    public get total(): number {
        return sum(this.ordentlicheBeitraege, this.beitraegeFuerDenEinkauf);
    }
}
