/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DecimalPipe} from '@angular/common';
import {valueGiven} from './valueGiven';
import {DEFAULT_KORRESPONDENZ_SPRACHE} from '../../core/constants';

const DECIMAL_PIPE = new DecimalPipe(DEFAULT_KORRESPONDENZ_SPRACHE);

/**
 * @param fractionalDigits the maximum number of fraction digits (default: 0, i.e.: integers).
 * @param maxFractionalDigits The minimum number of fraction digits. Setting this to the same value as fractionalDigits
 * (the default) results in a fixed point number.
 */
export function formatDecimal(
    value: number | null | undefined,
    locale: string,
    fractionalDigits?: number,
    maxFractionalDigits?: number,
): string {
    if (!valueGiven(value)) {
        return '';
    }

    const minIntegers = 1;
    const minFractions = fractionalDigits || 0;
    const maxFractions = maxFractionalDigits || minFractions;

    const digitsInfo = `${minIntegers}.${minFractions}-${maxFractions}`;
    const result = DECIMAL_PIPE.transform(value, digitsInfo, locale) || `!Cannot format date: ${value}/${locale}/${digitsInfo}`;

    return result;
}
