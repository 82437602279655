/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DisplayName} from '../../core/DisplayName';
import {Invalid} from '../../core/validation/Invalid';
import {ErrorType} from './ErrorType';

export class PrintValidationError<T extends DisplayName> {
    constructor(
        public readonly type: ErrorType,
        public readonly errors: Invalid<T>[],
    ) {
    }
}
