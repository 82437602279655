<mat-toolbar color="accent" class="mat-elevation-z3 elohn-toolbar">
    <div class="elohn-toolbar-left">
        <ng-content select=".elohn-toolbar-left"></ng-content>
    </div>

    <div class="elohn-toolbar-center">
        <ng-content select=".elohn-toolbar-center"></ng-content>
    </div>

    <div class="elohn-toolbar-right">
        <ng-content select=".elohn-toolbar-right"></ng-content>
    </div>
</mat-toolbar>
