/*
 * Copyright 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {PdfPrintAllDialogComponent} from './pdf-print-all-dialog/pdf-print-all-dialog.component';
import {PdfPrintComponent} from './pdf-print/pdf-print.component';
import {PrintErrorsDialogComponent} from './print-errors-dialog/print-errors-dialog.component';
import {SaveDataDialogComponent} from './save-data-dialog/save-data-dialog.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        PdfPrintComponent,
        PrintErrorsDialogComponent,
        PdfPrintAllDialogComponent,
        SaveDataDialogComponent
    ],
    exports: [
        PdfPrintComponent,
        PrintErrorsDialogComponent,
    ],
})
export class PrintingModule {
}
