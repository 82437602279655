/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Expose, Transform, Type} from 'class-transformer';
import {
    IsArray,
    IsBoolean,
    IsDate,
    IsDefined,
    IsEmail,
    IsEnum,
    IsInt,
    IsMobilePhone,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    Matches,
    Max,
    MaxLength,
    Min,
    MinLength,
    ValidateNested,
} from 'class-validator';
import {CantonType} from '../core/canton-type.enum';
import {DEFAULT_KORRESPONDENZ_SPRACHE} from '../core/constants';
import {KorrespondenzSprache} from '../core/sprache.enum';
import {LohnausweisTyp } from '../lohnausweis/shared/lohnausweis-typ.enum';
import { AddressPosition } from '../printing/pdf/pdf-generator/models/AddressPosition';
import {
    transformDate,
    transformDateTime,
    transformEmptyType,
    transformNumberFixed,
    transformOptionalSalaryAmountType,
    transformSalaryAmountType,
    transformToCivilStatusEnum,
    transformToEnum,
} from '../shared/transform-helper/transform.helper';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

const MIN_PLZ_LENGTH = 1;
const MAX_PLZ_LENGTH = 15;

const MIN_WEEKLY_HOURS = 1;
const MAX_WEEKLY_HOURS = 99;

const MIN_WEEKLY_LESSONS = 1;
const MAX_WEEKLY_LESSONS = 99;

const IDTYPE_MIN_LENGTH = 1;
const IDTYPE_MAX_LENGTH = 255;

export enum SexType {
    F = 'F',
    M = 'M'
}

/**
 * Keep in Sync with: Zivilstand
 */
export enum CivilStatusType {
    unknown = 'unknown',
    single = 'single',
    married = 'married',
    widowed = 'widowed',
    divorced = 'divorced',
    separated = 'separated',
    registeredPartnership = 'registeredPartnership',
    partnershipDissolvedByLaw = 'partnershipDissolvedByLaw',
    partnershipDissolvedByDeath = 'partnershipDissolvedByDeath',
    partnershipDissolvedByDeclarationOfLost = 'partnershipDissolvedByDeclarationOfLost'
}

export enum ResidenceCategoryType {
    shortTerm = 'shortTerm',
    annual = 'annual',
    settled = 'settled',
    crossBorder = 'crossBorder',
    othersNotSwiss = 'othersNotSwiss'
}

export enum DegreeOfRelationshipType {
    unknown = 'unknown',
    unrelated = 'unrelated',
    OwnerWife = 'OwnerWife',
    OwnerHusband = 'OwnerHusband',
    OwnerBloodRelation = 'OwnerBloodRelation',
    OwnerSiblings = 'OwnerSiblings',
    OwnerAdoptedChild = 'OwnerAdoptedChild',
    OwnerFosterChild = 'OwnerFosterChild'
}

export class ContactPersonType {
    @IsString()
    Name!: string;

    @IsOptional()
    @IsEmail()
    EmailAddress?: string;

    @IsMobilePhone()
    PhoneNumber!: string;

    @IsOptional()
    @IsMobilePhone()
    MobilePhoneNumber?: string;

}

export class GeneralSalaryDeclarationDescriptionType {

    @Transform(transformDateTime)
    @IsDate()
    CreationDate!: Date;

    @Type(() => Number)
    @IsNumber()
    AccountingPeriod!: number; // FIXME: defined as "xs:gYear" ???

    @Type(() => ContactPersonType)
    @IsOptional()
    @ValidateNested()
    ContactPerson?: ContactPersonType;

    @IsOptional()
    @IsString()
    Comments?: string;
}

export class SVASAHVAVSNumberType {
    /**
     * AHV13
     */
    @Expose({name: 'SV-AS-Number'})
    @IsOptional()
    @IsString()
    SVASNumber?: string;

    /**
     * AHV11
     */
    @Expose({name: 'AHV-AVS-Number'})
    @IsOptional()
    @IsString()
    AhvAvsNumber?: string;
}

export class SocialInsuranceIdentificationType {
    @Expose({name: 'SV-AS-AHV-AVS-Number'})
    @IsOptional()
    @IsString()
    SvAsAhvAvsNumber?: SVASAHVAVSNumberType;

    /**
     * AHV11
     */
    @Expose({name: 'AHV-AVS-Number'})
    @IsOptional()
    @IsString()
    AhvAvsNumber?: string;

    /**
     * AHV13
     */
    @Expose({name: 'SV-AS-Number'})
    @IsOptional()
    @IsString()
    SvAsNumber?: string;

    @Type(() => Boolean)
    @IsBoolean()
    unknown = false;
}

export class WorkType {
    // @IsOptional()
    // @ValidateNested()
    // WorkingTime?: WorkingTimeType;

    // @IsOptional()
    // @ValidateNested()
    // ActivityRate?: ActivityRateType;

    // @IsOptional()
    // @ValidateNested()
    // YearsOfService?: YearsOfServiceType;

    @Transform(transformDate)
    @IsOptional()
    @ValidateNested()
    WithdrawalDate?: Date;

    @Transform((value) => transformToEnum(value, CantonType))
    @IsEnum(CantonType)
    WorkplaceCanton!: CantonType;

    // @IsBoolean()
    // AgriculturalEmployee: boolean;

    // @IsBoolean()
    // Apprentice: boolean;

    // @IsDefined()
    // @ValidateNested()
    // LeaveEntitlement: LeaveEntitlementType;

}

export class AddressType {

    @IsOptional()
    @IsString()
    ComplementaryLine?: string;

    @IsOptional()
    @IsString()
    Street?: string;

    @IsOptional()
    @IsString()
    Postbox?: string;

    @IsOptional()
    @IsString()
    Locality?: string;

    @Expose({name: 'ZIP-Code'})
    @IsString()
    @MinLength(MIN_PLZ_LENGTH)
    @MaxLength(MAX_PLZ_LENGTH)
    ZipCode!: string;

    @IsString()
    City!: string;

    @IsOptional()
    @IsString()
    Country?: string;
}

export class TimePeriodType {
    @Transform(transformDate)
    @IsDate()
    from: Date | null = null;

    @Transform(transformDate)
    @IsDate()
    until: Date | null = null;
}

export class ParticularsType {
    @Expose({name: 'Social-InsuranceIdentification'})
    @Type(() => SocialInsuranceIdentificationType)
    @IsDefined()
    @ValidateNested()
    SocialInsuranceIdentification!: SocialInsuranceIdentificationType;

    @IsOptional()
    @IsString()
    EmployeeNumber?: string;

    @IsString()
    Lastname!: string;

    @IsString()
    Firstname!: string;

    @Transform((value) => transformToEnum(value, KorrespondenzSprache) || DEFAULT_KORRESPONDENZ_SPRACHE)
    @IsEnum(KorrespondenzSprache)
    Language: KorrespondenzSprache = DEFAULT_KORRESPONDENZ_SPRACHE;

    @IsEnum(SexType)
    Sex!: SexType;

    @Transform(transformDate)
    @IsDate()
    DateOfBirth: Date | null = null;

    /**
     * Aus der Xml-Doku: Nationalität 2-stellig nach ISO 3166 Erweitert.
     *
     * Erweiterung:
     * 11= unbekannt
     * 22= staatenlos
     * In der Qualitätsstufe Plausibilität wird gegen die ISO 3166 Codes geprüft und
     * evtl. eine Warning angezeigt
     * {@link http://www.unece.org/etrades/unedocs/repository/codelists/xml/CountryCode.xsd}
     */
    @MinLength(2)
    @MaxLength(2)
    @Matches(/[A-Z][A-Z]|11|22/) // NationalityType
    Nationality!: string;

    @Transform(({value}) => transformToCivilStatusEnum(value, CivilStatusType))
    @IsEnum(CivilStatusType)
    CivilStatus!: CivilStatusType;

    @Type(() => Boolean)
    @IsOptional()
    @IsBoolean()
    SingleParent?: boolean;

    @Type(() => AddressType)
    @ValidateNested()
    Address!: AddressType;

    @IsOptional()
    @IsEnum(CantonType)
    ResidenceCanton?: CantonType;

    @IsOptional()
    @IsEnum(ResidenceCategoryType)
    ResidenceCategory?: ResidenceCategoryType;

    @IsOptional()
    @IsEnum(DegreeOfRelationshipType)
    DegreeOfRelationship?: DegreeOfRelationshipType;
}

export class TaxAnnuityType {
    @Type(() => TimePeriodType)
    @IsDefined()
    @ValidateNested()
    Period!: TimePeriodType;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsNumber()
    Income!: number;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsNumber()
    GrossIncome!: number;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsNumber()
    NetIncome!: number;
}

export class SortSumType {
    @IsString()
    Text!: string;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsNumber()
    Sum!: number;
}

export class SortSumOptionalType {
    @IsString()
    Text!: string;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Sum?: number;
}

export class FringeBenefitsType {
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    FoodLodging?: number;

    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    CompanyCar?: number;

    @Type(() => SortSumType)
    @IsOptional()
    @ValidateNested()
    Other?: SortSumType;
}

export class TaxBVGLPPContributionType {
    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Regular?: number;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Purchase?: number;
}

export class GrantType {
    @Transform(transformDate)
    @IsDate()
    Allowed!: Date;

    @IsEnum(CantonType)
    Canton!: CantonType;
}

export class RectificateType {
    @Transform(transformDate)
    @IsDate()
    OriginalDate!: Date;

    @IsOptional()
    @IsString()
    OriginalDocID?: string;
}

export class ContinuedProvisionOfSalaryType {
    @IsString()
    CHF: string | null = null;

    @IsString()
    Lastname: string | null = null;

    @IsString()
    Firstname: string | null = null;

    @Type(() => AddressType)
    @ValidateNested()
    Address: AddressType = new AddressType();
}

export class StandardRemarkType {
    @IsOptional()
    @IsString()
    StaffShareThirdCompany?: string;

    @Expose({name: 'ChildAllowancePerAHV-AVS'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    ChildAllowancePerAhvAvs = false;

    @Expose({name: 'KurzArbeit'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    KurzArbeit = false;

    @Expose({name: 'MinimalEmployeeCarPartPercentage'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    MinimalEmployeeCarPartPercentage: boolean = false;

    @Expose({name: 'ActivityRate'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    ActivityRate: boolean = false;

    @Expose({name: 'taxAtSourcePeriodForObjection'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    TaxAtSourcePeriodForObjection: boolean = false;


    @Expose({name: 'ExpatriateRulingEnabled'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    ExpatriateRulingEnabled: boolean = false;

    @Expose({name: 'ContinuedProvisionOfSalaryEnabled'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    ContinuedProvisionOfSalaryEnabled: boolean = false;

    @Expose({name: 'ContinuedProvisionOfSalary'})
    @Type(() => ContinuedProvisionOfSalaryType)
    @IsOptional()
    @ValidateNested()
    ContinuedProvisionOfSalary?: ContinuedProvisionOfSalaryType;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    RelocationCosts?: number;

    @Type(() => GrantType)
    @IsOptional()
    @ValidateNested()
    StaffShareMarketValue?: GrantType;

    @IsOptional()
    @MinLength(3) // ReasonType
    StaffShareWithoutTaxableIncome?: string;

    @Type(() => GrantType)
    @IsOptional()
    @ValidateNested()
    PrivatePartCompanyCar?: GrantType;

    @Expose({name: 'CompanyCarClarify'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    CompanyCarClarify: boolean = false;

    // region custom types
    @Type(() => GrantType)
    @IsOptional()
    @ValidateNested()
    SpesenReglementGenehmigt?: GrantType;

    @Transform(transformOptionalSalaryAmountType)
    @IsOptional()
    @IsNumber()
    mehrereLohnausweiseAnzahl?: number;

    @Type(() => GrantType)
    @IsOptional()
    @ValidateNested()
    ExpatriateRulingValue?: GrantType;

    @Expose({name: 'RectificateEnabled'})
    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    RectificateEnabled: boolean = false;

    @Type(() => RectificateType)
    @IsOptional()
    @ValidateNested()
    Rectificate?: RectificateType;

    @Transform(transformOptionalSalaryAmountType)
    @IsOptional()
    @IsNumber()
    erwerbsausfallTage?: number;
    // endregion
}

export class LumpSumType {
    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Representation?: number;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Car?: number;

    @Type(() => SortSumType)
    @IsOptional()
    @ValidateNested()
    Other?: SortSumType;
}

export class EffectiveType {
    @Transform(transformOptionalSalaryAmountType)
    @IsOptional()
    @IsNumber()
    TravelFoodAccommodation?: number | null;

    @Type(() => SortSumOptionalType)
    @IsOptional()
    @ValidateNested()
    Other?: SortSumOptionalType;
}

export class ChargesType {
    @Type(() => EffectiveType)
    @IsOptional()
    @ValidateNested()
    Effective?: EffectiveType;

    @Type(() => LumpSumType)
    @IsOptional()
    @ValidateNested()
    LumpSum?: LumpSumType;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Education?: number;
}

export class TaxSalaryType {
    @Type(() => TimePeriodType)
    @IsDefined()
    @ValidateNested()
    Period!: TimePeriodType;

    @Type(() => Number)
    @IsNumber()
    Year!: number;

    @IsEnum(LohnausweisTyp)
    @Transform((val) => transformToEnum(val, LohnausweisTyp))
    @IsDefined()
    LohnausweisTyp: LohnausweisTyp = LohnausweisTyp.LOHNAUSWEIS;

    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    FreeTransport = false;

    @Transform(transformEmptyType, {toClassOnly: true})
    @IsBoolean()
    CanteenLunchCheck = false;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    Income?: number;

    @Type(() => FringeBenefitsType)
    @IsOptional()
    @ValidateNested()
    FringeBenefits?: FringeBenefitsType;

    @Type(() => SortSumType)
    @IsOptional()
    @ValidateNested()
    SporadicBenefits?: SortSumType;

    @Type(() => SortSumType)
    @IsOptional()
    @ValidateNested()
    CapitalPayment?: SortSumType;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    OwnershipRight?: number;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    BoardOfDirectorsRemuneration?: number;

    @Type(() => SortSumType)
    @IsOptional()
    @ValidateNested()
    OtherBenefits?: SortSumType;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsNumber()
    GrossIncome!: number;

    @Expose({name: 'AHV-ALV-NBUV-AVS-AC-AANP-Contribution'})
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    AhvAlvNbuvAvsAcAanpContribution?: number;

    @Expose({name: 'BVG-LPP-Contribution'})
    @Type(() => TaxBVGLPPContributionType)
    @IsOptional()
    @ValidateNested()
    BvgLppContribution?: TaxBVGLPPContributionType;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsNumber()
    NetIncome!: number;

    @Type(() => Number)
    @Transform(transformSalaryAmountType)
    @IsOptional()
    @IsNumber()
    DeductionAtSource?: number;

    // @Type(() => Number)
    // @Transform(transformSalaryAmountType)
    // @IsOptional()
    // @IsNumber()
    //     //FIXME: implement ChargesRuleType?
    // ChargesRule?: number;

    @Type(() => ChargesType)
    @IsOptional()
    @ValidateNested()
    Charges?: ChargesType;

    @IsOptional()
    @IsString()
    OtherFringeBenefits?: string;

    @Type(() => StandardRemarkType)
    @IsOptional()
    @ValidateNested()
    StandardRemark?: StandardRemarkType;

    @IsOptional()
    @IsString()
    Remark?: string;
}

export class TaxSalariesType {
    @Type(() => TaxAnnuityType)
    @IsOptional()
    @IsArray()
    @ValidateNested({each: true})
    TaxAnnuity?: TaxAnnuityType[];

    @Type(() => TaxSalaryType)
    @IsOptional()
    @IsArray()
    @ValidateNested({each: true})
    TaxSalary?: TaxSalaryType[];
}

export class PersonType {

    @Type(() => ParticularsType)
    @IsDefined()
    @ValidateNested()
    Particulars!: ParticularsType;

    @Type(() => WorkType)
    @IsDefined()
    @ValidateNested()
    Work!: WorkType;

    // @IsOptional();
    // @ValidateNested()
    // 'AHV-AVS-Salaries'?: AHVAVSSalariesType;

    // @IsOptional()
    // @ValidateNested()
    // 'UVG-LAA-Salaries'?: UVGLAASalariesType;

    // @IsOptional()
    // @ValidateNested()
    // 'UVGZ-LAAC-Salaries'?: UVGZLAACSalariesType;

    // @IsOptional()
    // @ValidateNested()
    // 'KTG-AMC-Salaries'?: KTGAMCSalariesType;

    // @IsOptional()
    // @ValidateNested()
    // 'BVG-LPP-Salaries'?: BVGLPPSalariesType;

    // @IsOptional()
    // @ValidateNested()
    // 'FAK-CAF-Salaries'?: FAKCAFSalariesType;

    @Type(() => TaxSalariesType)
    @IsOptional()
    @ValidateNested()
    TaxSalaries?: TaxSalariesType;

    // @IsOptional()
    // @ValidateNested()
    // StatisticSalaries?: StatisticSalariesType;

}

export class CompanyOwnerType {
    @IsString()
    Firstname!: string;

    @IsString()
    Lastname!: string;
}

export class CompanyNameType {
    @Expose({name: 'HR-RC-Name'})
    @IsString()
    HrRcName?: string;

    @IsOptional()
    @IsArray()
    @IsString({
        each: true,
    })
    @MaxLength(2, {
        each: true,
    })
    ComplementaryLine?: string[];
}

export class WeeklyHoursAndLessonsType {
    @Transform(transformNumberFixed(2))
    @IsNumber()
    @Min(MIN_WEEKLY_HOURS)
    @Max(MAX_WEEKLY_HOURS)
    @Matches(/[0-9]+\.[0-9]{2}/)
    WeeklyHours?: number;

    @IsInt()
    @Min(MIN_WEEKLY_LESSONS)
    @Max(MAX_WEEKLY_LESSONS)
    WeeklyLessons?: number;
}

export class CompanyWorkingTimeType {
    @Transform(transformNumberFixed(2))
    @IsOptional()
    @IsNumber()
    @Min(MIN_WEEKLY_HOURS)
    @Max(MAX_WEEKLY_HOURS)
    @Matches(/[0-9]+\.[0-9]{2}/)
    WeeklyHours?: number;

    @IsOptional()
    @IsInt()
    @Min(MIN_WEEKLY_LESSONS)
    @Max(MAX_WEEKLY_LESSONS)
    WeeklyLessons?: number;

    @IsOptional()
    @ValidateNested()
    WeeklyHoursAndLessons?: WeeklyHoursAndLessonsType;

    @Expose({name: '@CompanyWorkingTimeID', toPlainOnly: true})
    @IsString()
    @Matches(/#.*/)
    CompanyWorkingTimeID!: string;
}

export class BurReeType {
    // 'BUR-REE-Number'
    // address: AddressType;

    @Type(() => CompanyWorkingTimeType)
    @IsArray()
    @IsDefined({each: true})
    @ValidateNested({each: true})
    CompanyWorkingTime!: CompanyWorkingTimeType[];
}

export class CompanyDescriptionType {
    @Type(() => CompanyNameType)
    @IsDefined()
    @ValidateNested()
    Name!: CompanyNameType;

    @Type(() => CompanyOwnerType)
    @IsOptional()
    @ValidateNested()
    Owner?: CompanyOwnerType;

    @Type(() => AddressType)
    @IsDefined()
    @ValidateNested()
    Address!: AddressType;

    @Transform((value) => transformToEnum(value, AddressPosition) || AddressPosition.RIGHT)
    @IsNotEmpty()
    AddressPosition: AddressPosition = AddressPosition.RIGHT;

    @Expose({name: 'UID-EHRA'})
    @IsOptional()
    @IsString()
    @Matches(/CH-[0-9]{3}\.[0-9]{1}\.[0-9]{3}\.[0-9]{3}-[0-9]{1}/)
    UidEhra?: string;

    @Expose({name: 'PhoneNumber'})
    @IsOptional()
    @IsString()
    PhoneNumber?: string;

    // 'UID-BFS': string;
    @Expose({name: 'BUR-REE'})
    @Type(() => BurReeType)
    @IsArray()
    @IsDefined({each: true})
    @ValidateNested({each: true})
    BurRee!: BurReeType[];
}

export class PersonsType {
    @Type(() => PersonType)
    @IsDefined({each: true})
    @ValidateNested({each: true})
    Person: PersonType[] = [];
}

export class SalaryCountersType {
    // lots of optional attributes
}

export class CompanyType {
    @Type(() => CompanyDescriptionType)
    @IsDefined()
    @ValidateNested()
    CompanyDescription!: CompanyDescriptionType;

    @Type(() => PersonsType)
    @IsDefined()
    @ValidateNested()
    Staff!: PersonsType;

    // @IsDefined()
    // @ValidateNested()
    // Insurances: CustomerIdentificationType;

    // @IsDefined()
    // @ValidateNested()
    // SalaryTotals: SalaryTotalsType;

    @Type(() => SalaryCountersType)
    @IsDefined()
    @ValidateNested()
    SalaryCounters!: SalaryCountersType;

    // @IsDefined()
    // @ValidateNested()
    // Statistic: StatisticType;
}

export class SalaryDeclarationType {
    @Type(() => CompanyType)
    @IsDefined()
    @ValidateNested()
    Company!: CompanyType;

    @Type(() => GeneralSalaryDeclarationDescriptionType)
    @IsDefined()
    @ValidateNested()
    GeneralSalaryDeclarationDescription!: GeneralSalaryDeclarationDescriptionType;

}

export class UserAgentType {
    @IsString()
    Producer!: string;

    @IsString()
    Name!: string;

    @IsString()
    Version!: string;

    @IsString()
    Certificate!: string;

    /**
     * Aus der Doku:
     * Die Version des Lohnstandard-CH (ELM) nach der die Zertifizierung durchgeführt wurde.(3.0 oder höher)
     */
    @IsNumber()
    @Min(0)
    @Expose({name: 'ELM-SalaryStandardVersion'})
    ELMSalaryStandardVersion!: number;

}

export enum LanguageCodeType {
    de = 'de',
    fr = 'fr',
    it = 'it',
    en = 'en'
}

export class RequestContextType {
    @IsDefined()
    @ValidateNested()
    UserAgent!: UserAgentType;

    @IsDefined()
    @ValidateNested()
    CompanyName!: CompanyNameType;

    @Transform(() => transformDate)
    @IsDate()
    TransmissionDate!: Date; // dateTime

    @IsString()
    @MinLength(IDTYPE_MIN_LENGTH)
    @MaxLength(IDTYPE_MAX_LENGTH)
    RequestID!: string; // IDType

    @Transform((val) => transformToEnum(val, LanguageCodeType))
    @IsEnum(LanguageCodeType)
    LanguageCode!: LanguageCodeType;

    @Type(() => Boolean)
    TestCase = false;

    // minOccurs=0
    // MonitoringID: IDType;

    // minOccurs=0
    // PartialDeclaration: PartialDeclarationType;
}

export class AddresseeType {
    // contains lots of optional elements... of which we do not use any
    // 'AHV-AVS': ControlsMultiType;
    // 'UVG-LAA': ControlsMultiType;
    // 'UVGZ-LAAC': ControlsMultiType;
    // 'KTG-AMC': ControlsMultiType;
    // 'BVG-LPP': ControlsMultiType;
    // 'FAK-CAF': ControlsMultiType;
    // 'CantonalTaxAdministration': ControlsType;
    // 'SwissFederalStatisticalOffice': ControlsType;
}

export class JobType {
    @IsDefined()
    @ValidateNested()
    Addressees!: AddresseeType;

    // minOccurs=0
    // EndUserNotification: UserNotificationType;
}

export class SalaryDeclarationRequestType {
    @Type(() => RequestContextType)
    @IsDefined()
    @ValidateNested()
    RequestContext!: RequestContextType;

    @Type(() => JobType)
    @IsDefined()
    @ValidateNested()
    Job!: JobType;

    // minOccurs=0
    // Substitution: SubstitutionType;

    @Type(() => SalaryDeclarationType)
    @IsDefined()
    @ValidateNested()
    SalaryDeclaration!: SalaryDeclarationType;
}

export class XmlRoot {
    @Type(() => SalaryDeclarationRequestType)
    @IsDefined()
    @ValidateNested()
    DeclareSalary!: SalaryDeclarationRequestType;
}
