/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsDefined, IsNotEmpty} from 'class-validator';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';

export class Kontakt {

    @IsNotEmpty()
    @IsValidXMLValue({always: true})
    public name = '';

    @IsDefined()
    @IsNotEmpty()
    @IsValidXMLValue({always: true})
    // @IsMobilePhone(PHONE_REGION)
    public telefon = '';
}
