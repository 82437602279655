/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Inject, LOCALE_ID} from '@angular/core';
import {saveAs} from 'file-saver';
import {from, merge, Observable} from 'rxjs';
import {filter, map, mergeMap, reduce} from 'rxjs/operators';
import {firstBy} from 'thenby';
import {ArbeitNehmer} from '../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {DataStoreService} from '../../data-store/data-store.service';
import {LogFactory} from '../../core/logging/log-factory';
import {MarkerService} from '../../core/marker.service';
import {Lohnausweis} from '../../lohnausweis/shared/lohnausweis.model';
import {PDF_GENERATION_ABORTED} from '../../printing/pdf/pdf-generator/pdf-generator.service';
import {PrintingService} from '../../printing/printing.service';
import {ignoreNullAndUndefined} from '../../shared/functions/isNotNullOrUndefined';
import {
    PdfPrintAllDialogComponent,
    DialogMode, DownloadMode
} from '../../printing/pdf-print-all-dialog/pdf-print-all-dialog.component';
import { MatDialog } from '@angular/material/dialog';

const LOG = LogFactory.createLog('MarkerToolbarComponent');

type PrintEntry = [ArbeitNehmer, Lohnausweis];

@Component({
    selector: 'elohn-marker-toolbar',
    templateUrl: './marker-toolbar.component.html',
    styleUrls: ['./marker-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerToolbarComponent {

    marksCount$: Observable<number>;

    constructor(
        private readonly markerService: MarkerService,
        private readonly printingService: PrintingService,
        private readonly dialog: MatDialog,
        private readonly dataStoreService: DataStoreService,
        @Inject(LOCALE_ID) private readonly locale: string,
    ) {
        this.marksCount$ = this.markerService.marksCount$();
    }

    public resetSelection(): void {
        this.markerService.reset();
    }

    public printMarked(zipped = false): void {
        const lohnausweise$: Observable<Lohnausweis> = this.fromMarkedLohnausweise$();
        const fromArbeitNehmer$: Observable<Lohnausweis> = this.fromMarkedArbeitNehmer$();

        const selected$: Observable<PrintEntry[]> =
            // zip(this.joinArbeitNehmer(merged$)) not working as expected? (i.e.: does not zip but emit multiple times)
            this.joinArbeitNehmer$(merge(lohnausweise$, fromArbeitNehmer$))
                .pipe(
                    reduce((acc: PrintEntry[], value: PrintEntry) => {
                        acc.push(value);

                        return acc;
                    }, <PrintEntry[]>[]),
                );

        if (zipped) {
            this.printMarkedZipped(selected$);
        } else {
            this.printMarkedNormal(selected$);
        }

    }

    private printMarkedNormal(selected$: Observable<PrintEntry[]>): void {
        selected$
        .pipe(
            map(arr => this.sortByDisplayName(arr)),
            map(entries => entries.map(e => e[1])), // extract Lohnausweis
            mergeMap(lohnausweise => this.printingService.formatPDF$(lohnausweise)),
        )
        .subscribe(
            result => saveAs(result.blob, result.fileName),
            (error: unknown) => {
                if (error !== PDF_GENERATION_ABORTED) {
                    LOG.error(error);
                }
            },
        );
    }

    private printMarkedZipped(selected$: Observable<PrintEntry[]>): void {
        selected$
        .pipe(
            map(arr => this.sortByDisplayName(arr)),
            map(entries => entries.map(e => e[1])), // extract Lohnausweis
        )
        .subscribe(
            lohnausweise$ => this.printingService.printArrayZipped(lohnausweise$),
            (error: unknown) => {
                if (error !== PDF_GENERATION_ABORTED) {
                    LOG.error(error);
                }
            },
        );
    }

    private sortByDisplayName(arr: PrintEntry[]): PrintEntry[] {
        arr.sort(
            firstBy<PrintEntry, string>(entry => entry[0].getDisplayName())
                .thenBy<PrintEntry, string>(entry => entry[1].getDisplayName(this.locale)),
        );

        return arr;
    }

    public printChoice(): void {
        PdfPrintAllDialogComponent.open(this.dialog, DialogMode.SELECTED)
            .afterClosed()
            .subscribe(
                (downloadMode) => {
                    if (downloadMode === DownloadMode.SINGLE_PDF) {
                        this.printMarked(false);
                    } else if (downloadMode === DownloadMode.ZIP) {
                        this.printMarked(true);
                    } else {
                        LOG.error(`Not implemented: ${downloadMode}`);
                    }
                },
                (err: unknown) => LOG.error(err),
            );
    }

    private fromMarkedArbeitNehmer$(): Observable<Lohnausweis> {
        return this.markerService.lastMarks$().pipe(
            mergeMap(marks => from(marks.an.keys())),
            mergeMap(arbeitNehmerId =>
                this.dataStoreService.lohnausweis.findByAttribute$('arbeitNehmerId', arbeitNehmerId)
            ),
            // eslint-disable-next-line
            mergeMap(lohnausweise => from(lohnausweise))
        );
    }

    private fromMarkedLohnausweise$(): Observable<Lohnausweis> {
        return this.markerService.lastMarks$().pipe(
            mergeMap(marks => from(marks.la.keys())),
            mergeMap(lohnausweisId => this.dataStoreService.lohnausweis.get$(lohnausweisId)),
            ignoreNullAndUndefined(),
        );
    }

    private joinArbeitNehmer$(lohnausweis$: Observable<Lohnausweis>): Observable<PrintEntry> {
        const result$ = lohnausweis$.pipe(
            mergeMap((lohnausweis: Lohnausweis) =>
                this.dataStoreService.arbeitNehmer.get$(lohnausweis.arbeitNehmerId)
                    .pipe(
                        filter(an => !!an),
                        map(an => an!),
                        map(an => <[ArbeitNehmer, Lohnausweis]>[an, lohnausweis])
                    )
            ),
        );

        return result$;
    }
}
