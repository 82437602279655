/**
 * Big Endian: MSB at highest index.
 *
 * See unit-tests for examples.
 */
export function numberAsByteArray(n: number): Uint8Array {
    return new Uint8Array(new Int32Array([n]).buffer)
        // MSB:
        .reverse();
}
