import {Injectable} from '@angular/core';
import JSZip from 'jszip';
import {LogFactory} from '../../core/logging/log-factory';

const LOG = LogFactory.createLog('zipper.service');

@Injectable({providedIn: 'root'})
export class ZipperService {

    async zip(content: string): Promise<Uint8Array> {
        LOG.debug('zip', content);

        const zip = new JSZip();
        // Der Name des XML Files muss txab sein (wie in ViewGen)
        zip.file('txab', content);

        return zip.generateAsync({
            type: 'uint8array',
            compression: 'DEFLATE',
            compressionOptions: {
                level: 9,
            },
        });
    }
}
