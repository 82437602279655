import {Type} from 'class-transformer';
import {IsBoolean, IsDefined, IsNumber, ValidateIf, ValidateNested} from 'class-validator';
import {ELM4Genehmigung} from './ELM4Genehmigung';
import {ELM4GeschaeftsFahrzeugAussendienstBerechnungsArt} from './ELM4GeschaeftsFahrzeugAussendienstBerechnungsArt';
import {ELM4StaffShareWithoutTaxableIncomeReasons} from './ELM4StaffShareWithoutTaxableIncomeReasons';

export class ELM4StandardBemerkungen {
    /*
    Checkbox "Kinderzulagen durch die Ausgleichskasse bezahlt" in SB-comp
    XSD: ChildAllowancePerAHV-AVS
    XML Import/EXport: ChildAllowancePerAhvAvs
    Barcode: ChildAllowancePerAhvAvs
    Barcode reduced: CAPA
     */
    @IsBoolean({always: true})
    public childAllowancePerAHVAVSEnabled = false;

    /*
    Checkbox "Kurzarbeitsentschädigung in Ziffer 1 enthalten" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public kurzArbeit = false;

    /*
    Checkbox "Umzugskosten durch Arbeitgeber" in SB-comp
    XML Import/Export
    Barcode
     */
    @IsBoolean({always: true})
    public relocationCostsEnabled = false;

    /*
    Input in SB-comp
    XSD: RelocationCosts
    XML Import/Export: RelocationCosts
    Barcode: RelocationCosts
    Barcode reduced: RC
     */
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.relocationCostsEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    public relocationCosts: number | null = null;

    /*
    Checkbox "Mitarbeiterbeteilgung-Verkehrswert" in SB-comp und Wizard Step 3
    XML Import/Export
    Barcode
     */
    @IsBoolean({always: true})
    public staffShareMarketValueEnabled = false;

    /*
    SB-comp & Wizard Step 3
    XSD: staffShareMarketValue
    XML Import/Export: staffShareMarketValue
    Barcode: staffShareMarketValue
    Barcode reduced: SSMV
     */
    @Type(() => ELM4Genehmigung)
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.staffShareMarketValueEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public readonly staffShareMarketValue: ELM4Genehmigung = new ELM4Genehmigung();

    /*
    SB-comp checkbox "Mitarbeiterbeteilgung ohne steuerbares Einkommen"
    Aktiviert die 4 Checkboxen für StaffShareWithoutTaxableIncomeReasons
     */
    @IsBoolean({always: true})
    public staffShareWithoutTaxableIncomeEnabled = false;

    /*
    SB-comp und Wizard Step 3
    XSD: StaffShareWithoutTaxableIncome
    XML Import/Export: StaffShareWithoutTaxableIncome
    Barcode: StaffShareWithoutTaxableIncome
    Barcode reduced: SSWTI
     */
    @Type(() => ELM4StaffShareWithoutTaxableIncomeReasons)
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.staffShareWithoutTaxableIncomeEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public staffShareWithoutTaxableIncomeReasons = new ELM4StaffShareWithoutTaxableIncomeReasons();

    /*
    SB-comp checkbox "Spesenreglement durch" und Wizard Step 5
     */
    @IsBoolean({always: true})
    public spesenReglementGenehmigtEnabled = false;

    /*
    SB-comp & Wizard Step 5
    XSD: nicht definiert!
    XML: SpesenReglementGenehmigt
    Barcode: SpesenReglementGenehmigt
    Barcode reduced: nicht definiert!
     */
    @Type(() => ELM4Genehmigung)
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.spesenReglementGenehmigtEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public readonly spesenReglementGenehmigt: ELM4Genehmigung = new ELM4Genehmigung();

    /*
    SB-comp checkbox "Einer von"
     */
    @IsBoolean({always: true})
    public mehrereLohnausweiseEnabled = false;

    /*
    SB-comp input mehrereLohnausweiseAnzahl
    XSD:nicht definiert!
    XML: mehrereLohnausweiseAnzahl
    Barcode: nicht definiert!
    Barcode reduced: nicht definiert!
     */
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.mehrereLohnausweiseEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    public mehrereLohnausweiseAnzahl: number | null = null;

    /*
    SB-comp checkbox "Tage mit Erwerbsausfall"
     */
    @IsBoolean({always: true})
    public erwerbsausfallEnabled = false;

    /*
    SB-comp input erwerbsausfallTage
    XSD: nicht definiert!
    XML: erwerbsausfallTage
    Barcode: nicht definiert!
    Barcode reduced: nicht definiert!
     */
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.erwerbsausfallEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    public erwerbsausfallTage: number | null = null;

    /*
    SB-comp checkbox "Mitarbeiter mit Geschäftsfahrzeug und im Aussendienst tätig."
     */
    @IsBoolean({always: true})
    public geschaeftsFahrzeugAussendienstEnabled = false;

    /*
    SB-comp input "Aussendienstanteil in Prozent"
    XSD: nicht definiert!
    XML: geschaeftsFahrzeugAussendienstProzent
    Barcode: nicht definiert!
    Barcode reduced: nicht definiert!
     */
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.geschaeftsFahrzeugAussendienstEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    public geschaeftsFahrzeugAussendienstProzent: number | null = null;

    /*
    SB-comp select
    XSD: nicht definiert!
    XML: geschaeftsFahrzeugAussendienstBerechnungsart
    Barcode: nicht definiert!
    Barcode reduced: nicht definiert!
     */
    @ValidateIf((sb: ELM4StandardBemerkungen) => sb.geschaeftsFahrzeugAussendienstEnabled, {always: true})
    @IsDefined({always: true})
    public geschaeftsFahrzeugAussendienstBerechnungsart: ELM4GeschaeftsFahrzeugAussendienstBerechnungsArt | null = null;

    /*
    Sb-comp input
    XSD: nicht definiert!
    XML: geschaeftsFahrzeugAussendienstBerechnungsartValue
    Barcode: nicht definiert!
    Barcode reduced: nicht definiert!
     */
    @Type(() => ELM4Genehmigung)
    @ValidateIf((sb: ELM4StandardBemerkungen) => {
        return sb.geschaeftsFahrzeugAussendienstEnabled &&
            sb.geschaeftsFahrzeugAussendienstBerechnungsart === ELM4GeschaeftsFahrzeugAussendienstBerechnungsArt.VORABBESCHEID_STEUERVERWALTUNG;
    }, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public geschaeftsFahrzeugAussendienstBerechnungsartValue: ELM4Genehmigung = new ELM4Genehmigung();

}
