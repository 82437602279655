/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ARBEITGEBER_FIXTURE_ID, FIXTURE_JAHR} from '../../arbeit-geber/shared/arbeit-geber.fixture';
import {ARBEITNEHMER_FIXTURE_ID} from '../../arbeit-nehmer/shared/arbeit-nehmer.fixture';
import {Lohnausweis} from './lohnausweis.model';
import moment from 'moment';

export const LOHNAUSWEIS_BRUTTOLOHN_TOTAL = 103648;
export const LOHNAUSWEIS_NETTOLOHN_RENTE = 101291;

export const LOHNAUSWEIS_FIXTURE_ID = '07b8fc6e-5536-42f9-a097-6906b32cb514';

/* eslint-disable no-magic-numbers */
export class LohnausweisFixture extends Lohnausweis {
    // noinspection FunctionTooLongJS
    constructor(id?: string, arbeitNehmerId?: string, arbeitGeberId?: string, standardBemerkungen = true) {
        super(FIXTURE_JAHR, {
            id: id || LOHNAUSWEIS_FIXTURE_ID,
            arbeitNehmerId: arbeitNehmerId || ARBEITNEHMER_FIXTURE_ID,
            arbeitGeberId: arbeitGeberId || ARBEITGEBER_FIXTURE_ID
        });

        this.von = moment(Date.UTC(FIXTURE_JAHR, 0, 1)).toDate();
        this.bis = moment(Date.UTC(FIXTURE_JAHR, 11, 31)).toDate();
        this.lohnRente = 100000;
        this.gehaltsNebenLeistungen.verpflegung = 123;
        this.gehaltsNebenLeistungen.privatanteilGeschaeftswagen = 456;
        this.gehaltsNebenLeistungen.andere.value = 789;
        this.gehaltsNebenLeistungen.andere.beschreibung = 'Hello Gehaltsnebenleistungen';
        this.unregelmaessigeLeistungen.value = 234;
        this.unregelmaessigeLeistungen.beschreibung = 'Hello Unrelgemässige Leistungen';
        this.kapitalLeistungen.value = 345;
        this.kapitalLeistungen.beschreibung = 'Hello Kapitalleistungen';
        this.beteiligungsrechte = 456;
        this.verwaltungsratEntschaedigungen = 567;
        this.andereLeistungen.value = 678;
        this.andereLeistungen.beschreibung = 'Hello Andere Leistungen';
        this.beitraegeAhvIvEoAlvNbuv = 678;
        this.beruflicheVorsorge.ordentlicheBeitraege = 789;
        this.beruflicheVorsorge.beitraegeFuerDenEinkauf = 890;
        this.quellensteuerAbzug = 1000;
        this.spesenVerguetungen.effektivReiseCheck = false; // entweder effektivReiseCheck oder effektivReise
        this.spesenVerguetungen.effektivReise = 100;
        this.spesenVerguetungen.effektivUebrige.value = 200;
        this.spesenVerguetungen.effektivUebrige.beschreibung = 'Hello Spesenvergütungen Übrige';
        this.spesenVerguetungen.pauschalRepraesentation = 300;
        this.spesenVerguetungen.pauschalAuto = 400;
        this.spesenVerguetungen.pauschalUebrige.value = 500;
        this.spesenVerguetungen.pauschalUebrige.beschreibung = 'Hello Spesenvergütungen pauschal';
        this.spesenVerguetungen.weiterbildung = 600;
        this.weitereGehaltsNebenLeistungen = 'Hello Weitere Gehaltsnebenleistungen';
        this.bemerkungen = 'Hello Bemerkungen, Sonderzeichen: äöüÄÖÜ©°§çß';

        this.standardBemerkungen.ContinuedProvisionOfSalaryEnabled = standardBemerkungen;
        this.standardBemerkungen.ContinuedProvisionOfSalary.CHF = '1000';
        this.standardBemerkungen.ContinuedProvisionOfSalary.Firstname = 'Fritz'
        this.standardBemerkungen.ContinuedProvisionOfSalary.Lastname = 'Tester'
        this.standardBemerkungen.ContinuedProvisionOfSalary.Address.ort = 'Test city';
        this.standardBemerkungen.ContinuedProvisionOfSalary.Address.plz = '3000';
        this.standardBemerkungen.ContinuedProvisionOfSalary.Address.strasse = 'Test street';

        this.wizard.step5.effektiveSpesen = true;
        this.wizard.step5.zusaetzlicheEffektiveSpesen = true;
        this.wizard.step5.pauschalSpesen = true;
    }
}
