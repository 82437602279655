/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {LocationStrategy} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, NavigationExtras, Router} from '@angular/router';

import {LogFactory} from './core/logging/log-factory';
import {DataInitializerService} from './data-store/data-initializer.service';

const LOG = LogFactory.createLog('AppComponent');

@Component({
    selector: 'elohn-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    public persisted = 'NOT_INIT';

    constructor(
        readonly locationStrategy: LocationStrategy,
        private readonly initializer: DataInitializerService,
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
        LOG.info('eLohn online started');

        // required for ng2-pdf-viewer
        (<any>window).pdfWorkerSrc = 'pdf.worker.min.js';

        // Wird vom bwipjs font loader gebraucht (siehe: browser-fonts.js)
        // document.baseURI ist eine URL bis einschl. Root-Context (z.B..: http://localhost:8080/de/)
        // Wozu brauchts das? Ohne gibts einen wuesten 404 in der console
        // Siehe: assets/bwipjs-fonts/fnt0-desc.js
        const baseHref = locationStrategy.getBaseHref();
        this.bwipJsHack(baseHref);

        this.checkRouteToGoToLastPage();

    }

    private bwipJsHack(baseHref: string): void {
        window.process = window.process ?? {};
        window.process.env = (window.process.env ?? {});
        window.process.env.PUBLIC_URL = `${baseHref}assets`;
        LOG.debug('process env', window.process);
    }

    clearLocationRedirectData(): void {
        localStorage.removeItem('locationPathname');
        localStorage.removeItem('lastRoute');
        localStorage.removeItem('queryParamsRoute');
    }

    checkRouteToGoToLastPage(): void {
        if (location.pathname.includes('printing-development')) {
            LOG.warn('Printing-Development mode: do not restore/remember navigation');
            return;
        }

        if (localStorage.getItem('lastRoute')) {

            const locationPathname = localStorage.getItem('locationPathname') || location.pathname;

            if (locationPathname !== location.pathname) {
                location.pathname = locationPathname;
            }

            const extras: NavigationExtras = {};
            extras.queryParams = JSON.parse(localStorage.getItem('queryParamsRoute') || '{}');
            this.router.navigate([localStorage.getItem('lastRoute')], extras)
                .then(() => this.initLastRouteSubscription())
                .catch((err: unknown) => LOG.error(err));
        } else {
            this.initLastRouteSubscription();
        }
    }

    redirectExclusions(inputUrl: string): boolean {
        const exclusions: ((url: string) => boolean) [] = [];

        exclusions.push((url: string) => {
            const text = '/preview';

            return url.substr(-(text.length)) === text;
        });

        return exclusions.some(fn => fn(inputUrl));
    }

    initLastRouteSubscription(): void {
        this.router.events.subscribe(
            (event: Event) => {
                if (event instanceof NavigationEnd) {
                    try {

                        const urlWithoutParams = this.router.parseUrl(event.url)
                            .root.children.primary.segments.map(it => it.path).join('/');

                        const excluded = this.redirectExclusions(urlWithoutParams);

                        if (!excluded) {
                            localStorage.setItem('locationPathname', location.pathname);
                            localStorage.setItem('lastRoute', urlWithoutParams);
                            localStorage.setItem('queryParamsRoute', JSON.stringify(this.route.snapshot.queryParams));
                        }

                    } catch (error) {
                        // console.log(error);
                        //We are in the root
                        this.clearLocationRedirectData();
                    }
                }
            },
            (err: unknown) => LOG.error(err),
        );
    }

}
