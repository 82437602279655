/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {valueGiven} from '../../shared/functions/valueGiven';

const MIN_VALUE = 1000000000000;
const MAX_VALUE = 9999999999999;
const AHV_NUMERIC_LENGTH = 13;

export function format(text: string): string {
    if (!isValidAHVNummer(text)) {
        return text.trim();
    }

    const numeric = parseString(text);

    const start = isNaN(numeric) ? '' : numeric.toString();
    const padded = rpad(start, '0'.repeat(AHV_NUMERIC_LENGTH));
    const chars: string[] = padded.split('');

    /* eslint-disable no-magic-numbers */
    return `${chars[0]}${chars[1]}${chars[2]}.\
${chars[3]}${chars[4]}${chars[5]}${chars[6]}.\
${chars[7]}${chars[8]}${chars[9]}${chars[10]}.\
${chars[11]}${chars[12]}`;
    /* eslint-enable no-magic-numbers */
}

function rpad(text: string, pad: string): string {
    return (text + pad).substring(0, pad.length);
}

export function isValidAHVNummer(value?: string): boolean {
    if (!valueGiven(value)) {
        return false;
    }
    const numeric = parseString(value!);
    const valid = validate(numeric);

    return valid;
}

/**
 * remove everything but numbers, then parse.
 */
function parseString(value: string): number {
    const clean = value.trim().replace(new RegExp(/[^0-9]*/, 'g'), '');

    return parseInt(clean, 10);
}

function validate(numeric: number): boolean {
    if (isNaN(numeric) || numeric < MIN_VALUE || numeric > MAX_VALUE) {
        return false;
    }

    const pruefziffer = berechnePruefziffer(numeric);
    const valid = (numeric % 10) === pruefziffer;

    return valid;
}

function berechnePruefziffer(nummerToCalculate: number): number {
    // Entferne die Pruefziffer (ignoriere sie für die Pruefziffer Berechnung)
    let n = Math.floor(nummerToCalculate / 10);
    let pruefziffer = 0;
    let z = 1;
    while (n > 0) {
        const ziffer = n % 10;
        //noinspection NumericCastThatLosesPrecision
        pruefziffer = incrementPruefziffer(pruefziffer, Math.floor(ziffer), z);
        z++;
        n = Math.floor(n / 10);
    }
    let returnValue = 0;
    if (pruefziffer % 10 !== 0) {
        returnValue = 10 - pruefziffer % 10;
    }

    return returnValue;
}

function incrementPruefziffer(pruefziffer: number, ziffer: number, z: number): number {
    let increment = ziffer;
    if (z % 2 !== 0) {
        increment *= 3;
    }

    return pruefziffer + increment;
}
