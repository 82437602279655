/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ValidationError} from 'class-validator';
import {IsValid} from './IsValid';
import {Valid} from './Valid';

export class Invalid<T> implements IsValid<T> {
    constructor(
        // Name der Entity-Property muss sich von Valid<T> unterscheiden weil sonst
        // der Duck-Type gleich ist!!!
        public readonly errorEntity: T,
        public readonly errors: ValidationError[]
    ) {
    }

    public static of<T>(entity: T, errors: ValidationError[]): Invalid<T> {
        return new Invalid(entity, errors);
    }

    public isValid(): boolean {
        return false;
    }

    public toValid(): Valid<T> {
        throw new Error(`Cannot cast Invalid to Valid ${this}`);
    }
}
