/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {TextDecoder} from 'text-encoding';

/**
 * !!!! WARNING !!!!
 * THIS METHOD IS *probably* BROKEN REGARDING UNPRINTABLE CHARACTERS
 */
export function arrayBufferToString(
    arrayBuffer: ArrayBuffer,
    options: {
        /**
         * default: utf-8
         */
        charset?: string,
    } = {},
): string {
    return new TextDecoder(options.charset || 'utf-8').decode(arrayBuffer);
}
