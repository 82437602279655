/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Transform, Type} from 'class-transformer';
import {IsDefined, IsNotEmpty, IsUUID, ValidateNested} from 'class-validator';
import {DEFAULT_KORRESPONDENZ_SPRACHE, UUID_VERSION} from '../../core/constants';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';
import {Persistable} from '../../data-store/Persistable';
import {DisplayName} from '../../core/DisplayName';
import {KorrespondenzSprache} from '../../core/sprache.enum';
import {IsValidAHVNummer} from '../../core/validation/IsValidAHVNummer';
import {Adresse} from '../../shared/adresse.model';
import {createUUID} from '../../shared/functions/uuid';
import {transformDate, transformToEnum} from '../../shared/transform-helper/transform.helper';

export class ArbeitNehmer implements Persistable, DisplayName {

    @IsUUID(UUID_VERSION, {always: true})
    id: string;

    @IsUUID(UUID_VERSION, {always: true})
    arbeitGeberId: string;

    @IsDefined({always: true})
    @IsValidAHVNummer({always: true})
    ahvNummerNeu = '';

    @IsNotEmpty({always: true})
    @IsValidXMLValue({always: true})
    name = '';

    @IsNotEmpty({always: true})
    @IsValidXMLValue({always: true})
    vorname = '';

    @Transform((value) => transformToEnum(value, KorrespondenzSprache))
    @IsNotEmpty({always: true})
    korrespondezSprache: KorrespondenzSprache = DEFAULT_KORRESPONDENZ_SPRACHE;

    @Type(() => Adresse)
    @IsDefined({always: true})
    @ValidateNested({always: true})
    adresse: Adresse = new Adresse();

    @Transform(transformDate)
    geburtsdatum: Date | null = null;

    constructor(args: {
        id?: string,
        arbeitGeberId?: string,
    } = {}) {
        this.id = args.id || createUUID();
        this.arbeitGeberId = args.arbeitGeberId || '';
    }

    /**
     * Please note: also used for sorting!
     */
    //TODO: ueberall umbiegen auf getDisplayName()
    public get displayName(): string {
        return [this.name, this.vorname].filter(value => value.length).join(' ');
    }

    public get printName(): string {
        return [this.vorname, this.name].filter(value => value.length).join(' ');
    }

    /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
    public getDisplayName(ignoredLocale?: string): string {
        return this.displayName;
    }
}
