<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->

<elohn-toolbar role="toolbar" class="default-toolbar">
    <div class="elohn-toolbar-left">
        <a class="logo" routerLink="/" matTooltip="zurück zur ersten Seite" i18n-matTooltip="@@global.home"></a>
        <span class="version" aria-label="version">V{{ appVersion }}</span>
    </div>

    <div class="elohn-toolbar-center">
        <elohn-xml-export></elohn-xml-export>
    </div>

    <div class="elohn-toolbar-right">
        <span class="language-switcher">
            <a href="../de" (click)="setLocationPathname('/de/')" i18n="@@global.de" [ngClass]="{'active': locale === 'de-CH'}" matTooltip="Sprache wechseln" i18n-matTooltip="@@global.language.switch">DE</a> /
            <a href="../fr" (click)="setLocationPathname('/fr/')" i18n="@@global.fr" [ngClass]="{'active': locale === 'fr-CH'}" matTooltip="Sprache wechseln" i18n-matTooltip="@@global.language.switch">FR</a> /
            <a href="../it" (click)="setLocationPathname('/it/')" i18n="@@global.it" [ngClass]="{'active': locale === 'it-CH'}" matTooltip="Sprache wechseln" i18n-matTooltip="@@global.language.switch">IT</a></span>
    </div>
</elohn-toolbar>
