/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {LohnausweisPDF} from '../../../../lohnausweis/shared/LohnausweisPDF.model';
import {AdditionalHeaderText, AdditionalPageData} from './AdditionalPageData';
import {AddressPosition} from './AddressPosition';
import {Font} from './Font';
import {OverflowingPlaceholderType} from './OverflowingPlaceholders';
import {fromSprache, TemplateLanguage} from './TemplateLanguage';

export const PDF_DOCUMENT_OPTIONS: PDFKit.PDFDocumentOptions = {
    size: 'A4',
    autoFirstPage: false,
    margins: {top: 260, left: 45, right: 45, bottom: 45}
};

export class LohnausweisDocument {

    public constructor(
        public mainPage: SvgPdfOptions,
        public additionalPages: AdditionalSvgPdfOptions) {
    }
}

export const PDF_FONTS: Font[] = [
    // we have to use the name specified in the SVG, but we can provide any font we want
    new Font('Arial', 'assets/pdf-generation/fonts/LiberationSans-Regular.ttf', true),
    new Font('Frutiger', 'assets/pdf-generation/fonts/hinted-AcariSans-Light.ttf'),
    new Font('Frutiger Bold', 'assets/pdf-generation/fonts/hinted-AcariSans-Bold.ttf'),
    new Font('FrutigerBlack', 'assets/pdf-generation/fonts/hinted-AcariSans-Bold.ttf'),
    new Font('FrutigerUltraBlack', 'assets/pdf-generation/fonts/hinted-AcariSans-Bold.ttf'),
    new Font('Wingdings3', 'assets/pdf-generation/fonts/FreeSerif.ttf'),
];

export class SvgPdfOptions {
    public constructor(
        public svgUrl: string) {
    }
}

export class AdditionalSvgPdfOptions {

    public constructor(
        public svgUrl: string,
        public overflowTitles: ReadonlyMap<OverflowingPlaceholderType, ReadonlyArray<string>>,
        public heading: { font: string, size: number, text: string },
        public fontSize: number = 10,
        public titleFont: string = 'Frutiger',
        public inputFont: string = 'Arial',
        public inputBackgroudColor: string = '#FCDBE1'
    ) {
    }
}

function lowerCase(value: any): string {
    return String(value).toLocaleLowerCase();
}

export function lohnausweisDocumentFromPDFModel(lohnausweisPDF: LohnausweisPDF): LohnausweisDocument {
    const language = fromSprache(lohnausweisPDF.arbeitNehmer.korrespondezSprache);
    const addressPos = lohnausweisPDF.arbeitGeber.addressPosition;

    return createLohnausweisDocument(language, addressPos);
}

export function createLohnausweisDocument(language: TemplateLanguage,
                                          addressPos: AddressPosition): LohnausweisDocument {

    const lowerLang = lowerCase(language);
    const basePath = `assets/pdf-generation/template_address_${lowerCase(addressPos)}_${lowerLang}`;

    return new LohnausweisDocument(
        new SvgPdfOptions(basePath + '.svg'),
        new AdditionalSvgPdfOptions(
            basePath + '_header.svg',
            language === TemplateLanguage.DE_FR_EN
                ? AdditionalPageData.EN
                : AdditionalPageData.IT,
            {
                font: 'Frutiger Bold',
                size: 11,
                text: language === TemplateLanguage.DE_FR_EN
                    ? AdditionalHeaderText.EN
                    : AdditionalHeaderText.IT
            }
        ));
}
