/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {KorrespondenzSprache} from '../../core/sprache.enum';
import {LohnausweisPDF} from '../../lohnausweis/shared/LohnausweisPDF.model';
import {splitUint8Array} from '../../shared/functions';
import {BarcodeHeaderService, HeaderOpts} from './barcode-header.service';
import {Barcode, BarcodeRendererService} from './barcode-renderer.service';
import {BarcodeXmlService} from './barcode-xml.service';
import {ZipperService} from './zipper.service';

@Injectable({providedIn: 'root'})
export class BarcodeService {

    constructor(
        @Inject(LOCALE_ID) private readonly locale: KorrespondenzSprache,
        private readonly barcodeXml: BarcodeXmlService,
        private readonly barcodeHeader: BarcodeHeaderService,
        private readonly zipper: ZipperService,
    ) {
    }

    async buildBarcodeImages(lohnausweis: LohnausweisPDF, personIdent: number): Promise<Barcode[]> {
        return this.buildBarcodeContent(lohnausweis, personIdent)
            .then(parts => this.renderBarcodeImages(parts));
    }

    private prependHeader(contenBytes: Uint8Array, opts: HeaderOpts): Uint8Array {
        const headerBytes = this.barcodeHeader
            .build(opts);

        const result = new Uint8Array(headerBytes.byteLength + contenBytes.byteLength);
        result.set(headerBytes);
        result.set(contenBytes, headerBytes.byteLength);

        return result;
    }

    private async buildBarcodeContent(lohnausweis: LohnausweisPDF, personIdent: number): Promise<Uint8Array[]> {
        return this.barcodeXml.buildBarcodeXml(lohnausweis)
            .then(xml => this.zipper.zip(xml))
            .then(zippedXml => this.createBarcodes(zippedXml, personIdent))
    }

    private createBarcodes(zipped: Uint8Array, personIdent: number): Uint8Array[] {
        const splittedZip = splitUint8Array(zipped, BarcodeHeaderService.BYTES_PER_BARCODE);

        const result = splittedZip.map((split, idx) => {
            const opts: HeaderOpts = {
                personIdent,
                contentByteCount: split.byteLength,
                barcodeIndex: idx,
                totalBarcodes: splittedZip.length,
            };

            return this.prependHeader(split, opts);
        });

        return result;
    }

    private async renderBarcodeImages(content: Uint8Array[]): Promise<Barcode[]> {
        return Promise.all(
            content.map(part => new BarcodeRendererService().renderOne(part)),
        );
    }
}
