export function uint8ArrayToBinaryString(bytes: Uint8Array): string {
    let result = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        const char = bytes[i];
        result += String.fromCharCode(char);
    }

    return result;

}
