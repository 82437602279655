/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PrintingModule} from '../printing/printing.module';
import {SharedModule} from '../shared/shared.module';
import {XmlImportExportModule} from '../xml-import-export/xml-import-export.module';
import {DefaultToolbarComponent} from './default-toolbar/default-toolbar.component';
import {MarkerToolbarComponent} from './marker-toolbar/marker-toolbar.component';
import {PrintProgressToolbarComponent} from './print-progress-toolbar/print-progress-toolbar.component';
import {ToolbarRootComponent} from './toolbar-root/toolbar-root.component';
import {ToolbarComponent} from './toolbar/toolbar.component';

@NgModule({
    imports: [
        SharedModule,
        PrintingModule,
        XmlImportExportModule,
        RouterModule.forChild([])
    ],
    declarations: [
        ToolbarComponent,
        ToolbarRootComponent,
        MarkerToolbarComponent,
        DefaultToolbarComponent,
        PrintProgressToolbarComponent
    ],
    exports: [ToolbarRootComponent]
})
export class ToolbarModule {
}
