/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Transform, Type} from 'class-transformer';
import {IsUUID} from 'class-validator';
import {ArbeitNehmer} from '../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {Lohnausweis} from '../../lohnausweis/shared/lohnausweis.model';
import {createUUID} from '../../shared/functions/uuid';
import {transformToMap} from '../../shared/transform-helper/transform.helper';
import {UUID_VERSION} from '../constants';
import {Persistable} from '../../data-store/Persistable';

export class Validity implements Persistable {

    @IsUUID(UUID_VERSION, {always: true})
    public id: string;

    @Transform(transformToMap)
    public readonly invalidLohnausweisViaArbeitnehmer: Map<string, Set<string>>;

    @Type(() => Set)
    public readonly invalidArbeitnehmer: Set<string>;

    /**
     * @param invalidLohnausweisViaArbeitnehmer Map of arbeitNehmerId  => Set of lohnausweisId
     * @param invalidArbeitnehmer Set of arbeitNehmerId
     * @param id id
     */
    constructor(
        invalidLohnausweisViaArbeitnehmer: Map<string, Set<string>> = new Map<string, Set<string>>(),
        invalidArbeitnehmer: Set<string> = new Set<string>(),
        id: string = createUUID(),
    ) {
        this.id = id;
        this.invalidLohnausweisViaArbeitnehmer = invalidLohnausweisViaArbeitnehmer;
        this.invalidArbeitnehmer = invalidArbeitnehmer;

    }

    public isLohnausweisInvalid(lohnausweis: Lohnausweis): boolean {
        const lohnausweisSet = this.invalidLohnausweisViaArbeitnehmer.get(lohnausweis.arbeitNehmerId);

        return lohnausweisSet ? lohnausweisSet.has(lohnausweis.id) : false;
    }

    public isArbeitNehmerInvalid(arbeitNehmer: ArbeitNehmer): boolean {
        return new Set(this.invalidArbeitnehmer).has(arbeitNehmer.id);
    }

    public hasInvalidLohnausweis(arbeitNehmer: ArbeitNehmer): boolean {
        return this.invalidLohnausweisViaArbeitnehmer.has(arbeitNehmer.id);
    }
}
