/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsDefined, IsInt, IsOptional, Min, ValidateNested} from 'class-validator';
import {sum} from '../../shared/functions/sum';
import {OPTS_BOTH, OPTS_LOHNAUSWEIS} from '../../core/validation/validation.const';
import {ValueWithDescription} from './value-with-description.model';
import {Type} from 'class-transformer';

/**
 * Field: 2
 */
export class GehaltsNebenLeistungen {

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public verpflegung: number | null = null;

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public privatanteilGeschaeftswagen: number | null = null;

    @Type(() => ValueWithDescription)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    public andere: ValueWithDescription = new ValueWithDescription();

    public get total(): number {
        return sum(this.verpflegung, this.privatanteilGeschaeftswagen, this.andere.value);
    }
}
