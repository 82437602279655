/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Observable, Subject} from 'rxjs';

export class PdfGenerationProgress {

    private readonly abortSubject$ = new Subject<undefined>();
    public readonly abort$ = this.abortSubject$.asObservable();

    constructor(public readonly completed$: Observable<number>, public readonly queueSize: number) {
    }

    public abort(): void {
        this.abortSubject$.next(undefined);
        this.abortSubject$.complete();
    }
}
