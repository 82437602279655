/*
 * Copyright 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DatePipe} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {LogFactory} from './logging/log-factory';

const LOG = LogFactory.createLog('CoreModule');

@NgModule({
    imports: [
        // Leave empty
    ],
    providers: [
        // Insert global singleton services here that have no configuration
        DatePipe,
    ],
    declarations: [],
})

export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        LOG.debug('eLohn-Online initialized');

        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }

    /**
     * @see https://angular.io/guide/singleton-services#forroot
     */
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                // Insert configurable providers here (will be appended to providers defined in metadata above)
            ],
        };
    }
}
