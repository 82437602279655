/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

//TODO: Umbauen auf Translation/TranslationKey?

import {KorrespondenzSprache} from '../../../core/sprache.enum';

interface KeySprache {
    [translationKey: string]: Uebersetzung;
}

type Uebersetzung = {
    [locale in KorrespondenzSprache]: string;
};

export const STANDARD_BEMERKUNGEN: KeySprache = {
    childAllowancePerAHVAVS: {
        'de-CH': 'Kinderzulagen im Lohnausweis nicht enthalten. Auszahlung durch die Ausgleichskasse.',
        'fr-CH': 'Allocations pour enfants non déclarées dans le certificat de salaire. Versement direct par la caisse de compensation.',
        'it-CH': 'Indennità per i figli non incluse nel certificato di salario. Pagamento diretto da parte della cassa di compensazione.',
        'en': 'Kinderzulagen im Lohnausweis nicht enthalten. Auszahlung durch die Ausgleichskasse.',
    },
    relocationCosts: {
        'de-CH': 'Umzugskosten von CHF {0} bezahlt.',
        'fr-CH': 'Remboursement des frais de déménagement s’élevant à {0} francs.',
        'it-CH': 'Spese di trasloco di CHF {0} pagate.',
        'en': 'Umzugskosten von CHF {0} bezahlt.',
    },
    staffShareMarketValue: {
        'de-CH': 'Mitarbeiterbeteiligung: Verkehrswert durch Kanton {1} am {0} genehmigt',
        'fr-CH': 'Participation de collaborateur: Valeur vénale agrée par le canton {1} le {0}.',
        'it-CH': 'Diritto di partecipazione: Il valore venale è stato approvato dal cantone {1}, il {0}.',
        'en': 'Mitarbeiterbeteiligung: Verkehrswert durch Kanton {1} am {0} genehmigt',
    },
    staffShareWithoutTaxableIncome: {
        'de-CH': 'Mitarbeiterbeteiligung ohne steuerbares Einkommen. Grund: {0}',
        'fr-CH': 'Participation de collaborateur sans revenu imposable. Motif: {0}',
        'it-CH': 'Diritti di partecipazione dei collaboratori senza reddito imponibile. Motivo: {0}',
        'en': 'Mitarbeiterbeteiligung ohne steuerbares Einkommen. Grund: {0}',
    },
    staffShareWithoutTaxableIncome_anwartschaftlicheRechte: {
        'de-CH': 'Anwartschaftliche Rechte',
        'fr-CH': 'Droits en cours d\'acquisition',
        'it-CH': 'Diritti di aspettativa',
        'en': 'Anwartschaftliche Rechte',
    },
    staffShareWithoutTaxableIncome_gesperrteMitarbeiterOptionen: {
        'de-CH': 'Veräusserungssperre bis maximal 10 Jahre',
        'fr-CH': 'Interdiction d’aliénation jusqu\'à un maximum de 10 ans',
        'it-CH': 'Interdizione di alienazione fino ad un massimo di 10 anni',
        'en': 'Veräusserungssperre bis maximal 10 Jahre',
    },
    staffShareWithoutTaxableIncome_nichtBewertbareOptionen: {
        'de-CH': 'Bei der Ausgabe nicht bewertbare Optionen',
        'fr-CH': 'Options non évaluables objectivement à l’émission',
        'it-CH': 'Opzioni non valutabili all\'emissione',
        'en': 'Bei der Ausgabe nicht bewertbare Optionen',
    },
    staffShareWithoutTaxableIncome_veraeusserungsSperre: {
        'de-CH': 'Nicht börsenkotierte oder gesperrte Mitarbeiteroptionen',
        'fr-CH': 'Options de collaborateurs non cotées en Bourse ou bloquées',
        'it-CH': 'Opzioni di dipendenti non quotate in borsa o bloccate',
        'en': 'Nicht börsenkotierte oder gesperrte Mitarbeiteroptionen',
    },
    spesenReglementGenehmigt: {
        'de-CH': 'Spesenreglement durch Kanton {1} am {0} genehmigt.',
        'fr-CH': 'Règlement des remboursements de frais agréé par le canton {1} daté du {0}.',
        'it-CH': 'Regolamento delle spese approvato dal Cantone {1} il {0}.',
        'en': 'Spesenreglement durch Kanton {1} am {0} genehmigt.',
    },
    numberOfSalaryCertificateStr: {
        'de-CH': 'Einer von {0} Lohnausweisen',
        'fr-CH': 'Un certificat de salaire sur {0} certificats',
        'it-CH': 'Certificato di salario 1 di {0}',
        'en': 'One of {0} certificates of salary',
    },
    erwerbsausfall: {
        'de-CH': '{0} Tage mit Erwerbsausfall',
        'fr-CH': '{0} jours avec allocations pour perte de gain',
        'it-CH': '{0} giorni con perdita di guadagno',
        'en': '{0} Tage mit Erwerbsausfall',
    },
    kurzArbeit: {
        'de-CH': 'Kurzarbeitsentschädigung in Ziffer 1 enthalten',
        'fr-CH': 'Indemnité en cas de réduction de l’horaire de travail (RHT) est incluse au chiffre 1',
        'it-CH': 'Indennità per lavoro ridotto è inclusa nella cifra 1',
        'en': 'Short-time work compensation included in item 1'
    },
    CompanyCarClarify: {
        'de-CH': 'Privatanteil Geschäftsfahrzeug im Veranlagungsverfahren abzuklären',
        'fr-CH': 'Examiner part privée voiture de service dans la procédure de taxation',
        'it-CH': 'Quota privata dell\'automobile di servizio da chiarire nella procedura di tassazione',
        'en': 'Private share of business vehicle to be clarified in the assessment procedure'
    },
    MinimalEmployeeCarPartPercentage: {
        'de-CH': 'Privatanteil Geschäftsfahrzeug wird vom Arbeitnehmer bezahlt',
        'fr-CH': 'Part privée payée par le travailleur',
        'it-CH': 'La quota privata è pagata dal dipendente',
        'en': 'Private share of business vehicle paid by employee'
    },
    taxAtSourcePeriodForObjection: {
        'de-CH': 'Quellensteuerpflichtige Personen können bis am 31. März des auf die Fälligkeit der Leistung folgenden Steuerjahres bei der zuständigen kantonalen Steuerbehörde eine Verfügung über Bestand und Umfang der Quellensteuerpflicht oder eine nachträgliche ordentliche Veranlagung bzw. eine Neuberechnung der Quellensteuer verlangen. Ohne form- und fristgerechten Antrag wird der Quellensteuerabzug definitiv.',
        'fr-CH': 'Les personnes imposées à la source peuvent exiger jusqu’au 31 mars de l’année fiscale suivante auprès de l’administration cantonale des impôts compétente une décision sur l’existence et l’étendue de l’assujettissement à l’impôt à la source ou une taxation ordinaire ultérieure, respectivement un nouveau calcul de l’impôt à la source. Sans demande respectant la forme et le délai, le prélèvement de l’impôt à la source est définitif.',
        'it-CH': 'Le persone tassate alla fonte possono pretendere entro il 31 marzo dell’anno fiscale successivo presso l\'autorità fiscale cantonale competente una decisione circa la sussistenza e l\'entità dell\'imposta alla fonte oppure una tassazione supplementare ordinaria ovvero un nuovo calcolo dell\'imposta alla fonte. Senza una domanda, presentata correttamente e nei termini, la deduzione dell\'imposta alla fonte diviene definitiva.',
        'en': 'Persons liable to withholding tax may request by 31 March request from the competent cantonal tax authority a ruling on the existence and scope of the withholding tax liability or a subsequent ordinary assessment or recalculation of the withholding tax. Without a formal and timely application, the withholding tax deduction becomes definitive.'
    },
    activityRate: {
        'de-CH': 'Teilzeitbeschäftigung',
        'fr-CH': 'Occupation à temps partiel',
        'it-CH': 'Occupazione parziale',
        'en': 'Part-time employment'
    },
    ersetztLohnausweisStr: {
        'de-CH': 'Dieser Lohnausweis ersetzt denjenigen Lohnausweis vom {0}',
        'fr-CH': 'Ce certificat de salaire remplace celui du {0}',
        'it-CH': 'Questo certificato di salario sostituisce quello del {0}',
        'en': 'This certificate of salary replaces the one from {0}'
    },
    expatriateRulingStr: {
        'de-CH': 'Expatriateruling durch {0} am {1} genehmigt',
        'fr-CH': 'Expatriate ruling approuvé par le canton {1} le {0}',
        'it-CH': 'Il ruling per espatriati è stato approvato dal Cantone {1} il {0}',
        'en': 'Expatriateruling by canton {1} on {0}'
    },
    continuedProvisionOfSalaryStr: {
        'de-CH': 'Inkl. Besoldungsnachgenuss von CHF {0} für {1} {2}, {3}',
        'fr-CH': 'Y compris jouissance posthume de la rémunération pour un montant de CHF {0} versée à {1} {2}, {3}',
        'it-CH': 'Incluso salario postumo di CHF {0} per {1} {2}, {3}',
        'en': 'Supplementary salary allowance of CHF {0} to {1} {2}, {3}'
    },
    mitarbeiterbeteiligungStr: {
        'de-CH': 'Mitarbeiterbeteiligung ohne steuerbares Einkommen. Grund: {0}',
        'fr-CH': 'Participation de collaborateur sans revenu imposable. Motif: {0}',
        'it-CH': 'Diritti di partecipazione die collaboratori senza reddito imponibile. Motivo: {0}',
        'en': 'Employee stock ownership without taxable income. Reason: {0}'
    }
};

export type StandardBemerkungenKeys = keyof typeof STANDARD_BEMERKUNGEN;

export function standardBemerkungen(key: StandardBemerkungenKeys, locale: KorrespondenzSprache): string {
    const value = STANDARD_BEMERKUNGEN[key][locale];

    if (!value) {
        throw Error(`STANDARD_BEMERKUNGEN not found: ${key}/${locale}`);
    }

    return value;
}
