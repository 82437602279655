/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

/**
 * This is a null/undefined safe implementation of Math.floor().
 * Maps everything to null that is not a valid number!
 */
export function chopDecimals(value?: number | null | undefined): number | null {
    if (value === null || value === undefined || isNaN(value)) {
        return null;
    }

    return Math.trunc(value);
}
