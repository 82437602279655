/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {classToPlain} from 'class-transformer';
import Dexie from 'dexie';
import {from as fromPromise, Observable} from 'rxjs';
import {Persistable} from './Persistable';

export class DexieCrudService<T extends Persistable> {

    public constructor(private readonly table: Dexie.Table<T, string>) {
    }

    public getAll$(): Observable<T[]> {
        return fromPromise(this.table.toArray());
    }

    public get$(id: string): Observable<T | undefined> {
        return fromPromise(this.table.get(id));
    }

    /**
     * There is an annoying incompatibility of dexie and class-transformer:
     * Dexie's creating hook does allow to modify the new entity, but only by manipulation of the input object.
     * The return value is only used to set a custom ID. However, the input object is a class, which should be
     * transformed by class-transformer to a plain object -> the plain object has a new reference and is thus ignored
     * by Dexie. classToPlainFromExisting or likewise does not help either.
     * Thus we manually call classToPlain before passing the entity to Dexie.
     *
     * Be aware, to do this for all object creating APIs (e.g. bulkAdd)
     */
    public add$(entity: T): Observable<string> {
        return fromPromise(this.table.add(classToPlain(entity) as T));
    }

    public update$(entity: T): Observable<string> {
        return fromPromise(this.table.put(classToPlain(entity) as T, entity.id));
    }

    public delete$(entity: T): Observable<void> {
        return fromPromise(this.table.delete(entity.id));
    }

    public findByAttribute$(attribute: string, value: any): Observable<T[]> {
        return fromPromise(this.table.where(attribute).equals(value).toArray());
    }

    public clear$(): Observable<void> {
        return fromPromise(this.table.clear());
    }
}
