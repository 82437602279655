/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {BehaviorSubject, EMPTY, forkJoin, from, Observable, of, throwError} from 'rxjs';
import {
    catchError,
    combineAll,
    concatMap,
    filter,
    map,
    mergeAll,
    mergeMap,
    reduce,
    take,
    takeUntil,
// eslint-disable-next-line
    tap
} from 'rxjs/operators';
import {firstBy} from 'thenby';
import {ArbeitGeber} from '../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {ArbeitGeberService} from '../core/arbeit-geber.service';
import {ArbeitNehmerService} from '../core/arbeit-nehmer.service';
import {DisplayName} from '../core/DisplayName';
import {LogFactory} from '../core/logging/log-factory';
import {LohnausweisService} from '../core/lohnausweis.service';
import {Invalid} from '../core/validation/Invalid';
import {isInvalid, IsValid} from '../core/validation/IsValid';
import {Valid} from '../core/validation/Valid';
import {ValidationService} from '../core/validation/validation.service';
import {Persistable} from '../data-store/Persistable';
import {LohnausweisTyp} from '../lohnausweis/shared/lohnausweis-typ.enum';
import {Lohnausweis} from '../lohnausweis/shared/lohnausweis.model';
import {LohnausweisPDF} from '../lohnausweis/shared/LohnausweisPDF.model';
import {ignoreNullAndUndefined} from '../shared/functions/isNotNullOrUndefined';
import {ErrorType} from './models/ErrorType';
import {PrintValidationError} from './models/PrintValidationError';
import {PDF_GENERATION_ABORTED, PdfGeneratorService} from './pdf/pdf-generator/pdf-generator.service';
import {DialogParams, PrintErrorsDialogComponent} from './print-errors-dialog/print-errors-dialog.component';
import JSZip from 'jszip';
import {StatisticsService} from "../core/statistics/statistics.service";

function findInvalid<T>(validations: (IsValid<T>)[]): Invalid<T>[] {
    return validations.filter(isInvalid);
}

function allValidOrThrow<T extends DisplayName>(validations: IsValid<T>[], errorType: ErrorType): Valid<T>[] {
    const invalid = findInvalid(validations);
    if (invalid.length) {
        throw new PrintValidationError<T>(errorType, invalid);
    }

    // only Valid left
    return validations.map(v => v.toValid());
}

/**
 * Observable errors with {@link PrintValidationError} on validation errors.
 */
function validationPipe$<T extends DisplayName>(
    validationService: ValidationService,
    input: T[],
    errorType: ErrorType,
    validationGroups?: (t: T) => string[] | undefined,
): Observable<Valid<T>[]> {
    const valid$ = from(input)
        .pipe(
            map(la => validationService.validate$(la, {groups: validationGroups ? validationGroups(la) : undefined})),
            combineAll(),
            map((validations: IsValid<T>[]) => allValidOrThrow(validations, errorType)),
        );

    return valid$;
}

interface IdMap<T> {
    [id: string]: T;
}

function mapById<T extends Persistable>(list: T[]): IdMap<T> {
    const valueMap = list.reduce((prev, curr) => {
        prev[curr.id] = curr;

        return prev;
    }, <any>{});

    return valueMap;
}

function mapToLohnausweisPDF(
    arbeitGeber: ArbeitGeber[], arbeitNehmer: ArbeitNehmer[], lohnausweise: Lohnausweis[],
): LohnausweisPDF[] {
    const agMap = mapById(arbeitGeber);
    const anMap = mapById(arbeitNehmer);

    const result = lohnausweise
        .map(la => new LohnausweisPDF({
            arbeitGeber: agMap[la.arbeitGeberId],
            arbeitNehmer: anMap[la.arbeitNehmerId],
            lohnausweis: la,
        }))
        .filter(pdf => !!pdf.arbeitGeber && !!pdf.arbeitNehmer)
        .sort(firstBy(pdf => pdf.arbeitNehmer.displayName));

    return result;
}

const LOG = LogFactory.createLog('PrintingService');

interface PdfData {
    blob: Blob;
    fileName: string;
}

interface LohnData {
    result: PdfData;
    lohnausweise: Lohnausweis[];
}

class ZipData {
    zip: JSZip = new JSZip();
    lohnausweise: Lohnausweis[] = [];
}

@Injectable({
    providedIn: 'root',
})
export class PrintingService {

    public inProgress$: Observable<boolean>;

    private dialogRef?: MatDialogRef<PrintErrorsDialogComponent, undefined>;

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly arbeitNehmerService: ArbeitNehmerService,
        private readonly arbeitGeberService: ArbeitGeberService,
        private readonly lohnausweisService: LohnausweisService,
        private readonly validationService: ValidationService,
        private readonly pdfGeneratorService: PdfGeneratorService,
        private readonly dialog: MatDialog,
        private readonly statisticsService : StatisticsService
    ) {
        this.inProgress$ = this.pdfGeneratorService.progress$.pipe(map(value => !!value));
    }

    public printAll(): void {
        this.generateAndDownload(this.lohnausweisService.getAll$());
    }

    public printAllZipped(): void {
        this.generateZipAndDownload(this.lohnausweisService.getAll$());
    }

    public printArrayZipped(lohnausweise: Lohnausweis[]): void {
        this.generateZipAndDownload(of(lohnausweise));
    }

    public printCurrent(): void {
        this.generateAndDownload(this.lohnausweisService.current$()
            .pipe(take(1))
            .pipe(mergeMap(lohnausweis => {
                if (!lohnausweis) {
                    return EMPTY;
                }

                const input$: Observable<Lohnausweis[]> = this.lohnausweisService.get$(lohnausweis.id)
                    .pipe(
                        mergeMap(found => {
                                return found
                                    ? of([found])
                                    : throwError(`Lohnausweis not found: ${lohnausweis.id}`);
                            },
                        ),
                    ).pipe(tap(_=> this.statisticsService.logLohnausweisAbgeschlossen(lohnausweis)));

                return input$;
            }))
        );
    }

    private generateAndDownload(lohnausweise$: Observable<Lohnausweis[]>): void {
        lohnausweise$
            .pipe(
                take(1),
                mergeMap(all => this.formatPDF$(all)),
            )
            .subscribe(
                result => saveAs(result.blob, result.fileName),
                (error: unknown) => {
                    if (error !== PDF_GENERATION_ABORTED) {
                        LOG.error(error);
                    }
                },
            );
    }

    private generateZipAndDownload(lohnausweise$: Observable<Lohnausweis[]>): void {
        // this method is not using RxJS correctly, but refactoring formatPDF$ is laborious, so anyway...

        const zipData = new ZipData();
        let aborted = false;
        const abort$ = new BehaviorSubject<boolean>(false);
        const progress = {current: 0, totalLength: 0, abort$};

        abort$.subscribe(
            val => aborted = !!val,
            (err: unknown) => LOG.error(err)
        );

        lohnausweise$
            .pipe(
                take(1),
                mergeMap(lohns => this.validationChain$(lohns)),
                /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
                map(([arbeitGeber, arbeitNehmer, validLohnausweise]) => validLohnausweise),
                catchError((err: unknown) => {
                    return this.showValidationErrorDialog$(err, false);
                }),

                tap(lohns => progress.totalLength = lohns.length),
                mergeAll(),

                // concatMap: wait for prev to resolve to no stress
                concatMap(lohn => {
                    return this.formatPDF$([lohn], progress)
                        .pipe(
                            map(result => {
                                const lohnausweiseArray = [lohn];
                                const data: LohnData = {
                                    result,
                                    lohnausweise: lohnausweiseArray
                                };

                                return data;
                            }),
                            takeUntil(abort$.pipe(
                                filter(item => !!item)
                            ))
                        );
                }),

                reduce((acc: ZipData, elohn: LohnData, idx: number) => {
                    if (aborted) {
                        return acc;
                    }
                    const file = elohn.result;

                    if (filenameExists(file.fileName, acc.zip.files)) {
                        file.fileName = uniquePDFFilename(file.fileName, acc.zip.files);
                    }
                    progress.current = idx + 1;

                    acc.zip.file(file.fileName, file.blob);
                    if (elohn.lohnausweise.length > 0) {
                        acc.lohnausweise.push(elohn.lohnausweise[0]);
                    }

                    return acc;

                }, zipData)
            )
            .subscribe(
                (result) => {
                    if (aborted) {
                        return;
                    }
                    const obj = result as ZipData;

                    obj.zip.generateAsync({type: 'blob'})
                        .then((blob: Blob) => {
                            this.generateFileName$(
                                obj.lohnausweise,
                                'zip'
                                // eslint-disable-next-line rxjs/no-nested-subscribe
                            ).subscribe(
                                name => {
                                    this.saveAs(blob, name);
                                },
                                (err: unknown) => LOG.error(err)
                            ).unsubscribe();
                        })
                        .catch((err: unknown) => LOG.error(err));

                },
                (error: unknown) => {
                    if (error !== PDF_GENERATION_ABORTED && !(error instanceof PrintValidationError)) {
                        LOG.error(error);
                    }
                },
            );
    }

    // wrapper to help test
    saveAs(blob: Blob, fileName: string): void {
        saveAs(blob, fileName);
    }

    showValidationErrorDialog$(err: any, completeStream = true): Observable<never> {
        if (err instanceof PrintValidationError) {
            this.dialogRef = this.dialog.open<PrintErrorsDialogComponent, DialogParams, undefined>(
                PrintErrorsDialogComponent,
                {data: new DialogParams(err)},
            );
            if (completeStream) {
                // complete the stream
                return EMPTY;
            }
        }

        // other error -> re-throw
        return throwError(err);
    }

    validationChain$(lohnausweise: Lohnausweis[]): Observable<[ArbeitGeber[], ArbeitNehmer[], Lohnausweis[]]> {
        const validArbeitGeber$ = this.arbeitGeberService.get$()
            .pipe(
                ignoreNullAndUndefined(),
                take(1),
                mergeMap(ag => validationPipe$(
                    this.validationService, [ag], ErrorType.ARBEIT_GEBER)),
                map(list => list.map(valid => valid.entity)),
                // tap(ag => console.log('validArbeitGeber$', ag)),
            );

        const validArbeitNehmer$ = this.arbeitNehmerService.getAll$()
            .pipe(
                take(1),
                map(ane => {
                    const requiredArbeitNehmerIds = lohnausweise.map(la => la.arbeitNehmerId);

                    return ane.filter(an => !!an && requiredArbeitNehmerIds.includes(an.id));
                }),
                mergeMap(an => validationPipe$(
                    this.validationService, an.toArray(), ErrorType.ARBEIT_NEHMER)),
                map(list => list.map(valid => valid.entity)),
                // tap(ag => console.log('validArbeitNehmer$', ag)),
            );

        const validLohnausweise$ = of(lohnausweise)
            .pipe(
                mergeMap(la => validationPipe$(
                    this.validationService, la, ErrorType.LOHNAUSWEIS, t => t.validationGroup())),
                map(list => list.map(valid => valid.entity)),
                // tap(ag => console.log('validLohnausweise$', ag)),
            );

        const result$ = forkJoin([validArbeitGeber$, validArbeitNehmer$, validLohnausweise$]);

        return result$;
    }

    public formatPDF$(
        lohnausweise: Lohnausweis[],
        forceProgress?: { current: number, totalLength: number, abort$: BehaviorSubject<boolean> }
    ): Observable<{ blob: Blob, fileName: string }> {
        if (this.dialogRef) {
            this.dialogRef.close();
        }

        const result$ = this.validationChain$(lohnausweise)
            .pipe(
                // tap(entry => console.log('entry', entry)),
                mergeMap(([arbeitGeber, arbeitNehmer, validLohnausweise]) => {
                    const pdfs = mapToLohnausweisPDF(arbeitGeber, arbeitNehmer, validLohnausweise);

                    return this.pdfGeneratorService.generatePDFAll$(pdfs, undefined, undefined, forceProgress);
                }),
                catchError((err: unknown) => {
                    return this.showValidationErrorDialog$(err);
                }),
                mergeMap(result => this.generateFileName$(lohnausweise)
                    .pipe(map(fileName => ({blob: result, fileName})))),
            )
        ;

        return result$;
    }

    private generateFileName$(ausweise: Lohnausweis[], sufix = 'pdf'): Observable<string> {
        if (ausweise.length === 1) {
            const la = ausweise[0];
            const prefix = this.getFileNamePrefix(la.ausweisTyp);
            const von = moment(la.von!).format('MM');
            const bis = moment(la.bis!).format('MM');

            return this.arbeitNehmerService.get$(la.arbeitNehmerId)
                .pipe(
                    map(
                        an => `${la.jahr}_${prefix}_${an.ahvNummerNeu}_${an.name}_${an.vorname}_${von}_${bis}.${sufix}`),
                );
        }

        return this.arbeitGeberService.get$()
            .pipe(
                ignoreNullAndUndefined(),
                map(ag => `${ag.bearbeitungsJahr}_${this.locale === 'de-CH' ? 'Lohnausweise' : 'CS'}.${sufix}`),
            );
    }

    private getFileNamePrefix(ausweisTyp: LohnausweisTyp): string {
        switch (ausweisTyp) {
            case LohnausweisTyp.LOHNAUSWEIS:
                return this.locale === 'de-CH' ? 'LA' : 'CS';
            case LohnausweisTyp.RENTENBESCHEINIGUNG:
                return this.locale === 'de-CH' ? 'RB' : 'CS';
            default:
                throw new Error(`No prefix defined for type ${ausweisTyp}`);
        }
    }
}

function uniquePDFFilename(originalName: string, files: typeof JSZip.files): string {
    const basename = originalName.toLowerCase().replace(/\.pdf$/, '');
    const basenameOriginalCase = originalName.substr(0, basename.length);

    const foundFiles = Object.keys(files)
        .map(f => f.toLowerCase()) // case insensitive due to windows ignoring case
        .filter(i => i.startsWith(basename));

    const count = 1 + foundFiles.length;
    const result = `${basenameOriginalCase}_${count}.pdf`;

    return result;
}

function filenameExists(filename: string, files: typeof JSZip.files): boolean {
    // case insensitive since windows ignores case
    return !!Object.keys(files)
        .find(f => f!.toLowerCase() === filename.toLowerCase());
}

export const TESTING = {
    findInvalid: findInvalid,
    allValidOrThrow: allValidOrThrow,
    validationPipe$: validationPipe$,
    mapById: mapById,
    filenameExists: filenameExists,
    uniquePDFFilename: uniquePDFFilename
};
