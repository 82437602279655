<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->

<h1 mat-dialog-title
    class="title"
    i18n="@@print-errors-dialog.title">
    Druck: Validierungsfehler
</h1>
<mat-dialog-content class="content">
    <p i18n="@@print-errors-dialog.erklaerung">
        Für den Druck sind gültige Daten notwendig.
    </p>
    <p i18n="@@print-errors-dialog.erklaerung.useraktion">
        Bitte prüfen Sie alle unten stehenden Daten:
    </p>
    <div class="error-type" [ngSwitch]="params.error.type">
        <div *ngSwitchCase="ErrorType.ARBEIT_GEBER" i18n="@@global.arbeitgeber">Arbeitgeber</div>
        <div *ngSwitchCase="ErrorType.ARBEIT_NEHMER" i18n="@@global.arbeitnehmer">Arbeitnehmer</div>
        <div *ngSwitchCase="ErrorType.LOHNAUSWEIS" i18n="@@global.lohnausweis">Lohnausweis</div>
    </div>
    <div *ngFor="let errorDisplay of errors$ | async">
        <div *ngIf="errorDisplay.route"><a href="#" (click)="$event.preventDefault(); closeAndNavigate(errorDisplay.route)">{{ errorDisplay.text }}</a></div>
        <div *ngIf="!errorDisplay.route">{{ errorDisplay.text }}</div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="actions">
    <button mat-raised-button
            cdkfocusinitial
            color="primary"
            type="submit"
            mat-dialog-close
            i18n="@@global.back">
        Zurück
    </button>
</mat-dialog-actions>
