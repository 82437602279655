<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->
<elohn-toolbar role="toolbar" class="marker-toolbar">
    <div class="elohn-toolbar-left">
        <button mat-button
                (click)="resetSelection()"
                aria-label="Auswahl aufheben"
                i18n-aria-label="@@global.marks.removeAll">
            <mat-icon class="spacer-right">arrow_back</mat-icon>
            <ng-container i18n="@@marker-toolbar.unmark-all">Zurück (alle abwählen)</ng-container>
        </button>
    </div>

    <div class="elohn-toolbar-center">
        <mat-icon>check_box</mat-icon>
        <span class="marks spacer-left" aria-hidden="true">
            {{ marksCount$ | async }} <span i18n="@@marker-toolbar.ausgewaehlt">ausgewählt</span>
        </span>
    </div>

    <div class="elohn-toolbar-right">
        <button mat-button
                (click)="printChoice()"
                aria-label="Auswahl drucken"
                i18n-aria-label="@@global.marks.printSelection">
            <ng-container i18n="@@marker-toolbar.print-selection">Auswahl drucken</ng-container>
            <mat-icon class="spacer-left">print</mat-icon>
        </button>
    </div>
</elohn-toolbar>
