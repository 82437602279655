/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {getFromContainer, ValidationError, Validator, ValidatorOptions} from 'class-validator';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Invalid} from './Invalid';
import {Valid} from './Valid';

@Injectable({providedIn: 'root'})
export class ValidationService {

    private static toResult<T>(object: T, errors: ValidationError[]): Invalid<T> | Valid<T> {
        return (errors && errors.length) ? Invalid.of(object, errors) : Valid.of(object);
    }

    public validate$<T>(object: T, options?: ValidatorOptions): Observable<Invalid<T> | Valid<T>> {
        // debugging only
        // const syncValidation = getFromContainer(Validator).validateSync(object, options);

        // return of(ValidationService.toResult(syncValidation, object));

        const validation = getFromContainer(Validator).validate(object as unknown as object, options);

        return from(validation)
            .pipe(map(errors => ValidationService.toResult(object, errors)));
    }
}
