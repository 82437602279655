/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, Inject, LOCALE_ID} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'elohn-default-toolbar',
    templateUrl: './default-toolbar.component.html',
    styleUrls: ['./default-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger(
            'headerButtonTransition', [
                transition(':enter', [
                    style({transform: 'scale(0) translateY(-100%)', opacity: 0}),
                    animate('300ms', style({transform: 'scale(1) translateY(0)', opacity: 1})),
                ]),
                transition(':leave', [
                    style({transform: 'scale(1) translateY(0)', opacity: 1}),
                    animate('300ms', style({transform: 'scale(0) translateY(-100%)', opacity: 0})),
                ]),
            ],
        ),
    ],
})
export class DefaultToolbarComponent {
    public readonly appVersion = environment.version;

    constructor(@Inject(LOCALE_ID) public locale: string) {
        //
    }

    setLocationPathname(locationPathname: string): void {
        localStorage.setItem('locationPathname', locationPathname);
    }
}
