/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsDefined, IsInt, IsOptional, Min, ValidateNested} from 'class-validator';
import {OPTS_LOHNAUSWEIS} from '../../core/validation/validation.const';
import {ValueWithDescription} from './value-with-description.model';
import {Type} from 'class-transformer';

export class SpesenVerguetungen {
    @IsDefined(OPTS_LOHNAUSWEIS)
    public effektivReiseCheck = false;

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public effektivReise: number | null = null;

    @Type(() => ValueWithDescription)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @ValidateNested(OPTS_LOHNAUSWEIS)
    public effektivUebrige = new ValueWithDescription();

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public pauschalRepraesentation: number | null = null;

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public pauschalAuto: number | null = null;

    @Type(() => ValueWithDescription)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @ValidateNested(OPTS_LOHNAUSWEIS)
    public pauschalUebrige = new ValueWithDescription();

    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    @Min(0, OPTS_LOHNAUSWEIS)
    public weiterbildung: number | null = null;
}
