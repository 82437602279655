/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {plainToClass} from 'class-transformer';
import Dexie from 'dexie';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArbeitGeber} from '../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {Validity} from '../core/validation/validity.model';
import {Lohnausweis} from '../lohnausweis/shared/lohnausweis.model';
import {standardbemerkungenElm4ToElm5} from './migration/v6/standardbemerkungen-elm4-to-elm5';

export class Database extends Dexie {
    public arbeitGeber!: Dexie.Table<ArbeitGeber, string>;
    public arbeitNehmer!: Dexie.Table<ArbeitNehmer, string>;
    public lohnausweis!: Dexie.Table<Lohnausweis, string>;
    public validity!: Dexie.Table<Validity, string>;

    constructor() {
        super('elohn');

        // Never, ever remove a version when there are still databases with old data.
        // Instead, simply define a new schema version with the modified schema to create/drop/alter indexes or tables.
        // New versions need only to specify changed tables.
        // Threre is an upgrade function, which helps migrating data to a new architecture.
        // Tables are only deleted, when you specify null as the store-specification for that table in a new version.
        this.version(1).stores({
            // primary key, followed by properties that should be indexed.
            // Objects can only be queried (where clause) by indexed properties.
            // Don't index Blobs or huge strings.
            // see also: http://dexie.org/docs/Version/Version.stores()
            arbeitGeber: '$$id, &firma', // &firma is a unique index
            arbeitNehmer: '$$id, arbeitGeberId, name, vorname',
            lohnausweis: '$$id, [arbeitGeberId+arbeitNehmerId], arbeitGeberId, arbeitNehmerId, von, bis',
        });
        this.version(2).stores(
            {lohnausweis: '$$id, [arbeitGeberId+arbeitNehmerId], arbeitNehmerId'},
        );
        this.version(3).stores(
            {lohnausweis: '$$id, [arbeitGeberId+arbeitNehmerId], arbeitGeberId, arbeitNehmerId'},
        );
        this.version(4).stores(
            {validity: '$$id'},
        );
        this.version(5).upgrade(tx => {
            return tx.table('lohnausweis').toCollection().modify(lohnausweis => {
                delete lohnausweis.wizard.step3.genehmigtSteuerverwaltung;
                delete lohnausweis.wizard.step3.steuerverwaltungDatum;
                delete lohnausweis.wizard.step3.steuerverwaltungKanton;
                delete lohnausweis.wizard.step3.beteiligungenOhneSteuerEinkommen;
                delete lohnausweis.wizard.step3.beteiligungenOhneSteuerEinkommenAnswers;
            });
        });
        this.version(6).upgrade(tx => {
            return tx.table('lohnausweis').toCollection().modify(lohnausweis => {
                lohnausweis.standardBemerkungen = standardbemerkungenElm4ToElm5(lohnausweis.standardBemerkungen);
            });
        });
        // There is a Dexie API mapToClass, but it only instantiates the root Class. Thus we do manual mapping.
        /* eslint-disable  */
        this.arbeitGeber.hook('reading', data => plainToClass(ArbeitGeber, data));
        this.arbeitNehmer.hook('reading', data => plainToClass(ArbeitNehmer, data));
        this.lohnausweis.hook('reading', data => plainToClass(Lohnausweis, data));
        this.validity.hook('reading', data => plainToClass(Validity, data));
        /* eslint-enable */
    }

    clearAll$(): Observable<Database> {
        const tables = [
            this.arbeitGeber,
            this.arbeitNehmer,
            this.lohnausweis,
            this.validity,
        ];

        const clearTransaction: Promise<void[]> = this.transaction(
            'rw',
            tables,
            () => Promise.all(tables.map(table => table.clear())),
        );

        return from(clearTransaction).pipe(
            map(() => this),
        );
    }

}
