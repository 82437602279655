/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Invalid} from './Invalid';
import {Valid} from './Valid';

export interface IsValid<T> {
    isValid(): boolean;

    toValid(): Valid<T>;
}

export function isInvalid<T>(entity: IsValid<T>): entity is Invalid<T> {
    return !entity.isValid();
}

export function toInvalidEntities<T>(entities: IsValid<T>[]): T[] {
    return entities.filter(isInvalid)
        .map(invalid => invalid.errorEntity);
}
