import {classToPlain, plainToClass} from 'class-transformer';
import {StandardBemerkungen} from '../../../lohnausweis/shared/standard-bemerkungen/standard-bemerkungen.model';
import {ELM4StandardBemerkungen} from './elm4types/ELM4StandardBemerkungen';

export function standardbemerkungenElm4ToElm5(oldSBFromDB: any): any {
    if (oldSBFromDB === undefined || oldSBFromDB === null) {
        // keep original nullability
        return oldSBFromDB
    }

    console.log('before', oldSBFromDB);

    const migrated = plainToClass(StandardBemerkungen, oldSBFromDB, {excludeExtraneousValues: true});
    const old = plainToClass(ELM4StandardBemerkungen, oldSBFromDB, {excludeExtraneousValues: false});

    if (hasProperty(old, 'mehrereLohnausweiseEnabled')) {
        migrated.numberOfSalaryCertificateEnabled = old.mehrereLohnausweiseEnabled;
        migrated.numberOfSalaryCertificate = old.mehrereLohnausweiseAnzahl;
    }

    const result = classToPlain(migrated);

    console.log('after', result);

    return result;
}

function hasProperty(
    obj: ELM4StandardBemerkungen,
    name: keyof ELM4StandardBemerkungen,
): boolean {
    const result = name in obj;

    return result;
}
