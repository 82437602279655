/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {KorrespondenzSprache} from '../../../../core/sprache.enum';
import {OverflowingPlaceholderType} from './OverflowingPlaceholders';
import {SVGMappingDocument} from './SVGMappingDocument';
import {SVGPlaceholder} from './SVGPlaceholder';

export const OVERFLOW_INDICATOR = '<tspan font-family="Wingdings3">⟫</tspan>';

export class OverflowingPlaceholder extends SVGPlaceholder<string> {

    public constructor(
        public readonly placeholder: OverflowingPlaceholderType,
        private readonly width: number,
        private readonly additional?: SVGPlaceholder<string>,
    ) {
        super(placeholder, value => value);
    }

    public write(document: SVGMappingDocument, value: string, sprache: KorrespondenzSprache): void {
        if (document.widthCalculator(value) <= this.width) {
            // write everything to this placeholder
            super.write(document, value, sprache);

            if (this.additional) {
                this.additional.write(document, '', sprache);
            }

            return;
        }

        if (!this.additional) {
            // write everything to this placeholder and add an overflow
            const substr = this.findFittingSubstring(document, value, true);
            this.addOverflow(document, value, substr);

            super.write(document, substr, sprache);

            return;
        }

        // fill this placeholder
        const line1 = this.findFittingSubstring(document, value, false);
        super.write(document, line1, sprache);

        const remainingText = value.substring(line1.length);

        if (document.widthCalculator(remainingText) <= this.width) {
            // write the rest to the additional placeholder
            this.additional.write(document, remainingText, sprache);

            return;
        }

        // write the rest to the additional placeholder and add an overflow
        const line2 = this.findFittingSubstring(document, remainingText, true);
        this.addOverflow(document, value, line1 + line2);

        this.additional.write(document, line2, sprache);
    }

    private findFittingSubstring(document: SVGMappingDocument, value: string, addIndicator = false): string {
        const substr = this.findRecursive(document, value, value.length - 1);

        return addIndicator ? `${substr} ${OVERFLOW_INDICATOR}` : substr;
    }

    private findRecursive(document: SVGMappingDocument, value: string, substrLength: number): string {
        if (substrLength === 0) {
            return '';
        }

        const text = value.substring(0, substrLength);
        const middleLength = Math.floor(text.length / 2);
        const middle = value.substring(0, middleLength);

        const middleWidth = document.widthCalculator(middle);

        if (middleWidth > this.width) {
            // noinspection TailRecursionJS
            return this.findRecursive(document, value, middleLength);
        }

        const largerWidth = document.widthCalculator(text);

        const large = value.substring(0, substrLength - 1);
        const smallerWidth = document.widthCalculator(large);

        if (smallerWidth <= this.width && this.width < largerWidth) {
            return large;
        }

        // noinspection TailRecursionJS
        return this.findRecursive(document, value, substrLength - 1);
    }

    private addOverflow(document: SVGMappingDocument, fullText: string, croppedText: string): void {
        this.initMultiPage(document);
        document.multiPage!.overflows[this.placeholder] = {
            fullText,
            croppedText,
        };
    }

    private initMultiPage(document: SVGMappingDocument): void {
        // noinspection AssignmentReplaceableWithOperatorAssignmentJS
        document.multiPage = document.multiPage ?? {
            overflows: {},
            overflowHeader: '',
        };
    }
}
