/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {classToPlain} from 'class-transformer';
import moment from 'moment';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {toXML} from 'to-xml';
import {environment} from '../../environments/environment';

import {ArbeitGeber} from '../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {VALID_XML_CHARACTERS} from '../core/validation/validation.const';
import {BeruflicheVorsorge} from '../lohnausweis/shared/berufliche-vorsorge.model';
import {GehaltsNebenLeistungen} from '../lohnausweis/shared/gehalts-neben-leistungen.model';
import {LohnausweisTyp} from '../lohnausweis/shared/lohnausweis-typ.enum';
import {Lohnausweis} from '../lohnausweis/shared/lohnausweis.model';
import {SpesenVerguetungen} from '../lohnausweis/shared/spesen-verguetungen.model';
import {StandardBemerkungen} from '../lohnausweis/shared/standard-bemerkungen/standard-bemerkungen.model';
import {ValueWithDescription} from '../lohnausweis/shared/value-with-description.model';
import {Adresse} from '../shared/adresse.model';
import {valueGiven} from '../shared/functions/valueGiven';
import {EMPTYTYPE_ELEM_NAMES, LONG_TAG_ELEM_NAMES} from './xml-import-export-shared';
import {
    AddresseeType,
    AddressType,
    BurReeType,
    ChargesType,
    CompanyDescriptionType,
    CompanyNameType,
    CompanyType,
    CompanyWorkingTimeType,
    ContactPersonType,
    EffectiveType,
    FringeBenefitsType,
    GeneralSalaryDeclarationDescriptionType,
    GrantType,
    RectificateType,
    JobType,
    LanguageCodeType,
    LumpSumType,
    ParticularsType,
    PersonsType,
    PersonType,
    RequestContextType,
    SalaryCountersType,
    SalaryDeclarationRequestType,
    SalaryDeclarationType,
    SocialInsuranceIdentificationType,
    SortSumType,
    StandardRemarkType,
    TaxBVGLPPContributionType,
    TaxSalariesType,
    TaxSalaryType,
    TimePeriodType,
    UserAgentType,
    WorkType,
    ContinuedProvisionOfSalaryType,
} from './xml-import.types';

function toCompanyName(arbeitGeber: ArbeitGeber): CompanyNameType {
    const result = new CompanyNameType();
    result.HrRcName = arbeitGeber.firma;

    return result;
}

function toUserAgentType(): UserAgentType {
    const result = new UserAgentType();
    result.Producer = 'DV Bern AG';
    result.Name = 'eLohn-Online';
    result.Version = environment.version;
    result.Certificate = '';
    result.ELMSalaryStandardVersion = 3;

    return result;
}

function toRequestContextType(args: Args): RequestContextType {
    const result = new RequestContextType();

    const date = moment().toDate();

    result.CompanyName = toCompanyName(args.arbeitGeber);
    result.RequestID = date.toISOString();
    result.LanguageCode = LanguageCodeType.de;
    result.TransmissionDate = date;
    result.UserAgent = toUserAgentType();
    result.TestCase = false;

    return result;
}

function toJobType(): JobType {
    const result = new JobType();
    result.Addressees = new AddresseeType();

    return result;
}

function toCompanyNameType(arbeitGeber: ArbeitGeber): CompanyNameType {
    const result = new CompanyNameType();
    result.HrRcName = arbeitGeber.firma;
    // result.ComplementaryLine

    return result;

}

function toAddressType(adresse: Adresse): AddressType {
    const result = new AddressType();
    // result.ComplementaryLine
    result.Street = adresse.strasse;
    result.Postbox = adresse.postfach;
    // result.Locality = adresse.
    result.ZipCode = adresse.plz;
    result.City = adresse.ort;
    result.Country = adresse.land;

    return result;
}

function toBurReeType(): [BurReeType] {
    const result = new BurReeType();

    // just fake data for now
    const workingTime = new CompanyWorkingTimeType();

    workingTime.CompanyWorkingTimeID = '#1';
    workingTime.WeeklyHours = 1;

    result.CompanyWorkingTime = [workingTime];

    return [result];
}

function toCompanyDescriptionType(arbeitGeber: ArbeitGeber): CompanyDescriptionType {
    const result = new CompanyDescriptionType();
    result.Name = toCompanyNameType(arbeitGeber);
    result.Address = toAddressType(arbeitGeber.adresse);
    result.BurRee = toBurReeType();
    result.AddressPosition = arbeitGeber.addressPosition;
    result.PhoneNumber = arbeitGeber.telefon;

    return result;
}

function toSocialInsuranceIdentificationType(arbeitNehmer: ArbeitNehmer): SocialInsuranceIdentificationType {
    const result = new SocialInsuranceIdentificationType();
    result.SvAsNumber = arbeitNehmer.ahvNummerNeu;

    return result;
}

function toParticularsType(arbeitNehmer: ArbeitNehmer): ParticularsType {
    const result = new ParticularsType();
    result.SocialInsuranceIdentification = toSocialInsuranceIdentificationType(arbeitNehmer);
    // result.EmployeeNumber;
    result.Lastname = arbeitNehmer.name;
    result.Firstname = arbeitNehmer.vorname;
    result.Language = arbeitNehmer.korrespondezSprache;
    // result.Sex
    result.DateOfBirth = arbeitNehmer.geburtsdatum;
    // result.Nationality
    // result.CivilStatus
    // result.SingleParent;
    result.Address = toAddressType(arbeitNehmer.adresse);
    // result.ResidenceCanton
    // result.ResidenceCategory;
    // result.DegreeOfRelationship;

    return result;
}

/* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
function toWorkType(ignored: ArbeitNehmer): WorkType {
    const result = new WorkType;
    // result.WorkplaceCanton

    return result;
}

function toTimePeriodType(von: Date, bis: Date): TimePeriodType {
    const result = new TimePeriodType();
    result.from = von;
    result.until = bis;

    return result;
}

function toSortSumType(value: ValueWithDescription): SortSumType {
    const result = new SortSumType();
    result.Sum = value.value!;
    result.Text = value.beschreibung;

    return result;
}

function toFringeBenefitsType(
    gehaltsNebenLeistungen: GehaltsNebenLeistungen,
): FringeBenefitsType {
    const result = new FringeBenefitsType();
    result.CompanyCar = gehaltsNebenLeistungen.privatanteilGeschaeftswagen!;
    result.FoodLodging = gehaltsNebenLeistungen.verpflegung!;
    result.Other = toSortSumType(gehaltsNebenLeistungen.andere);

    return result;
}

function toTaxBVGLPPContributionType(beruflicheVorsorge: BeruflicheVorsorge): TaxBVGLPPContributionType {
    const result = new TaxBVGLPPContributionType();
    result.Purchase = beruflicheVorsorge.beitraegeFuerDenEinkauf!;
    result.Regular = beruflicheVorsorge.ordentlicheBeitraege!;

    return result;
}

function toLumSumType(spesenVerguetungen: SpesenVerguetungen): LumpSumType {
    const result = new LumpSumType();
    result.Car = spesenVerguetungen.pauschalAuto!;
    result.Representation = spesenVerguetungen.pauschalRepraesentation!;
    result.Other = toSortSumType(spesenVerguetungen.pauschalUebrige);

    return result;
}

function toEffectiveType(spesenVerguetungen: SpesenVerguetungen): EffectiveType {
    const result = new EffectiveType();

    // Setting to undefined (no export) when checkbox is false and no value was given
    const effektivReise = spesenVerguetungen.effektivReise;
    result.TravelFoodAccommodation = !spesenVerguetungen.effektivReiseCheck && !valueGiven(effektivReise)
        ? undefined
        : effektivReise;

    result.Other = toSortSumType(spesenVerguetungen.effektivUebrige);

    return result;
}

function toChargesType(spesenVerguetungen: SpesenVerguetungen): ChargesType {
    const result = new ChargesType();
    result.LumpSum = toLumSumType(spesenVerguetungen);
    result.Education = spesenVerguetungen.weiterbildung!;
    result.Effective = toEffectiveType(spesenVerguetungen);

    return result;
}

function toStandarRemark(sb: StandardBemerkungen): StandardRemarkType | undefined {
    const result = new StandardRemarkType();
    let found = false;

    if (sb.childAllowancePerAHVAVSEnabled) {
        found = true;
        result.ChildAllowancePerAhvAvs = sb.childAllowancePerAHVAVSEnabled;
    }

    if (sb.kurzArbeit) {
        found = true;
        result.KurzArbeit = sb.kurzArbeit;
    }

    if (sb.CompanyCarClarify) {
        found = true;
        result.CompanyCarClarify = sb.CompanyCarClarify;
    }

    if (sb.MinimalEmployeeCarPartPercentage) {
        found = true;
        result.MinimalEmployeeCarPartPercentage = sb.MinimalEmployeeCarPartPercentage;
    }

    if(sb.expatriateRulingEnabled){
        found = true;
        result.ExpatriateRulingEnabled = sb.expatriateRulingEnabled;
        result.ExpatriateRulingValue = new GrantType();
        result.ExpatriateRulingValue.Allowed = sb.expatriateRulingValue.datum!;
        result.ExpatriateRulingValue.Canton = sb.expatriateRulingValue.kanton!;
    }

    if (sb.activityRate) {
        found = true;
        result.ActivityRate = sb.activityRate;
    }

    if (sb.taxAtSourcePeriodForObjection) {
        found = true;
        result.TaxAtSourcePeriodForObjection = sb.taxAtSourcePeriodForObjection;
    }

    if (sb.RectificateEnabled) {
        found = true;
        result.RectificateEnabled = sb.RectificateEnabled;
        result.Rectificate = new RectificateType();
        result.Rectificate.OriginalDate = sb.Rectificate!;
    }

    if (sb.ContinuedProvisionOfSalaryEnabled) {
        found = true;
        result.ContinuedProvisionOfSalaryEnabled = sb.ContinuedProvisionOfSalaryEnabled;
        result.ContinuedProvisionOfSalary = new ContinuedProvisionOfSalaryType();
        result.ContinuedProvisionOfSalary.CHF = sb.ContinuedProvisionOfSalary.CHF!;
        result.ContinuedProvisionOfSalary.Lastname = sb.ContinuedProvisionOfSalary.Lastname!;
        result.ContinuedProvisionOfSalary.Firstname = sb.ContinuedProvisionOfSalary.Firstname!;
        result.ContinuedProvisionOfSalary.Address = new AddressType();
        result.ContinuedProvisionOfSalary.Address.Street = sb.ContinuedProvisionOfSalary.Address.strasse!;
        result.ContinuedProvisionOfSalary.Address.ZipCode = sb.ContinuedProvisionOfSalary.Address.plz!;
        result.ContinuedProvisionOfSalary.Address.City = sb.ContinuedProvisionOfSalary.Address.ort!;
    }

    if (sb.relocationCostsEnabled) {
        found = true;
        result.RelocationCosts = sb.relocationCosts!;
    }

    if (sb.staffShareMarketValueEnabled) {
        found = true;
        result.StaffShareMarketValue = new GrantType();
        result.StaffShareMarketValue.Allowed = sb.staffShareMarketValue.datum!;
        result.StaffShareMarketValue.Canton = sb.staffShareMarketValue.kanton!;
    }

    if (sb.staffShareWithoutTaxableIncomeEnabled) {
        found = true;
        result.StaffShareWithoutTaxableIncome = sb.staffShareWithoutTaxableIncomeReasons.buildTranslationKeys()
            .join('|');
    }

    // region custom types
    if (sb.spesenReglementGenehmigtEnabled) {
        found = true;
        result.SpesenReglementGenehmigt = new GrantType();
        result.SpesenReglementGenehmigt.Allowed = sb.spesenReglementGenehmigt.datum!;
        result.SpesenReglementGenehmigt.Canton = sb.spesenReglementGenehmigt.kanton!;
    }

    if (sb.numberOfSalaryCertificateEnabled) {
        found = true;
        result.mehrereLohnausweiseAnzahl = sb.numberOfSalaryCertificate!;
    }

    if (sb.erwerbsausfallEnabled) {
        found = true;
        result.erwerbsausfallTage = sb.erwerbsausfallTage!;
    }
    // endregion

    return found ? result : undefined;
}

function toTaxSalaryType(la: Lohnausweis): TaxSalaryType {
    const result = new TaxSalaryType();
    result.Period = toTimePeriodType(la.von!, la.bis!);
    result.Year = la.jahr;
    result.FreeTransport = la.unentgeltlicheBefoerderung;
    result.CanteenLunchCheck = la.kantinenverpflegung;
    result.Income = la.lohnRente!;
    result.FringeBenefits = toFringeBenefitsType(la.gehaltsNebenLeistungen);
    result.SporadicBenefits = toSortSumType(la.unregelmaessigeLeistungen);
    result.CapitalPayment = toSortSumType(la.kapitalLeistungen);
    result.OwnershipRight = la.beteiligungsrechte!;
    result.BoardOfDirectorsRemuneration = la.verwaltungsratEntschaedigungen!;
    result.OtherBenefits = toSortSumType(la.andereLeistungen);
    result.GrossIncome = la.bruttoLohnRenteTotal;
    result.AhvAlvNbuvAvsAcAanpContribution = la.beitraegeAhvIvEoAlvNbuv!;
    result.BvgLppContribution = toTaxBVGLPPContributionType(la.beruflicheVorsorge);
    result.NetIncome = la.nettoLohnRente;
    result.DeductionAtSource = la.quellensteuerAbzug!;
    // result.ChargesRule
    result.Charges = toChargesType(la.spesenVerguetungen);
    result.OtherFringeBenefits = la.weitereGehaltsNebenLeistungen;
    // result.StandardRemark = la.
    result.Remark = la.bemerkungen;
    result.StandardRemark = toStandarRemark(la.standardBemerkungen);
    result.LohnausweisTyp = la.ausweisTyp;

    return result;
}

function toTaxSalariesType(lohnausweise: Lohnausweis[], arbeitNehmer: ArbeitNehmer): TaxSalariesType {
    const result = new TaxSalariesType();
    lohnausweise
        .filter(la => la.arbeitNehmerId === arbeitNehmer.id)
        .forEach(la => {
            switch (la.ausweisTyp) {
                case LohnausweisTyp.LOHNAUSWEIS:
                case LohnausweisTyp.RENTENBESCHEINIGUNG: {
                    const salary = toTaxSalaryType(la);
                    result.TaxSalary = (result.TaxSalary || []).concat(salary);
                    break;
                }
                default:
                    throw new Error(`Lohnausweis nicht uterstuetzt: ${la.ausweisTyp}, id:${la.id}`);
            }
        });

    return result;
}

function toArbeitNehmer(arbeitNehmer: ArbeitNehmer, lohnausweise: Lohnausweis[]): PersonType {
    const result = new PersonType();
    result.Particulars = toParticularsType(arbeitNehmer);
    result.Work = toWorkType(arbeitNehmer);
    result.TaxSalaries = toTaxSalariesType(lohnausweise, arbeitNehmer);

    return result;

}

function toPersonsType(args: Args): PersonsType {
    const result = new PersonsType();

    args.arbeitNehmer.forEach(current => {
        const arbeitNehmer = toArbeitNehmer(current, args.lohnausweise);

        result.Person = (result.Person || []).concat(arbeitNehmer);
    });

    return result;
}

function toSalaryCountersType(): SalaryCountersType {
    const result = new SalaryCountersType();

    return result;
}

function toCompanyType(args: Args): CompanyType {
    const result = new CompanyType();
    result.CompanyDescription = toCompanyDescriptionType(args.arbeitGeber);
    result.Staff = toPersonsType(args);
    result.SalaryCounters = toSalaryCountersType();

    return result;
}

function toContactPersonType(arbeitGeber: ArbeitGeber): ContactPersonType {
    const result = new ContactPersonType();
    result.Name = arbeitGeber.kontakt.name;
    result.PhoneNumber = arbeitGeber.kontakt.telefon;
    // result.MobilePhoneNumber = arbeitGeber.kontakt.
    // result.EmailAddress = arbeitGeber.kontakt.email;

    return result;
}

function toGeneralSalaryDeclarationDescriptionType(args: Args): GeneralSalaryDeclarationDescriptionType {
    const result = new GeneralSalaryDeclarationDescriptionType();
    result.CreationDate = moment().toDate();
    result.AccountingPeriod = args.jahr;
    result.ContactPerson = toContactPersonType(args.arbeitGeber);
    // result.Comments =

    return result;
}

function toSalaryDeclarationType(args: Args): SalaryDeclarationType {
    const result = new SalaryDeclarationType();
    result.Company = toCompanyType(args);
    result.GeneralSalaryDeclarationDescription = toGeneralSalaryDeclarationDescriptionType(args);

    //FIXME: attribute
    // schemaVersion: number = 0.0; // SupportedMinorSchemaVersionAttributeType

    return result;
}

// For Future Use: wir wissen noch nicht, ob unsere DB als SalaryDeclarationRequestType
// oder "nur" als SalaryDeclarationType gespeichert werden soll.
/* eslint-disable @typescript-eslint/no-unused-vars */

// noinspection JSUnusedLocalSymbols
function toSalaryDeclarationRequestType(args: Args): SalaryDeclarationRequestType {

    const sd = new SalaryDeclarationRequestType();

    sd.RequestContext = toRequestContextType(args);
    sd.Job = toJobType();
    sd.SalaryDeclaration = toSalaryDeclarationType(args);

    return sd;
}

/* eslint-enable @typescript-eslint/no-unused-vars */

/**
 * Add Namespaces to all elements
 */

/* eslint-disable @typescript-eslint/dot-notation */
function toXmlObjectWithNamespaces(salaryDeclarationPlain: object): object {
    const salaryDeclarationXml = JSON.parse(JSON.stringify(salaryDeclarationPlain));

    const SALARY_DECLARATION_NAMESPACE = 'https://www.elohnausweis-ssk.ch/de/assets/documents/SalaryDeclarationElohnOnline.xsd';
    const NS_PREFIX = 'sd';

    addNamespace(salaryDeclarationXml, NS_PREFIX);

    salaryDeclarationXml['@xmlns:sd'] = SALARY_DECLARATION_NAMESPACE;
    salaryDeclarationXml['@schemaVersion'] = '0.0';

    const obj = {
        'sd:SalaryDeclaration': salaryDeclarationXml,
    };

    return obj;
}

/* eslint-disable @typescript-eslint/dot-notation */

/* eslint-disable  */
function addNamespace(obj: any, namespacePrefix: string): void {
    if (!obj) {
        return;
    }
    if (Array.isArray(obj)) {
        obj.forEach(entry => addNamespace(entry, namespacePrefix));

        return;
    }

    Object.getOwnPropertyNames(obj)
        .filter(name => !name.startsWith('@')) // attributes
        .forEach(propName => {
            const propValue: any = obj[propName];
            const propNameFQ = namespacePrefix + ':' + propName;

            delete obj[propName];
            obj[propNameFQ] = propValue;

            if (typeof propValue === 'object') {
                addNamespace(propValue, namespacePrefix);
            }
        });
}

/* eslint-enable */

function replacer(name: string, value: any): any {
    const nameNoNamespace = name.substr(name.indexOf(':') + 1);
    if (LONG_TAG_ELEM_NAMES.indexOf(nameNoNamespace) >= 0) {
        return value ? 'True' : undefined;
    } else if (EMPTYTYPE_ELEM_NAMES.indexOf(nameNoNamespace) >= 0) {
        return value ? '' : undefined;
    } else {
        return value;
    }
}

function transformXmlObjectToXml(obj: any, parser: DOMParser): Document {

    //FIXME: proper params?!?
    //FIXME: works in all browsers?
    // return document.implementation.createDocument(null, null, null);
    const xmlString = toXML(obj, replacer).replace(VALID_XML_CHARACTERS, '');
    const doc = parser.parseFromString(xmlString, 'text/xml');

    return doc;
}

function xmlDocToString(next: Document): string {
    return new XMLSerializer().serializeToString(next);
}

interface Args {
    jahr: number;
    arbeitGeber: ArbeitGeber;
    arbeitNehmer: ArbeitNehmer[];
    lohnausweise: Lohnausweis[];
}

@Injectable({providedIn: 'root'})
export class XmlExportService {
    private readonly domParser = new DOMParser();

    constructor() {
        //
    }

    public buildXml$(args: {
                         jahr: number;
                         arbeitGeber: ArbeitGeber;
                         arbeitNehmer: ArbeitNehmer[];
                         lohnausweise: Lohnausweis[];
                     },
    ): Observable<Document> {

        return of({
            jahr: args.jahr,
            arbeitGeber: args.arbeitGeber,
            arbeitNehmer: args.arbeitNehmer,
            lohnausweise: args.lohnausweise,
        })
            .pipe(
                map(toSalaryDeclarationType),
                // disable tslint or else there is some compiler error due to type interference not working
                // eslint-disable-next-line
                map(value => classToPlain(value)),
                map(toXmlObjectWithNamespaces),
                map(value => transformXmlObjectToXml(value, this.domParser)),
            );
    }

    public buildXmlBlob$(args: {
                             jahr: number;
                             arbeitGeber: ArbeitGeber;
                             arbeitNehmer: ArbeitNehmer[];
                             lohnausweise: Lohnausweis[];
                         },
    ): Observable<Blob> {
        return this.buildXml$(args)
            .pipe(
                map(xmlDocToString),
                map(text => new Blob([text], {
                    type: 'text/plain',
                })),
            );
    }

}
