/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

// set the global document element to a defined state
const document = window.document as Document;

// FIXME: why? margin?
const WRAPPER_FACTOR = 1.25;

/**
 * Splits a long text into multiple lines (currently: 2019-09-18: max 2 lines).
 *
 * @param fontFamily compatible to the CSS font-family property.
 */
export function splitText(
    text: string,
    fontFamily: string,
    fontSizePx: number,
    containerWidthPx: number
): string[] {
    const [span, container] = createWrapperElement(fontFamily, fontSizePx, containerWidthPx);

    // padding: 1/10th of the width
    const maxContainerWidthPx = containerWidthPx - (containerWidthPx / 10);

    let result: string[] | null = null;

    // first we try the clean way with a space, then we break on any character
    const separators = [' ', ''];
    separators.forEach(separator => {
        if (!result) {
            result = trySplit(text, separator, span, maxContainerWidthPx);
        }
    });

    freeWrapperElement(container);

    // if everything fails return at least the original text
    return result ? result : [text];
}

function trySplit(
    text: string,
    separator: string,
    span: HTMLSpanElement,
    maxContainerWidthPx: number
): string[] | null {
    const aText = text.split(separator);
    for (let limit = (aText.length - 1); limit >= 0; limit--) {
        const array1: string[] = [];
        const array2: string[] = [];
        aText.forEach((value, index) => {
            if (index <= limit) {
                array1.push(value);
            } else {
                array2.push(value);
            }
        });
        span.innerText = array1.join(separator);

        if (span.offsetWidth <= maxContainerWidthPx) {

            let result = [array1.join(separator)];
            if (array2.length) {
                result = result.concat(array2.join(separator));
            }

            return result;
        }
    }

    return null;
}

function createWrapperElement(
    fontFamily: string,
    fontSizePx: number,
    containerWidth: number
): [HTMLSpanElement, HTMLElement] {

    const container = document.createElement('div');
    container.className = 'split-text-container';
    container.style.overflow = 'hidden';
    // noinspection XHTMLIncompatabilitiesJS
    document.body.appendChild(container);

    const div = document.createElement('div');
    div.className = 'split-text-container-block';
    div.style.width = Math.floor(containerWidth * WRAPPER_FACTOR).toString() + 'px';
    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    container.appendChild(div);

    const span = document.createElement('span');
    span.className = 'split-text-container-text';
    //span.style.border = '1px solid black';
    span.style.fontFamily = fontFamily;
    span.style.fontSize = fontSizePx.toString() + 'px';
    div.appendChild(span);

    return [span, container];
}

function freeWrapperElement(container: HTMLElement): void {
    // noinspection XHTMLIncompatabilitiesJS
    document.body.removeChild(container);
}
