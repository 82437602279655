/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Type} from 'class-transformer';
import {IsBoolean, IsDate, IsDefined, IsNumber, ValidateIf, ValidateNested} from 'class-validator';
import {LogFactory} from '../../../core/logging/log-factory';
import {KorrespondenzSprache} from '../../../core/sprache.enum';
import {SupportedLocale} from '../../../core/translations';
import {formatDate} from '../../../shared/functions/date-formats';
import {formatDecimal} from '../../../shared/functions/decimal-format';
import {Genehmigung} from '../genehmigung.model';
import {StaffShareWithoutTaxableIncomeReasons} from './staff-share-without-taxable-income-reasons';
import {standardBemerkungen, StandardBemerkungenKeys} from './standard-bemerkungen.translations';
import {CANTONTYPE_TRANSLATIONS} from '../../../core/canton-type.enum';
import {ContinuedProvisionOfSalary} from '../continuedProvisionOfSalary.model';

const LOG = LogFactory.createLog('StandardBemerkungen');

export class StandardBemerkungen {
    /*
    Checkbox "Kinderzulagen durch die Ausgleichskasse bezahlt" in SB-comp
    XSD: ChildAllowancePerAHV-AVS
    XML Import/EXport: ChildAllowancePerAhvAvs
    Barcode: ChildAllowancePerAhvAvs
     */
    @IsBoolean({always: true})
    public childAllowancePerAHVAVSEnabled = false;

    /*
    Checkbox "Kurzarbeitsentschädigung in Ziffer 1 enthalten" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public kurzArbeit = false;

    /*
    Checkbox "Privatanteil Geschäftsfahrzeug im Veranlagungsverfahren abzuklären" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public CompanyCarClarify = false;

    /*
    Checkbox "Privatanteil Geschäftsfahrzeug wird vom Arbeitnehmer bezahlt" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public MinimalEmployeeCarPartPercentage = false;
    /*
    Checkbox "Teilzeitbeschaftigung" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public activityRate = false;

    /*
    Checkbox "Quellensteuerpflichtige Personen" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public taxAtSourcePeriodForObjection = false;

    /*
    Checkbox "Dieser Lohnausweis ersetzt denjenigen Lohnausweis" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public RectificateEnabled = false;

    /*
    Input in SB-comp
    XSD: Rectificate
    XML Import/Export: Rectificate
    Barcode: Rectificate
     */
    @ValidateIf((sb: StandardBemerkungen) => sb.RectificateEnabled, {always: true})
    @IsDate()
    public Rectificate: Date | null = null;

    /*
    Checkbox "Expatriateruling Kanton X" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public expatriateRulingEnabled = false;

    /*
        Input in SB-comp
        XSD: expatriateRulingValue
        XML Import/Export: expatriateRulingValue
        Barcode: expatriateRulingValue
    */
    @Type(() => Genehmigung)
    @ValidateIf((sb: StandardBemerkungen) => sb.expatriateRulingEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public readonly expatriateRulingValue: Genehmigung = new Genehmigung();

    /*
    Checkbox "ContinuedProvisionOfSalary an" in SB-comp
    XML Import/Export
    Barcode
    */
    @IsBoolean({always: true})
    public ContinuedProvisionOfSalaryEnabled = false;

    /*
     Input in SB-comp
     XSD: ContinuedProvisionOfSalary
    */
    @Type(() => ContinuedProvisionOfSalary)
    @ValidateIf((sb: StandardBemerkungen) => sb.ContinuedProvisionOfSalaryEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public readonly ContinuedProvisionOfSalary: ContinuedProvisionOfSalary = new ContinuedProvisionOfSalary();

    /*
    Checkbox "Mitarbeiterbeteilgung-Verkehrswert" in SB-comp und Wizard Step 3
    XML Import/Export
    Barcode
     */
    @IsBoolean({always: true})
    public staffShareMarketValueEnabled = false;

    /*
    SB-comp & Wizard Step 3
    XSD: staffShareMarketValue
    XML Import/Export: staffShareMarketValue
    Barcode: staffShareMarketValue
     */
    @Type(() => Genehmigung)
    @ValidateIf((sb: StandardBemerkungen) => sb.staffShareMarketValueEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public readonly staffShareMarketValue: Genehmigung = new Genehmigung();

    /*
    SB-comp checkbox "Mitarbeiterbeteilgung ohne steuerbares Einkommen"
    Aktiviert die 4 Checkboxen für StaffShareWithoutTaxableIncomeReasons
     */
    @IsBoolean({always: true})
    public staffShareWithoutTaxableIncomeEnabled = false;

    /*
    SB-comp und Wizard Step 3
    XSD: StaffShareWithoutTaxableIncome
    XML Import/Export: StaffShareWithoutTaxableIncome
    Barcode: StaffShareWithoutTaxableIncome
     */
    @Type(() => StaffShareWithoutTaxableIncomeReasons)
    @ValidateIf((sb: StandardBemerkungen) => sb.staffShareWithoutTaxableIncomeEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public staffShareWithoutTaxableIncomeReasons = new StaffShareWithoutTaxableIncomeReasons();

    /*
    Checkbox "Umzugskosten durch Arbeitgeber" in SB-comp
    XML Import/Export
    Barcode
     */
    @IsBoolean({always: true})
    public relocationCostsEnabled = false;

    /*
    Input in SB-comp
    XSD: RelocationCosts
    XML Import/Export: RelocationCosts
    Barcode: RelocationCosts
     */
    @ValidateIf((sb: StandardBemerkungen) => sb.relocationCostsEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    //FIXME: SalaryAmountType ist -Unendlich < 0.0 < Undenlich mit max 2 Nachkommastellen 2018
    public relocationCosts: number | null = null;

    /*
    SB-comp checkbox "Spesenreglement durch" und Wizard Step 5
     */
    @IsBoolean({always: true})
    public spesenReglementGenehmigtEnabled = false;

    /*
    SB-comp & Wizard Step 5
    XSD: nicht definiert!
    XML: SpesenReglementGenehmigt
    Barcode: SpesenReglementGenehmigt
     */
    @Type(() => Genehmigung)
    @ValidateIf((sb: StandardBemerkungen) => sb.spesenReglementGenehmigtEnabled, {always: true})
    @IsDefined({always: true})
    @ValidateNested({always: true})
    public readonly spesenReglementGenehmigt: Genehmigung = new Genehmigung();

    /*
    SB-comp checkbox "Einer von"
     */
    @IsBoolean({always: true})
    public numberOfSalaryCertificateEnabled = false;

    /*
    SB-comp input numberOfSalaryCertificate
    XSD: nicht definiert!
    XML: numberOfSalaryCertificate
    Barcode: nicht definiert!
     */
    @ValidateIf((sb: StandardBemerkungen) => sb.numberOfSalaryCertificateEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    public numberOfSalaryCertificate: number | null = null;

    /*
    SB-comp checkbox "Tage mit Erwerbsausfall"
     */
    @IsBoolean({always: true})
    public erwerbsausfallEnabled = false;

    /*
    SB-comp input erwerbsausfallTage
    XSD: nicht definiert!
    XML: erwerbsausfallTage
    Barcode: nicht definiert!
     */
    @ValidateIf((sb: StandardBemerkungen) => sb.erwerbsausfallEnabled, {always: true})
    @IsNumber(undefined, {always: true})
    public erwerbsausfallTage: number | null = null;

    public relocationCostsFormatted(sprache: KorrespondenzSprache): string {
        return formatDecimal(this.relocationCosts, sprache, 2);
    }

    public anzahlTageFormatted(sprache: KorrespondenzSprache): string {
        return formatDecimal(this.erwerbsausfallTage, sprache);
    }

    public anzahlLohnausweiseFormatted(sprache: KorrespondenzSprache): string {
        return formatDecimal(this.numberOfSalaryCertificate, sprache);
    }

    public buildLines(sprache: KorrespondenzSprache): string[] {
        const lines: string[] = [];

        /**
         * replace {0}, {1}, ... by its matching entry in the args array (index is 0-based)
         */
        function translate(key: StandardBemerkungenKeys, ...args: string[]): string {
            const template = standardBemerkungen(key, sprache);

            let result = template;

            args.forEach((arg, idx) => {
                const search = `{${idx}}`;
                result = result.replace(search, arg);
            });

            if (result.match(/\\{\d+\\}/)) {
                LOG.warn(`could not replace all placeholders: ${key}/${sprache}`);
            }

            return result;
        }

        if (this.childAllowancePerAHVAVSEnabled) {
            lines.push(translate('childAllowancePerAHVAVS'));
        }

        if (this.kurzArbeit) {
            lines.push(translate('kurzArbeit'));
        }

        if (this.CompanyCarClarify) {
            lines.push(translate('CompanyCarClarify'));
        }

        if (this.MinimalEmployeeCarPartPercentage) {
            lines.push(translate('MinimalEmployeeCarPartPercentage'));
        }

        if (this.taxAtSourcePeriodForObjection) {
            lines.push(translate('taxAtSourcePeriodForObjection'));
        }

        if (this.activityRate) {
            lines.push(translate('activityRate'));
        }

        if (this.RectificateEnabled) {
            lines.push(translate('ersetztLohnausweisStr', formatDate(this.Rectificate, sprache) || ''));
        }

        if (this.expatriateRulingEnabled) {
            const key = this.expatriateRulingValue.kanton && CANTONTYPE_TRANSLATIONS[this.expatriateRulingValue.kanton];
            const translation = key && key[sprache as SupportedLocale];
            lines.push(translate('expatriateRulingStr',  translation || '', formatDate(this.expatriateRulingValue.datum, sprache) || ''));
        }

        if (this.ContinuedProvisionOfSalaryEnabled) {
            const address = this.ContinuedProvisionOfSalary.Address.strasse + ', '
                    + this.ContinuedProvisionOfSalary.Address.plz + ' '
                    + this.ContinuedProvisionOfSalary.Address.ort;
            lines.push(translate('continuedProvisionOfSalaryStr', this.ContinuedProvisionOfSalary.CHF || '',
                this.ContinuedProvisionOfSalary.Firstname || '', this.ContinuedProvisionOfSalary.Lastname || '', address || ''));
        }

        if (this.relocationCostsEnabled) {
            lines.push(translate('relocationCosts', this.relocationCostsFormatted(sprache)));
        }

        if (this.staffShareMarketValueEnabled) {
            lines.push(translate('staffShareMarketValue',
                formatDate(this.staffShareMarketValue.datum, sprache) || '',
                this.staffShareMarketValue.kanton || ''),
            );
        }

        if (this.staffShareWithoutTaxableIncomeEnabled) {
            const LINE_PREFIX = '- ';
            const reasonsText = '\n' + this.staffShareWithoutTaxableIncomeReasons.buildTranslationKeys()
                .map(key => LINE_PREFIX + translate(key))
                .join('\n');

            lines.push(translate('staffShareWithoutTaxableIncome', reasonsText));
        }

        if (this.spesenReglementGenehmigtEnabled) {
            lines.push(translate('spesenReglementGenehmigt',
                formatDate(this.spesenReglementGenehmigt.datum, sprache) || '',
                this.spesenReglementGenehmigt.kanton || ''));
        }

        if (this.numberOfSalaryCertificateEnabled) {
            lines.push(translate('numberOfSalaryCertificateStr', this.anzahlLohnausweiseFormatted(sprache)));
        }

        if (this.erwerbsausfallEnabled) {
            lines.push(translate('erwerbsausfall', this.anzahlTageFormatted(sprache)));
        }

        return lines;
    }
}
