/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Transform, Type} from 'class-transformer';
import {
    IsDefined,
    IsEnum,
    IsInt,
    IsOptional,
    IsUUID,
    MinDate,
    ValidateNested,
    ValidationOptions,
} from 'class-validator';
import {UUID_VERSION} from '../../core/constants';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';
import {Persistable} from '../../data-store/Persistable';
import {DisplayName} from '../../core/DisplayName';
import {KorrespondenzSprache} from '../../core/sprache.enum';
import {IsSameOrAfter} from '../../core/validation/IsSameOrAfter';
import {IsInYear} from '../../core/validation/IsInYear';
import {MIN_ALLOWED_YEAR, OPTS_BOTH, OPTS_LOHNAUSWEIS} from '../../core/validation/validation.const';
import {formatDate} from '../../shared/functions/date-formats';
import {subtract} from '../../shared/functions/subtract';
import {sum} from '../../shared/functions/sum';
import {createUUID} from '../../shared/functions/uuid';
import {transformToEnum} from '../../shared/transform-helper/transform.helper';
import {BeruflicheVorsorge} from './berufliche-vorsorge.model';
import {GehaltsNebenLeistungen} from './gehalts-neben-leistungen.model';
import {LohnausweisTyp} from './lohnausweis-typ.enum';
import {SpesenVerguetungen} from './spesen-verguetungen.model';
import {StandardBemerkungen} from './standard-bemerkungen/standard-bemerkungen.model';
import {ValueWithDescription} from './value-with-description.model';
import {Wizard} from './wizard.model';

export class Lohnausweis implements Persistable, DisplayName {

    @IsUUID(UUID_VERSION, OPTS_BOTH)
    id: string;

    @IsUUID(UUID_VERSION, OPTS_BOTH)
    arbeitNehmerId: string;

    @IsUUID(UUID_VERSION, OPTS_BOTH)
    arbeitGeberId: string;

    /**
     * WizardSteps
     */
    @Type(() => Wizard)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    wizard: Wizard = new Wizard();

    /**
     * A/B
     * Lohnausweis: TaxSalaryType
     * Rentenscheinigung: TaxAnnuityType
     */
    @Transform(value => transformToEnum(value, LohnausweisTyp))
    @IsDefined(OPTS_BOTH)
    @IsEnum(LohnausweisTyp, OPTS_BOTH)
    ausweisTyp: LohnausweisTyp = LohnausweisTyp.LOHNAUSWEIS;

    /**
     * D (siehe unten: property {@link Lohnausweis#jahr})
     */
    public jahr: number;

    /**
     * E: from
     */
    @IsDefined(OPTS_BOTH)
    @MinDate(MIN_ALLOWED_YEAR, OPTS_BOTH)
    @IsInYear('jahr', OPTS_BOTH)
    von: Date | null = null;

    /**
     * E: until
     */
    @IsDefined(OPTS_BOTH)
    @MinDate(MIN_ALLOWED_YEAR, OPTS_BOTH)
    @IsInYear('jahr', OPTS_BOTH)
    @IsSameOrAfter('von', OPTS_BOTH)
    bis: Date | null = null;

    /**
     * F: FreeTransport
     */
    @IsDefined(OPTS_LOHNAUSWEIS)
    unentgeltlicheBefoerderung = false;

    /**
     * G: CanteenLunchCheck
     */
    @IsDefined(OPTS_LOHNAUSWEIS)
    kantinenverpflegung = false;

    /**
     * 1: Income
     */
    @IsOptional(OPTS_BOTH)
    @IsDefined(OPTS_BOTH)
    @IsInt(OPTS_BOTH)
    lohnRente: number | null = null;

    /**
     * 2: FringeBenefits
     */
    @Type(() => GehaltsNebenLeistungen)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    gehaltsNebenLeistungen = new GehaltsNebenLeistungen();

    /**
     * 3: SporadicBenefits
     */
    @Type(() => ValueWithDescription)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    unregelmaessigeLeistungen = new ValueWithDescription();

    /**
     * 4: CapitalPayment
     */
    @Type(() => ValueWithDescription)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @ValidateNested(OPTS_LOHNAUSWEIS)
    kapitalLeistungen = new ValueWithDescription();

    /**
     * 5: OwnershipRight
     */
    @IsOptional(OPTS_BOTH)
    @IsDefined(OPTS_BOTH)
    @IsInt(OPTS_BOTH)
    beteiligungsrechte: number | null = null;

    /**
     * 6: BoardOfDirectorsRemuneration
     */
    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    verwaltungsratEntschaedigungen: number | null = null;

    /**
     * 7: OtherBenefits
     */
    @Type(() => ValueWithDescription)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    andereLeistungen = new ValueWithDescription();

    /**
     * 9: AHV-ALV-NBUV-AVS-AC-AANP-Contribution
     */
    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    beitraegeAhvIvEoAlvNbuv: number | null = null;

    /**
     * 10: BVG-LPP-Contribution
     */
    @Type(() => BeruflicheVorsorge)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @ValidateNested(OPTS_LOHNAUSWEIS)
    beruflicheVorsorge = new BeruflicheVorsorge();

    /**
     * 12: DeductionAtSource
     */
    @IsOptional(OPTS_BOTH)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @IsInt(OPTS_LOHNAUSWEIS)
    quellensteuerAbzug: number | null = null;

    /**
     * 13: ChargesRule, Charges
     */
    @Type(() => SpesenVerguetungen)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @ValidateNested(OPTS_LOHNAUSWEIS)
    spesenVerguetungen = new SpesenVerguetungen();

    /**
     * 14: OtherFringeBenefits
     */
    @IsOptional(OPTS_LOHNAUSWEIS)
    @IsDefined(OPTS_LOHNAUSWEIS)
    @IsValidXMLValue({always: true})
    weitereGehaltsNebenLeistungen = '';

    @Type(() => StandardBemerkungen)
    @IsDefined(OPTS_BOTH)
    @ValidateNested(OPTS_BOTH)
    standardBemerkungen: StandardBemerkungen = new StandardBemerkungen();

    /**
     * 15: Remark
     */
    @IsOptional(OPTS_BOTH)
    @IsDefined(OPTS_BOTH)
    @IsValidXMLValue({always: true})
    bemerkungen = '';

    constructor(
        jahr: number,
        args: {
            id?: string,
            arbeitNehmerId?: string,
            arbeitGeberId?: string
        } = {}) {
        this.jahr = jahr;
        this.id = args.id || createUUID();
        this.arbeitNehmerId = args.arbeitNehmerId || '';
        this.arbeitGeberId = args.arbeitGeberId || '';
    }

    public toString(): string {
        return `Lohnausweis[${this.id},${this.ausweisTyp},${this.arbeitNehmerId},${this.arbeitGeberId}`;
    }

    public getDisplayName(locale: string): string {
        const vonText = this.von ? formatDate(this.von, locale) : '??.??.????';
        const bisText = this.bis ? formatDate(this.bis, locale) : '??.??.????';

        return `${vonText} - ${bisText}`;
    }

    /**
     * 8: GrossIncome
     */
    public get bruttoLohnRenteTotal(): number {
        const brutto = sum(
            this.lohnRente,
            this.gehaltsNebenLeistungen.total,
            this.unregelmaessigeLeistungen.value,
            this.kapitalLeistungen.value,
            this.beteiligungsrechte,
            this.verwaltungsratEntschaedigungen,
            this.andereLeistungen.value
        );

        return brutto;
    }

    /**
     * 11: NetIncome
     */
    public get nettoLohnRente(): number {
        return subtract(this.bruttoLohnRenteTotal, this.beitraegeAhvIvEoAlvNbuv, this.beruflicheVorsorge.total);
    }

    public get isLohn(): boolean {
        return this.ausweisTyp === LohnausweisTyp.LOHNAUSWEIS;
    }

    public get isRente(): boolean {
        return this.ausweisTyp === LohnausweisTyp.RENTENBESCHEINIGUNG;
    }

    public buildAllBemerkungenText(sprache: KorrespondenzSprache): string {
        const lines = (this.bemerkungen && this.bemerkungen.length > 0)
            ? [this.bemerkungen, ...this.standardBemerkungen.buildLines(sprache)]
            : this.standardBemerkungen.buildLines(sprache);

        return lines.join('\n');
    }

    public validationGroup(): string[] {
        return [this.ausweisTyp];
    }

    public validationOptions(): ValidationOptions {
        return {groups: this.validationGroup()};
    }
}
