/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ArbeitGeberFixture} from '../../arbeit-geber/shared/arbeit-geber.fixture';
import {ArbeitGeber} from '../../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmerFixture} from '../../arbeit-nehmer/shared/arbeit-nehmer.fixture';
import {ArbeitNehmer} from '../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {DataInitializerService} from '../data-initializer.service';
import {DataStoreService} from '../data-store.service';
import {LogFactory} from '../../core/logging/log-factory';
import {LohnausweisFixture} from '../../lohnausweis/shared/lohnausweis.fixture';
import {Lohnausweis} from '../../lohnausweis/shared/lohnausweis.model';
import {createUUID} from '../../shared/functions/uuid';

const LOG = LogFactory.createLog('DataStoreComponent');

@Component({
    selector: 'elohn-data-store',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './data-store.component.html',
    styleUrls: ['./data-store.component.scss']
})
export class DataStoreComponent implements OnInit, OnDestroy {

    public allArbeitGeber$!: Observable<ArbeitGeber[]>;
    public allArbeitNehmer$!: Observable<ArbeitNehmer[]>;
    public allLohnausweise$!: Observable<Lohnausweis[]>;

    private readonly unsubscribe$ = new Subject<void>();

    constructor(private readonly store: DataStoreService, private readonly initializer: DataInitializerService) {
    }

    public ngOnInit(): void {
        this.allArbeitGeber$ = this.store.arbeitGeber.getAll$();
        this.allArbeitNehmer$ = this.store.arbeitNehmer.getAll$();
        this.allLohnausweise$ = this.store.lohnausweis.getAll$();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public addArbeitGeber(): void {
        this.store.arbeitGeber.add$(new ArbeitGeberFixture())
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                key => {
                    LOG.info(`added ArbeitGeber with primary key ${key}`);
                    this.ngOnInit();
                },
                (err: unknown) => LOG.error(`failed to add ArbeitGeber: ${err}`)
            );
    }

    public deleteArbeitGeber(arbeitGeber: ArbeitGeber): void {
        this.store.arbeitGeber.delete$(arbeitGeber)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => {
                    LOG.info(`deleted ArbeitGeber with primary key ${arbeitGeber.id}`);
                    this.ngOnInit();
                },
                (err: unknown) => LOG.error(`failed to delete ArbeitGeber: ${err}`)
            );
    }

    public addArbeitNehmer(): void {
        this.store.arbeitNehmer.add$(new ArbeitNehmerFixture(createUUID()))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                key => {
                    LOG.info(`added ArbeitNehmer with primary key ${key}`);
                    this.ngOnInit();
                },
                (err: unknown) => LOG.error(`failed to add ArbeitNehmer: ${err}`)
            );
    }

    public deleteArbeitNehmer(arbeitNehmer: ArbeitNehmer): void {
        this.store.arbeitNehmer.delete$(arbeitNehmer)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => {
                    LOG.info(`deleted ArbeitNehmer with primary key ${arbeitNehmer.id}`);
                    this.ngOnInit();
                },
                (err: unknown) => LOG.error(`failed to delete ArbeitNehmer: ${err}`)
            );
    }

    public addLohnausweis(): void {
        this.store.lohnausweis.add$(new LohnausweisFixture(createUUID()))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                key => {
                    LOG.info(`added Lohnausweis with primary key ${key}`);
                    this.ngOnInit();
                },
                (err: unknown) => LOG.error(`failed to add Lohnausweis: ${err}`)
            );
    }

    public deleteLohnausweis(lohnausweis: Lohnausweis): void {
        this.store.lohnausweis.delete$(lohnausweis)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => {
                    LOG.info(`deleted Lohnausweis with primary key ${lohnausweis.id}`);
                    this.ngOnInit();
                },
                (err: unknown) => LOG.error(`failed to delete Lohnausweis: ${err}`)
            );
    }

    public reset(): void {
        this.initializer.resetDatabase$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => this.ngOnInit(),
                (err: unknown) => LOG.error(`failed to reset the database: ${err}`)
            );
    }
}
