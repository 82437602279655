/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Transform, Type} from 'class-transformer';
import {IsDefined, IsInt, IsNotEmpty, IsUUID, ValidateNested} from 'class-validator';
import {UUID_VERSION} from '../../core/constants';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';
import {Persistable} from '../../data-store/Persistable';
import {DisplayName} from '../../core/DisplayName';
import {AddressPosition} from '../../printing/pdf/pdf-generator/models/AddressPosition';
import {Adresse} from '../../shared/adresse.model';
import {createUUID} from '../../shared/functions/uuid';
import {transformToEnum} from '../../shared/transform-helper/transform.helper';
import {Kontakt} from './kontakt.model';

export class ArbeitGeber implements Persistable, DisplayName {

    @IsUUID(UUID_VERSION)
    public id: string = createUUID();

    @IsNotEmpty()
    @IsValidXMLValue({always: true})
    public firma = '';

    @IsValidXMLValue({always: true})
    public telefon = '';

    @Type(() => Adresse)
    @IsDefined()
    @ValidateNested()
    public adresse: Adresse = new Adresse();

    @Type(() => Kontakt)
    @IsDefined()
    @ValidateNested()
    public kontakt: Kontakt = new Kontakt();

    @Transform((params) => transformToEnum(params, AddressPosition))
    @IsNotEmpty()
    addressPosition: AddressPosition = AddressPosition.RIGHT;

    @IsInt()
    bearbeitungsJahr: number | null = null;

    constructor(args: { id?: string; firma?: string; bearbeitungsJahr?: number } = {}) {
        this.id = args.id || createUUID();
        this.firma = args.firma || '';
        this.bearbeitungsJahr = args.bearbeitungsJahr || null;
    }

    //TODO: ueberall umbiegen auf getDisplayName()
    public get displayName(): string {
        return this.firma;
    }

    /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
    public getDisplayName(ignoredLocale?: string): string {
        return this.displayName;
    }
}
