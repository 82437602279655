export function assertCondition(condition: any, message?: string | (() => string)): asserts condition {
    if (condition) {
        return;
    }

    if (message) {
        const text = (typeof message === 'function')
            ? message()
            : message;
        throw Error(`Condition not met: ${text}`);
    } else {
        throw Error('Condition not met');
    }
}
