import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {parse} from 'js2xmlparser';
import {ArbeitGeber} from '../../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../../arbeit-nehmer/shared/arbeit-nehmer.model';
import {KorrespondenzSprache} from '../../core/sprache.enum';
import {BeruflicheVorsorge} from '../../lohnausweis/shared/berufliche-vorsorge.model';
import {GehaltsNebenLeistungen} from '../../lohnausweis/shared/gehalts-neben-leistungen.model';
import {Lohnausweis} from '../../lohnausweis/shared/lohnausweis.model';
import {LohnausweisPDF} from '../../lohnausweis/shared/LohnausweisPDF.model';
import {SpesenVerguetungen} from '../../lohnausweis/shared/spesen-verguetungen.model';
import {StandardBemerkungen} from '../../lohnausweis/shared/standard-bemerkungen/standard-bemerkungen.model';
import {ValueWithDescription} from '../../lohnausweis/shared/value-with-description.model';
import {cleanString, formatISODate, valueGiven} from '../../shared/functions';

@Injectable({providedIn: 'root'})
export class BarcodeXmlService {
    private readonly domParser = new DOMParser();
    private readonly xmlSerializer = new XMLSerializer();

    constructor(
        @Inject(LOCALE_ID) public readonly locale: KorrespondenzSprache,
    ) {
    }

    async buildBarcodeXml(lohnausweisPDF: LohnausweisPDF): Promise<string> {
        return this.buildBarcodeDocument(lohnausweisPDF)
            .then(doc => this.serializeToString(doc));
    }

    private serializeToString(doc: Document): string {
        const xmlString = this.xmlSerializer.serializeToString(doc);

        return xmlString;
    }

    private async buildBarcodeDocument(lohnausweisPDF: LohnausweisPDF): Promise<Document> {
        const barcodeData = this.buildBarcodeObject(lohnausweisPDF);

        const rootElementName = 'T';
        const barcodeXml = parse(rootElementName, barcodeData, {
            declaration: {encoding: 'UTF-8'},
        });

        const document = this.domParser.parseFromString(barcodeXml, 'text/xml');

        return validateDocumentWellformed(document, rootElementName);
    }

    /**
     * mostly used for debugging purposes!
     * @param lohnausweisPDF ein validierter Lohnausweis
     */
    private buildBarcodeObject(
        lohnausweisPDF: LohnausweisPDF,
    ): Record<string, unknown> {
        const lohnausweis = lohnausweisPDF.lohnausweis;
        const arbeitGeber = lohnausweisPDF.arbeitGeber;
        const arbeitNehmer = lohnausweisPDF.arbeitNehmer;

        const data = {
            '@': {
                xmlns: 'http://www.swissdec.ch/schema/sd/20200220/SalaryDeclarationTxAB',
                SID: buildSIDType(),
                SysV: buildSysVType(),
            },
            'Company': buildCompany(arbeitGeber),
            'PersonID': buildPersonIdType(arbeitNehmer),
            // Rente
            'A': lohnausweis.isRente
                ? buildTaxAnnuityType(lohnausweis)
                : undefined,
            // Lohnausweis
            'S': lohnausweis.isLohn
                ? buildTaxSalaryType(lohnausweis, this.locale)
                : undefined,
        };
        deleteUndefined(data);

        return data;
    }

}

async function validateDocumentWellformed(
    document: Document,
    rootElementName: string,
): Promise<Document> {
    const errorElements = document.getElementsByTagName('parsererror');
    if (errorElements.length > 0) {
        return Promise.reject(`invalid generated xml: ${errorElements.item(0)?.textContent}`);
    }
    if (document.getElementsByTagName(rootElementName).length !== 1) {
        return Promise.reject(`document does not contain root element: ${rootElementName}`);
    }

    return Promise.resolve(document);
}

/**
 * Remove all properties that resolve to undefined since the xml generator does not support removing properties
 * in any way :(
 */
// waere natuerlich cool, wenn das ohne delete ginge oder der Workaround anders implementiert werden koennte..
function deleteUndefined<T>(obj: T): void {
    for (const propName in obj) {
        if (obj[propName] === undefined) {
            delete obj[propName];
        } else if (typeof obj[propName] === 'object') {
            deleteUndefined(obj[propName]);
        }
    }
}

/**
 * js2xml only represents an empty object (i.e.: {}) as xml element presence (empty element): e.g.:
 * 'foo': {}
 * will result in xml:
 * <foo/>
 *
 * See also: {@link #deleteUndefined}
 */
function elementBool(value: boolean): object | undefined {
    if (value) {
        return {};
    } else {
        return undefined;
    }
}

function buildCompany(arbeitGeber: ArbeitGeber): object {
    // FIXME: die verschiedenen IDs implementieren?
    return {
        '@': {
            'HR-RC-Name': buildHRRCNameType(arbeitGeber.firma),
            'ZIP': buildReducedZIPCodeType(arbeitGeber.adresse.plz!),
        },
    };
}

function buildIDType(id: string): string {
    return cleanString(id);
}

function buildPersonIdType(arbeitNehmer: ArbeitNehmer): object {
    //FIXME:
    // choice:
    // 	I: IType
    //  SV-AS-Nr: SV-AS-NumberType
    //  AHV-AVS-Nr: AHV-AVS-NumberType
    //  DateOfBirth: date
    //  unknown: EmptyType
    return {
        'SV-AS-Nr': arbeitNehmer.ahvNummerNeu,
        '@': {
            Lastname: buildLastNameType(arbeitNehmer.name!),
            Firstname: buildFirstNameType(arbeitNehmer.vorname!),
            ZIP: buildReducedZIPCodeType(arbeitNehmer.adresse.plz!),
            Street: cleanString(arbeitNehmer.adresse.strasse),
            Postbox: cleanString(arbeitNehmer.adresse.postfach),
            City: cleanString(arbeitNehmer.adresse.ort!),
            Country: cleanString(arbeitNehmer.adresse.land),
        },
    };
}

const LASTNAME_MAX_LENGTH = 30;

function buildLastNameType(lastName: string): string | undefined {
    //FIXME:
    /*
        <xs:restriction base="xs:string">
            <xs:whiteSpace value="collapse"/>
            <xs:maxLength value="30"/>
        </xs:restriction>
     */
    return lastName.substring(0, LASTNAME_MAX_LENGTH);
}

const FIRSTNAME_MAX_LENGTH = 15;

function buildFirstNameType(firstName: string): string | undefined {
    //FIXME:
    /*
        <xs:restriction base="xs:string">
            <xs:whiteSpace value="collapse"/>
            <xs:maxLength value="15"/>
        </xs:restriction>	 */
    return firstName.substring(0, FIRSTNAME_MAX_LENGTH);
}

// Steuerbare Rente
function buildTaxAnnuityType(lohnausweis: Lohnausweis): object | undefined {
    return {
        DocID: buildIDType(lohnausweis.id),
        Period: buildTimePeriodType(lohnausweis.von!, lohnausweis.bis!),
        Income: buildSalaryAmountType(lohnausweis.lohnRente!),
        GrossIncome: buildSalaryAmountType(lohnausweis.bruttoLohnRenteTotal),
        NetIncome: buildSalaryAmountType(lohnausweis.nettoLohnRente),
    };
}

function buildFringeBenefitsType(
    gehaltsNebenLeistungen: GehaltsNebenLeistungen,
): object {
    return {
        FoodLodging: buildSalaryAmountType(gehaltsNebenLeistungen.verpflegung),
        CompanyCar: buildSalaryAmountType(gehaltsNebenLeistungen.privatanteilGeschaeftswagen),
        Other: buildSortSumType(gehaltsNebenLeistungen.andere),
    };
}

function toStandarRemark(
    sb: StandardBemerkungen,
): object | undefined {
    const result: any = {};
    let found = false;

    if (sb.childAllowancePerAHVAVSEnabled) {
        found = true;
        result['ChildAllowancePerAHV-AVS'] = elementBool(sb.childAllowancePerAHVAVSEnabled);
    }

    if (sb.relocationCostsEnabled) {
        found = true;
        result.RelocationCosts = buildSalaryAmountType(sb.relocationCosts!);
    }

    if (sb.staffShareMarketValueEnabled) {
        found = true;
        result.StaffShareMarketValue = {
            Allowed: formatISODate(sb.staffShareMarketValue.datum),
            Canton: sb.staffShareMarketValue.kanton,
        };
    }

    if (sb.staffShareWithoutTaxableIncomeEnabled) {
        const atLeastOneReason = sb.staffShareWithoutTaxableIncomeReasons.atleastOneTrue();
        found = found || atLeastOneReason;

        if (atLeastOneReason) {
            result.StaffShareWithoutTaxableIncome = {
                // FIXME: passen unsere SBs noch zum Xml?
                // xml-schemas/ELM5_20200220/20200220SalaryDeclaration_Tax_noNS.xsd:1355
                BlockedOptions: elementBool(sb.staffShareWithoutTaxableIncomeReasons.gesperrteMitarbeiterOptionen),
                UnquotedOptions: elementBool(sb.staffShareWithoutTaxableIncomeReasons.nichtBewertbareOptionen),
                DeferredBenefitsStaffShares: elementBool(sb.staffShareWithoutTaxableIncomeReasons.anwartschaftlicheRechte),
                FictitousStaffShare: elementBool(sb.staffShareWithoutTaxableIncomeReasons.veraeusserungsSperre),
            }
        }
    }

    //CompanyCarClarify
    if (sb.CompanyCarClarify) {
        found = true;
        result['CompanyCarClarify'] = elementBool(sb.CompanyCarClarify);
    }

    //MinimalEmployeeCarPartPercentage
    if (sb.MinimalEmployeeCarPartPercentage) {
        found = true;
        result['MinimalEmployeeCarPartPercentage'] = elementBool(sb.MinimalEmployeeCarPartPercentage);
    }

    //PercentageExternalWork

    //TaxSurcePeriodForObjection

    //ContinuedProvisionOfSalary
    if (sb.ContinuedProvisionOfSalaryEnabled) {
        found = true;
        result['ContinuedProvisionOfSalary'] = {
            Lastname: sb.ContinuedProvisionOfSalary.Lastname,
            Firstname: sb.ContinuedProvisionOfSalary.Firstname,
            Address: {
                Street: sb.ContinuedProvisionOfSalary.Address.strasse,
                'ZIP-Code': sb.ContinuedProvisionOfSalary.Address.plz,
                City: sb.ContinuedProvisionOfSalary.Address.ort,
            },
        }
    }

    //ExpatriateRuling
    if (sb.expatriateRulingEnabled) {
        found = true;
        result['ExpatriateRuling'] = {
            Allowed: formatISODate(sb.expatriateRulingValue.datum),
            Canton: sb.expatriateRulingValue.kanton,
        }
    }

    //ActivityRate
    if (sb.activityRate) {
        found = true;
        result['ActivityRate'] = elementBool(sb.activityRate);
    }

    //NumberOfSalaryCertificate
    if (sb.numberOfSalaryCertificate) {
        found = true;
        result['NumberOfSalaryCertificate'] = sb.numberOfSalaryCertificate;
    }

    //Rectificate
    if (sb.RectificateEnabled) {
        found = true;
        result['Rectificate'] = {
            OriginalDate: formatISODate(sb.Rectificate),
            OriginalDocID: ' ',
        };
    }

    return found
        ? result
        : undefined;
}

function buildTaxSalaryType(
    lohnausweis: Lohnausweis,
    sprache: KorrespondenzSprache,
): object | undefined {
    return {
        'DocID': buildIDType(lohnausweis.id),
        'Period': buildTimePeriodType(lohnausweis.von!, lohnausweis.bis!),
        'FreeTransport': elementBool(lohnausweis.unentgeltlicheBefoerderung),
        'CanteenLunchCheck': elementBool(lohnausweis.kantinenverpflegung),
        'Income': buildSalaryAmountType(lohnausweis.lohnRente),
        'FringeBenefits': buildFringeBenefitsType(lohnausweis.gehaltsNebenLeistungen),
        'SporadicBenefits': buildSortSumType(lohnausweis.unregelmaessigeLeistungen),
        'CapitalPayment': buildSortSumType(lohnausweis.kapitalLeistungen),
        'OwnershipRight': buildSalaryAmountType(lohnausweis.beteiligungsrechte),
        'BoardOfDirectorsRemuneration': buildSalaryAmountType(lohnausweis.verwaltungsratEntschaedigungen),
        'OtherBenefits': buildSortSumType(lohnausweis.andereLeistungen),
        'GrossIncome': buildSalaryAmountType(lohnausweis.bruttoLohnRenteTotal),
        'AHV-ALV-NBUV-AVS-AC-AANP-Contribution': buildSalaryAmountType(lohnausweis.beitraegeAhvIvEoAlvNbuv),
        'BVG-LPP-Contribution': buildBvgLppContributionType(lohnausweis.beruflicheVorsorge),
        'NetIncome': buildSalaryAmountType(lohnausweis.nettoLohnRente),
        'DeductionAtSource': buildSalaryAmountType(lohnausweis.quellensteuerAbzug),
        'ChargesRule': buildChargesRuleType(lohnausweis.spesenVerguetungen),
        'Charges': buildChargesType(lohnausweis.spesenVerguetungen),
        'OtherFringeBenefits': lohnausweis.weitereGehaltsNebenLeistungen,
        'StandardRemark': toStandarRemark(lohnausweis.standardBemerkungen),
        'Remark': lohnausweis.buildAllBemerkungenText(sprache),
    };
}

export function buildSalaryAmountType(salary: number | null): string | undefined {
    // FIXME: validate/throw or adjust input value?
    //         <xs:restriction base="xs:decimal">
    //             <xs:pattern value="[\-]?[0-9]+\.[0-9]{2}"/>
    //         </xs:restriction>
    if (!valueGiven(salary)) {
        return undefined;
    }

    return (Math.round(salary! * 100) / 100)
        .toLocaleString('en-US', {useGrouping: false, minimumFractionDigits: 2});
}

function buildTimePeriodType(von: Date, bis: Date): object {
    return {
        from: formatISODate(von),
        until: formatISODate(bis),
    };
}

function buildSortSumType(param: ValueWithDescription): object | undefined {
    if (!valueGiven(param.value) && !valueGiven(param.beschreibung)) {
        return undefined;
    }

    return {
        Text: param.beschreibung,
        Sum: buildSalaryAmountType(param.value),
    };
}

function buildBvgLppContributionType(
    beruflicheVorsorge: BeruflicheVorsorge,
): object | undefined {
    return {
        Regular: buildSalaryAmountType(beruflicheVorsorge.ordentlicheBeitraege),
        Purchase: buildSalaryAmountType(
            beruflicheVorsorge.beitraegeFuerDenEinkauf,
        ),
    };
}

function buildChargesRuleType(spesen: SpesenVerguetungen): object | undefined {
    if (!spesen.effektivReiseCheck) {
        // see also: buildReducedChargesRuleType
        //
        // because withRegulation is not implemented:
        // if there is no Guidance-Element (it is boolean-present),
        // the ChargesRule would be empty... but this is not allowed
        // so: discard the ChargesRule completely
        return undefined;
    }
    return {
        // FIXME: WithRegulation => GrantType
        Guidance: elementBool(spesen.effektivReiseCheck),
    };
}

function buildChargesType(spesen: SpesenVerguetungen): object | undefined {
    return {
        Effective: buildEffectiveType(spesen),
    };
}

function buildEffectiveType(spesen: SpesenVerguetungen): object | undefined {
    return {
        TravelFoodAccommodation: buildSalaryAmountType(spesen.effektivReise),
        Other: buildSortSumType(spesen.effektivUebrige),
    };
}

function buildSIDType(): string {
    // *DO NOT CHANGE!*
    // Values are managed by Swissdec,
    // maxlength: 3
    return 'FOO';
}

function buildSysVType(): string {
    // version, max 3 char
    return '001';
}

const HRRCNAME_MAX_LENGTH = 30;

function buildHRRCNameType(hrRcName: string): string {
    //FIXME
    /*
        <xs:restriction base="xs:string">
            <xs:whiteSpace value="collapse"/>
            <xs:maxLength value="30"/>
        </xs:restriction>
     */
    return hrRcName.substring(0, HRRCNAME_MAX_LENGTH);
}

function buildReducedZIPCodeType(zipCode: string): string {
    //FIXME
    /*
        <xs:restriction base="xs:string">
            <xs:minLength value="1"/>
            <xs:maxLength value="6"/>
        </xs:restriction>
     */
    return zipCode.substring(0, 6);
}
