<!--
  ~ Copyright © 2018 DV Bern AG, Switzerland
  ~
  ~ Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
  ~ geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
  ~ insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
  ~ elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
  ~ Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
  -->

<ng-container *ngIf="show$ | async">
    <button mat-raised-button
            #exportButton
            i18n-matTooltip="@@xml-export.xml-export-header.tooltip"
            matTooltip="Alle Daten in eine XML-Datei exportieren"
            color="primary"
            *ngIf="isMainBar; else normalButton"
            class="elohn-xml-export-button"
            (click)="exportAll()">
        <span i18n="@@xml-export.xml-export-header">
            <mat-icon>save_alt</mat-icon>
            Daten speichern
        </span>
    </button>

    <ng-template #normalButton>
        <button mat-raised-button
                i18n-matTooltip="@@xml-export.xml-export.tooltip"
                matTooltip="Alle Daten in eine XML-Datei exportieren"
                cdkFocusInitial
                color="primary"
                i18n="@@xml-export.xml-export"
                (click)="exportAll()">
            <mat-icon>save_alt</mat-icon>
            erfasste Daten speichern
        </button>
    </ng-template>
</ng-container>
