/*
 * Copyright 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ngfModule} from 'angular-file';
import {ClearAllDialogComponent} from '../onboarding/clear-all-dialog/clear-all-dialog.component';
import {ErrorComponent} from './error/error.component';
import {ItemComponent} from './item/item.component';
import {MaterialModule} from './material.module';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {XmlExportComponent} from './xml-export/xml-export.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        MaterialModule,
        ngfModule,

        // Not exporting RouterModule as the routing modules do that already
        RouterModule
    ],
    declarations: [
        ErrorComponent,
        ItemComponent,
        XmlExportComponent,
        SnackbarComponent,
        ClearAllDialogComponent
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        MaterialModule,
        ngfModule,
        ErrorComponent,
        ItemComponent,
        XmlExportComponent,
    ],
    providers: [
        // Leave empty (if you have singleton services, add them to CoreModule)
    ]
})

export class SharedModule {
}
