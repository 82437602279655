import {IsBoolean} from 'class-validator';

export class ELM4StaffShareWithoutTaxableIncomeReasons {
    @IsBoolean({always: true})
    anwartschaftlicheRechte: boolean = false;

    @IsBoolean({always: true})
    veraeusserungsSperre: boolean = false;

    @IsBoolean({always: true})
    nichtBewertbareOptionen: boolean = false;

    @IsBoolean({always: true})
    gesperrteMitarbeiterOptionen: boolean = false;
}
