// noinspection MagicNumberJS

import {Injectable} from '@angular/core';
import {numberAsByteArray, numberInStupidFormat} from '../../shared/functions';

export interface HeaderOpts {
    /**
     * An identifier that groups all barcodes <strong>for one person</strong> in the current PDF-file.
     *
     * This might be a simple counter.
     */
    readonly personIdent: number,
    /**
     * Number of bytes in this barcode.
     *
     * Please note: this is limited by the spec, see {@link BarcodeHeaderService.BYTES_PER_BARCODE}
     */
    readonly contentByteCount: number,
    /**
     * As there can be multiple barcode parts for one person: the current part (index: 0-based).
     */
    readonly barcodeIndex: number;
    /**
     * As there can be multiple barcode parts for one person: the total number of parts.
     */
    readonly totalBarcodes: number;
}

@Injectable({providedIn: 'root'})
export class BarcodeHeaderService {
    // taken from Barcode spec
    static readonly FILE_HEADER_LENGTH = 14;
    // taken from Barcode spec
    static readonly BYTES_PER_BARCODE = 1000;

    /**
     * The maximum integer that can be represented by two bytes.
     */
    private static readonly TWO_BYTES_MAX_INT = 65535;
    private static readonly FOUR_BYTES_MAX_INT = 4294967295;

    build(opts: HeaderOpts): Uint8Array {
        this.validate(opts);

        const header = new Uint8Array(BarcodeHeaderService.FILE_HEADER_LENGTH);

        // 4 bytes identification
        const ident = numberAsByteArray(opts.personIdent);
        header[0] = ident[0];
        header[1] = ident[1];
        header[2] = ident[2];
        header[3] = ident[3];

        // 1 byte compression type (z = ZIP)
        const zInASCII = 122;
        header[4] = zInASCII;

        // total barcode size = header length + zip size
        const barcodeSize = BarcodeHeaderService.FILE_HEADER_LENGTH + opts.contentByteCount;
        const sizeInts = numberAsByteArray(barcodeSize);
        if (sizeInts[0] > 0) {
            throw Error('Barcode-Content too large!');
        }
        header[5] = sizeInts[1];
        header[6] = sizeInts[2];
        header[7] = sizeInts[3];

        // page control byte: 1 for first page, 2 for all followup pages
        header[8] = opts.barcodeIndex == 0
            ? 1
            : 2;

        // 1 byte version: ELM5: fixed: 3
        header[9] = 3;

        // 2 bytes current barcode index
        const currentBarcodeNum = numberInStupidFormat(opts.barcodeIndex + 1);
        header[10] = currentBarcodeNum[0];
        header[11] = currentBarcodeNum[1];
        // header[10] = '0'.charCodeAt(0);
        // header[11] = '1'.charCodeAt(0);

        // 2 bytes total barcode count
        const totalBarcodes = numberInStupidFormat(opts.totalBarcodes);
        header[12] = totalBarcodes[0];
        header[13] = totalBarcodes[1];
        // header[12] = '0'.charCodeAt(0);
        // header[13] = '1'.charCodeAt(0);

        return header;
    }

    private validate(opts: HeaderOpts): void {
        this.checkIntegerRange(opts, 'personIdent', 0, BarcodeHeaderService.FOUR_BYTES_MAX_INT);
        this.checkIntegerRange(opts, 'contentByteCount', 1, BarcodeHeaderService.BYTES_PER_BARCODE);
        this.checkIntegerRange(opts, 'barcodeIndex', 0, BarcodeHeaderService.TWO_BYTES_MAX_INT);
        this.checkIntegerRange(opts, 'totalBarcodes', 1, BarcodeHeaderService.TWO_BYTES_MAX_INT);
        if (opts.barcodeIndex >= opts.totalBarcodes) {
            throw Error(`Barcode-Index exceeds totalBarcodes: ${opts.barcodeIndex}/${opts.totalBarcodes}`)
        }
    }

    private checkIntegerRange<T>(opts: T, name: keyof T, min: number, max: number): void {
        const value = opts[name];

        if (typeof value !== 'number') {
            throw Error(`${name.toString()} is not a number but: ${value}`);
        }

        if (!Number.isInteger(value)) {
            throw Error(`${name.toString()} is not an integer value but: ${value}`);
        }
        if (value < min) {
            throw Error(`${name.toString()} must be >= ${min} but was: ${value}`);
        }
        if (value > max) {
            throw Error(`${name.toString()} must be <= ${max} but was: ${value}`);
        }
    }
}
