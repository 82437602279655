/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ValidationOptions} from 'class-validator';
import {LohnausweisTyp} from '../../lohnausweis/shared/lohnausweis-typ.enum';
import moment from 'moment';

export const MIN_YEAR = 1900;
export const MIN_MONTH = 0;
export const MIN_DAY = 1;
export const MIN_ALLOWED_YEAR = moment(Date.UTC(MIN_YEAR, MIN_MONTH, MIN_DAY)).toDate();

export const VALID_XML_CHARACTERS = // eslint-disable-next-line no-control-regex
    /[^\x09\x0A\x0D\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm;
// export const PHONE_REGION = 'de-CH';
export const PHONE_REGION = 'any';

export const GROUP_LOHNAUSWEIS = LohnausweisTyp.LOHNAUSWEIS;
export const GROUP_RENTENBESCHEINIGUNG = LohnausweisTyp.RENTENBESCHEINIGUNG;
export const GROUPS_LOHNAUSWEIS = [GROUP_LOHNAUSWEIS];
export const GROUPS_BOTH = [GROUP_LOHNAUSWEIS, GROUP_RENTENBESCHEINIGUNG];

export const OPTS_BOTH: ValidationOptions = {
    groups: GROUPS_BOTH
};

export const OPTS_LOHNAUSWEIS: ValidationOptions = {
    groups: GROUPS_LOHNAUSWEIS
};
