/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PdfGenerationProgress} from '../../printing/pdf/pdf-generator/models/PdfGenerationProgress';

@Component({
    selector: 'elohn-print-progress-toolbar',
    templateUrl: './print-progress-toolbar.component.html',
    styleUrls: ['./print-progress-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintProgressToolbarComponent {

    @Input() public progress!: PdfGenerationProgress;
}
