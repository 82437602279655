/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {classToClass} from 'class-transformer';
import {List} from 'immutable';
import {BehaviorSubject, forkJoin, from, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {ArbeitGeber} from '../arbeit-geber/shared/arbeit-geber.model';
import {ArbeitNehmer} from '../arbeit-nehmer/shared/arbeit-nehmer.model';
import {DataService} from '../data-store/data-initializer.service';
import {DataStoreService} from '../data-store/data-store.service';
import {Lohnausweis} from '../lohnausweis/shared/lohnausweis.model';
import {ignoreNullAndUndefined} from '../shared/functions/isNotNullOrUndefined';
import {LogFactory} from './logging/log-factory';
import {LohnausweiseCountService} from './lohnausweise-count.service';
import {Invalid} from './validation/Invalid';
import {Valid} from './validation/Valid';
import {ValidationService} from './validation/validation.service';
import {ValidityCheckService} from './validation/validityCheck.service';
import moment from 'moment';

const LOG = LogFactory.createLog('LohnausweisService');

@Injectable({
    providedIn: 'root',
})
export class LohnausweisService implements DataService {

    private readonly lohnausweisSubject$: BehaviorSubject<Lohnausweis | undefined> = new BehaviorSubject(undefined as any);
    private readonly lohnausweiseSubject$ = new BehaviorSubject<List<Lohnausweis>>(List([]));
    public readonly lohnausweise$ = this.lohnausweiseSubject$.asObservable();

    constructor(
        private readonly dataStoreService: DataStoreService,
        private readonly validationService: ValidationService,
        private readonly validityCheckService: ValidityCheckService,
        private readonly lohnausweiseCountService: LohnausweiseCountService,
    ) {
    }

    public loadInitialData$(): Observable<void> {
        this.lohnausweisSubject$.next(undefined);
        this.lohnausweiseCountService.clearCache();

        // make sure to emit: pass undefined as argument!
        return of(undefined);
    }

    public lastActive$(): Observable<Lohnausweis> {
        return this.current$()
            .pipe(ignoreNullAndUndefined());
    }

    public current$(): Observable<Lohnausweis | undefined> {
        return this.lohnausweisSubject$.asObservable()
            .pipe(distinctUntilChanged()); // sometimes there are lots of successive undefined
    }

    public setLohnausweis(lohnausweis: Lohnausweis | undefined): void {
        LOG.debug('next', lohnausweis ? lohnausweis.id : lohnausweis);
        this.lohnausweisSubject$.next(lohnausweis);
    }

    public get$(id: string): Observable<Lohnausweis | undefined> {
        return this.dataStoreService.lohnausweis.get$(id);
    }

    public find$(arbeitNehmer: ArbeitNehmer): Observable<List<Lohnausweis>> {
        const subj$ = new Subject<List<Lohnausweis>>();
        const obs$ = subj$.asObservable();

        from(this.dataStoreService.db.lohnausweis
            .where({arbeitGeberId: arbeitNehmer.arbeitGeberId, arbeitNehmerId: arbeitNehmer.id})
            .toArray<List<Lohnausweis>>(List))
            .subscribe(subj$);

        obs$.subscribe(
            ausweise => this.lohnausweiseSubject$.next(ausweise),
            (err: unknown) => LOG.error(err),
        );

        return obs$.pipe(switchMap(() => this.lohnausweise$));
    }

    public findByArbeitGeber$(arbeitGeber: ArbeitGeber): Observable<List<Lohnausweis>> {
        const subj$ = new Subject<List<Lohnausweis>>();
        const obs$ = subj$.asObservable();

        from(this.dataStoreService.db.lohnausweis
            .where({arbeitGeberId: arbeitGeber.id})
            .toArray<List<Lohnausweis>>(List))
            .subscribe(subj$);

        obs$.subscribe(
            ausweise => this.lohnausweiseSubject$.next(ausweise),
            (err: unknown) => LOG.error(err),
        );

        return obs$.pipe(switchMap(() => this.lohnausweise$));
    }

    public getAll$(): Observable<Lohnausweis[]> {
        return this.dataStoreService.lohnausweis.getAll$();
    }

    /**
     * returns the ID of the Lohnausweis
     */
    public createEmpty$(jahr: number, arbeitNehmer: ArbeitNehmer): Observable<string> {
        const arbeitGeberId = arbeitNehmer.arbeitGeberId;
        const arbeitNehmerId = arbeitNehmer.id;
        const lohnausweis = new Lohnausweis(jahr, {arbeitGeberId, arbeitNehmerId});
        lohnausweis.von = moment(Date.UTC(jahr, 0, 1)).toDate();
        lohnausweis.bis = moment(Date.UTC(jahr, 11, 31)).toDate();

        return this.validationService.validate$(lohnausweis, lohnausweis.validationOptions())
            .pipe(
                map(validation => validation.isValid()),
                mergeMap(isValid => this.validityCheckService.setValidityForLohnausweis$(lohnausweis, isValid)),
                mergeMap(() => this.dataStoreService.lohnausweis.add$(lohnausweis)),
                mergeMap(id => forkJoin([
                        this.lohnausweiseCountService.update$(arbeitNehmerId, arbeitGeberId),
                        this.get$(id)
                            .pipe(
                                ignoreNullAndUndefined(),
                                take(1),
                                map(l => {
                                    const list = this.lohnausweiseSubject$.getValue();
                                    this.lohnausweiseSubject$.next(list.push(l));
                                })
                            ),
                    ]).pipe(map(() => id)),
                ),
            );
    }

    /**
     * returns the number of deleted Lohnausweise
     */
    public deleteForArbeitGeber$(arbeitNehmerId: string, arbeitGeberId: string): Observable<number> {
        return this.validityCheckService.clearValidityForArbeitnehmer$(arbeitNehmerId).pipe(
            mergeMap(() => from(this.dataStoreService.db.lohnausweis
                .where('arbeitNehmerId').equals(arbeitNehmerId)
                .delete(),
            )),
            mergeMap(numDeleted => this.lohnausweiseCountService.update$(arbeitNehmerId, arbeitGeberId)
                .pipe(map(() => numDeleted)),
            ),
        );
    }

    public delete$(lohnausweis: Lohnausweis): Observable<void> {
        const arbeitNehmerId = lohnausweis.arbeitNehmerId;
        const arbeitGeberId = lohnausweis.arbeitGeberId;

        return this.validityCheckService.setValidityForLohnausweis$(lohnausweis, true)
            .pipe(
                mergeMap(() => from(this.dataStoreService.db.lohnausweis.delete(lohnausweis.id))),
                mergeMap(() => this.lohnausweiseCountService.update$(arbeitNehmerId, arbeitGeberId)),
                map(() => {
                    const list = this.lohnausweiseSubject$.getValue();
                    const index = list.findIndex(l => !!l && l.id === lohnausweis.id);

                    this.lohnausweiseSubject$.next(list.delete(index));

                    const currentSelected = this.lohnausweisSubject$.getValue();
                    if (currentSelected && currentSelected.id === lohnausweis.id) {
                        this.lohnausweisSubject$.next(undefined);
                    }
                }),
            );
    }

    public deleteAll$(): Observable<void> {
        this.lohnausweisSubject$.next(undefined);
        this.lohnausweiseSubject$.next(List([]));

        return this.validityCheckService.clearValidityForAllLohnausweise$()
            .pipe(
                mergeMap(() => this.dataStoreService.lohnausweis.clear$()),
                map(() => this.lohnausweiseCountService.clearCache()),
            );
    }

    /**
     * returns the ID of the updated Lohnausweis
     */
    public update$(
        lohnausweis: Lohnausweis,
        validationResult: Invalid<Lohnausweis> | Valid<Lohnausweis>,
    ): Observable<string> {
        const subj$ = new Subject<string>();
        const obs$ = subj$.asObservable();

        this.validityCheckService.setValidityForLohnausweis$(lohnausweis, validationResult.isValid())
            .pipe(mergeMap(() => this.dataStoreService.lohnausweis.update$(lohnausweis)))
            .subscribe(subj$);

        obs$.subscribe(
            () => {
                const list = this.lohnausweiseSubject$.getValue();
                const index = list.findIndex(l => !!l && l.id === lohnausweis.id);

                // creating a new instance of Lohnausweis, to trigger change detection
                this.lohnausweiseSubject$.next(list.set(index, classToClass(lohnausweis)));
            },
            (err: unknown) => LOG.error(err),
        );

        return obs$;
    }
}
