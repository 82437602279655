/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {IsString, ValidateNested} from 'class-validator';
import {IsValidXMLValue} from '../../core/validation/IsValidXMLValue';
import {Type} from 'class-transformer';
import {Adresse} from "../../shared/adresse.model";

export class ContinuedProvisionOfSalary {
    @IsString({always: true})
    @IsValidXMLValue({always: true})
    public CHF: string | null = null;

    @IsString({always: true})
    @IsValidXMLValue({always: true})
    public Lastname: string | null = null;

    @IsString({always: true})
    @IsValidXMLValue({always: true})
    public Firstname: string | null = null;

    @Type(() => Adresse)
    @ValidateNested()
    public Address: Adresse = new Adresse();
}
