/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Lohnausweis} from './lohnausweis.model';

export class WizardStep2Model {

    verpflegungUndUnterkunft = false;
    kantinenverpflegung = false;
    unentgeltlicheBefoerderung = false;
    geschaeftswagen = false;
    gehaltsnebenLeistungen = false;
    marktwertNebenleistungen = false;

    public initFromLohnausweis(la: Lohnausweis): void {
        this.verpflegungUndUnterkunft = la.gehaltsNebenLeistungen.verpflegung !== null;
        this.kantinenverpflegung = la.kantinenverpflegung;
        this.unentgeltlicheBefoerderung = la.unentgeltlicheBefoerderung;
        this.geschaeftswagen = la.gehaltsNebenLeistungen.privatanteilGeschaeftswagen !== null;
        this.gehaltsnebenLeistungen = la.weitereGehaltsNebenLeistungen !== ''
            || la.gehaltsNebenLeistungen.andere.hasNonZeroValue();
        this.marktwertNebenleistungen = la.gehaltsNebenLeistungen.andere.hasNonZeroValue();
    }
}
