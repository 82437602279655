import {IsDate, IsEnum} from 'class-validator';
import {CantonType} from '../../../../core/canton-type.enum';
import {IsValidXMLValue} from '../../../../core/validation/IsValidXMLValue';

export class ELM4Genehmigung {
    @IsDate({always: true})
    @IsValidXMLValue({always: true})
    public datum: Date | null = null;

    @IsEnum(CantonType, {always: true})
    public kanton: CantonType | null = null;

}
