import {LogFactory} from '../../core/logging/log-factory';
import {assertCondition} from './assertCondition';

const LOG = LogFactory.createLog('splitUint8Array');

export function splitUint8Array(input: Uint8Array, maxChunkBytes: number): Uint8Array[] {
    assertCondition(maxChunkBytes > 0, () => `maxChunkLength must be > 0 but was: ${maxChunkBytes}`);

    const slices = Math.ceil(input.byteLength / maxChunkBytes);
    // split evenly across barcodes
    const bytesPerSlice = Math.ceil(input.byteLength / slices);

    LOG.debug('bytesPerSlice: ', bytesPerSlice)

    const result: Uint8Array[] = [];
    for (let i = 0; i < slices; i++) {
        const offset = (i * bytesPerSlice);
        const slice = input.slice(offset, offset + bytesPerSlice);

        result.push(slice);
    }

    const sumLen = sumBytes(result);
    assertCondition(
        input.byteLength === sumLen,
        () => `Splint length != original length: ${sumLen} <-> ${input.byteLength}`,
    );

    return result;
}

function sumBytes(param: Uint8Array[]) {
    return param
        .map(r => r.byteLength)
        .reduce(
            (prev, next) => prev + next,
            0,
        );
}

