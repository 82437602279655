/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {map, mergeAll, mergeMap, switchMap, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ArbeitGeberService} from '../core/arbeit-geber.service';
import {ArbeitNehmerService} from '../core/arbeit-nehmer.service';
import {LogFactory} from '../core/logging/log-factory';
import {LohnausweisService} from '../core/lohnausweis.service';
import {ValidityCheckService} from '../core/validation/validityCheck.service';
import {ImportMode} from '../onboarding/import/import-mode.enum';
import {ImportData} from '../shared/import/ImportData.interface';
import {DataStoreService} from './data-store.service';
import {noStorageManagerAPI, tryPersistWithoutPromtingUser} from './PersistenceHelpers';

export interface DataService {
    loadInitialData$(): Observable<void>;
}

const LOG = LogFactory.createLog('DataInitializerService');

@Injectable({
    providedIn: 'root',
})
export class DataInitializerService {

    private readonly dataServices: ReadonlyArray<DataService>;
    private readonly hasBrowserDataSubject$ = new BehaviorSubject<boolean>(false);

    public hasBrowserData$ = this.hasBrowserDataSubject$.asObservable();

    constructor(
        arbeitNehmerService: ArbeitNehmerService,
        private readonly arbeitGeberService: ArbeitGeberService,
        private readonly lohnausweisService: LohnausweisService,
        private readonly dataStoreService: DataStoreService,
        validityCheckService: ValidityCheckService,
    ) {
        this.dataServices = [arbeitNehmerService, arbeitGeberService, lohnausweisService, validityCheckService];
        this.initHasBrowserData();

        if (environment.production || environment.hmr) {
            // not exeucting in tests because of log spam
            this.isStoragePersisted()
                .then(isPersisted => DataInitializerService.initStoragePersistence(isPersisted))
                .catch((err: unknown) => LOG.error(err));
        }
    }

    private static async initStoragePersistence(isPersisted: boolean): Promise<void> {
        if (isPersisted) {
            return;
        }

        const persist = await tryPersistWithoutPromtingUser();
        switch (persist) {
            case 'never':
                LOG.info('Not possible to persist storage');

                return;
            case 'persisted':
                LOG.info('Successfully persisted storage silently');

                return;
            case 'prompt':
                LOG.info('Not persisted, but we may prompt user when we want to.');

                return;
            default:
                throw new Error('unhandled state ' + persist);
        }
    }

    private static importWizard(params: ImportData): void {
        params.lohnausweise.forEach(la => la.wizard.initFromLohnausweis(la));
    }

    public resetDatabase$(): Observable<void> {
        return this.dataStoreService.resetDatabase$()
            .pipe(switchMap(() => this.init$()));
    }

    public replaceWith$(params: ImportData, mode: ImportMode): Observable<void> {
        DataInitializerService.importWizard(params);

        return this.dataStoreService.replaceWith$(params, mode)
            .pipe(
                switchMap(() => this.init$()),
                take(1),
            );
    }

    public clearLohnausweise$(): Observable<void> {
        return this.lohnausweisService.deleteAll$();
    }

    public clearAll$(): Observable<void> {
        return this.dataStoreService.clearAll$()
            .pipe(mergeMap(() => this.init$()));
    }

    private init$(): Observable<void> {
        return forkJoin(this.dataServices.map(s => s.loadInitialData$().pipe(take(1))))
            .pipe(mergeAll())
            .pipe(map(() => this.initHasBrowserData()));
    }

    private initHasBrowserData(): void {
        this.arbeitGeberService.get$()
            .pipe(
                take(1),
                map(value => !!value),
            )
            .subscribe(
                next => this.hasBrowserDataSubject$.next(next),
                (error: unknown) => LOG.error(error),
            );
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Check if storage is persisted already.
     * @returns Promise resolved with true if current origin is using persistent storage, false if not, and rejected if
     *         the API is not present.
     */
    public isStoragePersisted(): Promise<boolean> {
        return navigator.storage && navigator.storage.persisted ?
            navigator.storage.persisted() :
            Promise.reject(noStorageManagerAPI);
    }
}
